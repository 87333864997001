import cn from 'classnames';
import { HTMLAttributes } from 'react';
import './index.scss';

interface ICursorPagination {
  count: number;
  activeIndex: number;
  cols?: number;
  onItemClick?: (index: number) => void;
  vertical?: boolean;
}
export const CursorPagination = (props: ICursorPagination & HTMLAttributes<HTMLDivElement>) => {
  const { count, activeIndex, className, onItemClick, vertical = false, cols = 1, ...restProps } = props;
  const realIndex = activeIndex / cols;
  return (
    <div
      className={cn('cursor-circle-wrapper', { 'cursor-circle-wrapper-vertical': vertical }, className)}
      {...restProps}
    >
      {Array(count)
        .fill(undefined)
        .map((_, i) => {
          return (
            <div
              key={i}
              onClick={() => onItemClick?.(i)}
              className={cn('cursor-circle', { 'cursor-circle-active': realIndex === i })}
            ></div>
          );
        })}
    </div>
  );
};
