import axios from 'axios';

export const postProductList = (params) => {
  return axios.post('/api/internal/product/list', params);
};

export const getProductDetail = (id) => {
  return axios.get(`/api/internal/product/${id}`);
};
export const getProductCategory = (id) => {
  return axios.get(`/api/internal/product-category/detail/${id}`);
};