import Helmet from 'components/Helmet';
import useLanguage from 'hooks/useLanguage';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import './index.scss';
import { getActivityBanner, getActivityPlace, postActivityList } from './service';
import Context from 'container';
import MainTitle from 'components/Common/main-title';
import Slider, { Settings } from 'react-slick';
import { isValid, oss } from 'scripts/utils';
import map from 'lodash/map';
import { isDark } from 'scripts/color-extractor/utils';
import { getColorByUrl } from 'scripts/color-extractor/index';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import CircleButton from 'components/Common/circle-button';
import { CursorPagination } from 'components/Common/cursor-pagination';
import { dateFormatter, enDateFormatter, fieldTranslation } from 'components/constants';
import DownOne from 'components/IconWrapper/down-one';
import { DatePicker, Select, Input } from 'antd';
import { SearchOutlined, UnorderedListOutlined, AppstoreFilled } from '@ant-design/icons';
import cn from 'classnames';
import { divideData } from 'components/About/news';
import Divider from 'components/Common/divider';
import Pagination from 'components/Common/pagination';
import zh from 'antd/locale/zh_CN';
import en from 'antd/locale/en_US';
import { getTitle } from 'components/services';
import usePlatform from 'hooks/usePlatform';
import MobileDatePicker from './mobile-date-picker';
import { InfiniteScroll } from 'antd-mobile'
import LiveButton from './live-button'
// import 'antd/es/date-picker/style'
// import 'antd/es/select/style'
// import 'antd/es/input/style'

export interface ActivitySearch {
  activityTypes?: string[];
  places?: string[];
  activityName?: string;
  startDate?: number;
  endDate?: number;
}
export interface SearchParams {
  site?: {
    id: number;
  };
  activity?: ActivitySearch;
  pageNo?: number;
  pageSize?: number;
}

const Activity = () => {
  const { i18n } = useLanguage();
  const { currentSite = {}, lang } = useContext(Context);
  const [places, setPlaces] = useState<string[]>([]);
  const { isMobile } = usePlatform();
  const [headerInfo, setHeaderInfo] = useState({
    title: '',
    desc: ''
  });
  const [displayType, setDisplayType] = useState<'card' | 'list'>('card');
  const [pageInfo, setPageInfo] = useState({
    total: 0,
    pageNo: 1,
    pageSize: 10
  });
  const [activitySearch, setActivitySearch] = useState<ActivitySearch>({});
  const [data, setData] = useState<ActivityData[]>([]);

  const [mobileData, setMobileData] = useState<ActivityData[]>([])
  const [hasMore, setHasMore] = useState(true)
  const pageNoMobileRef = useRef(1)
  const isMountRef = useRef(true)

  async function loadMore() {
    const res = await postActivityList({
      pageNo: pageNoMobileRef.current,
      pageSize: 10,
      activity: activitySearch,
      site: { id: currentSite.id },
    })
    const {total, data} = res as unknown as { total: number; data: ActivityData[] }
    pageNoMobileRef.current ++
    const finalData = [...mobileData, ...data]
    setMobileData(finalData)
    setHasMore(finalData.length < total)
  }

  const PAGE_COLUMN = isMobile ? 1 : 2;

  const list = divideData(data, PAGE_COLUMN);
  const mobileList = divideData(mobileData, PAGE_COLUMN);

  const activityTypes = useMemo(
    () => [
      i18n(fieldTranslation, '产品发布会'),
      i18n(fieldTranslation, '客户现场会'),
      i18n(fieldTranslation, '媒体交流会'),
      i18n(fieldTranslation, '研讨会'),
      i18n(fieldTranslation, 'CIO活动'),
      i18n(fieldTranslation, '产业峰会'),
      i18n(fieldTranslation, '行业峰会'),
      i18n(fieldTranslation, '展会')
    ],
    [i18n]
  );

  const handleDateChange = (d: any) => {
    if (d === null || d === undefined) {
      setActivitySearch((s) => ({
        ...s,
        startDate: undefined,
        endDate: undefined
      }));
    } else {
      setActivitySearch((s) => ({
        ...s,
        startDate: d[0]?.startOf('day').valueOf(),
        endDate: d[1]?.endOf('day').valueOf()
      }));
    }
  };

  const handlePageChange = (page: number, size: number) => {
    setPageInfo((info) => ({
      ...info,
      pageNo: page,
      pageSize: size
    }));
  };

  useEffect(() => {
    if (!isMountRef.current && isMobile) {
      pageNoMobileRef.current = 1
      setMobileData([])
      setHasMore(true)
    } else {
      isMountRef.current = false
    }
  }, [activitySearch])

  useEffect(() => {
    if (currentSite.id) {
      getActivityPlace(currentSite.id).then((res) => {
        setPlaces(res as unknown as string[]);
      });
      getTitle({ type: 'BRAND_ACTIVITIES', site: { id: currentSite.id } }).then((res) => {
        const { title, desc } = res;
        setHeaderInfo({
          title,
          desc
        });
      });
    }
  }, [currentSite.id]);

  useEffect(() => {
    if (currentSite.id && !isMobile ) {
      postActivityList({
        site: { id: currentSite.id },
        pageNo: pageInfo.pageNo,
        pageSize: pageInfo.pageSize,
        activity: activitySearch
      }).then((res) => {
        const { data, total } = res as unknown as { total: number; data: ActivityData[] };
        setPageInfo((s) => ({ ...s, total }));
        setData(data);
      });
    }
  }, [activitySearch, currentSite.id, pageInfo.pageNo, pageInfo.pageSize, isMobile]);

  const ActivityItem = ({items, hideDivider}: {hideDivider?: boolean;items: ActivityData[]}) => {
    return (
        <div className="activity-list-row">
        <div className="activity-list-row-item-wrapper">
          {map(items, (item, i) => {
            return (
              <Link
                className={cn('activity-list-row-item', {
                  'activity-list-row-item-small': displayType === 'list'
                })}
                to={`/activity/${item.id}`}
                key={item.id}
              >
                {item.liveAddress && <LiveButton {...item} />}
                <img src={oss(item.coverMaterial, 434, 244)} alt={item.activityName} />
                <div>
                  <div className="item-intro line-clamp-1">{item.activityIntroduction}</div>
                  <div className="item-time hidden-in-mobile">{i18n(fieldTranslation, '活动时间')}:</div>
                  <div className="item-time-str">{item.activityTimeStr}</div>
                </div>
              </Link>
            );
          })}
        </div>
        {
          hideDivider ? null : <Divider />
        }
      </div>
    )
  }

  return (
    <React.Fragment>
      <Helmet title="端点-Terminus-数字化转型-商业软件" description={''} />
      <div className="activity-wrapper">
        <MainTitle
          className="activity-title hidden-in-mobile"
          main={headerInfo.title}
          sub={headerInfo.desc}
          size={'xl'}
        />
        <ActivityCarousel />
        <div className="activity-filter">
          <div className="activity-filter-selector">
            {/* //TODO: mobile 日期选择组件 */}
            <DatePicker.RangePicker
              locale={lang === 'cn' ? zh.DatePicker : en.DatePicker}
              value={
                activitySearch?.startDate
                  ? [dayjs(activitySearch?.startDate), dayjs(activitySearch?.endDate)]
                  : undefined
              }
              allowClear
              onChange={handleDateChange}
              className="filter-picker hidden-in-mobile"
              placeholder={[i18n(fieldTranslation, '活动开始时间'), i18n(fieldTranslation, '活动结束时间')]}
            />
            <MobileDatePicker
              className="hidden-in-pc"
              value={
                activitySearch?.startDate
                  ? [dayjs(activitySearch?.startDate), dayjs(activitySearch?.endDate)]
                  : undefined
              }
              onChange={handleDateChange}
              placeholder={i18n(fieldTranslation, '活动时间')}
            />
            <Select
              className="filter-select"
              options={map(places, (p) => ({
                label: p,
                value: p
              }))}
              allowClear
              maxTagCount="responsive"
              showSearch={false}
              showArrow={true}
              bordered={false}
              value={activitySearch.places}
              onChange={(v) => setActivitySearch((s) => ({ ...s, places: v }))}
              mode="multiple"
              suffixIcon={<DownOne size={14} />}
              placeholder={i18n(fieldTranslation, '所有地点')}
            />
            <Select
              className="filter-select"
              options={map(activityTypes, (type) => ({
                label: type,
                value: type
              }))}
              allowClear
              maxTagCount="responsive"
              value={activitySearch.activityTypes}
              onChange={(v) => setActivitySearch((s) => ({ ...s, activityTypes: v }))}
              showSearch={false}
              bordered={false}
              showArrow={true}
              mode="multiple"
              suffixIcon={<DownOne size={14} />}
              placeholder={i18n(fieldTranslation, '所有类型')}
            />
          </div>
          <div>
            <span className="list-type-switch hidden-in-mobile">
              <span onClick={() => setDisplayType('card')} className={cn({ 'icon-active': displayType === 'card' })}>
                <AppstoreFilled size={16} />
              </span>
              <span onClick={() => setDisplayType('list')} className={cn({ 'icon-active': displayType === 'list' })}>
                <UnorderedListOutlined size={16} />
              </span>
            </span>
            <Input
              allowClear
              prefix={<SearchOutlined style={{ color: '#676A76' }} />}
              className="filter-input"
              placeholder={i18n(fieldTranslation, '请输入关键字')}
              value={activitySearch.activityName}
              onChange={(ev) => setActivitySearch((s) => ({ ...s, activityName: ev.target.value }))}
            />
          </div>
        </div>
        <div className='hidden-in-mobile'>
          <div className="activity-list">
            {map(list, (items, idx) => {
              return <ActivityItem hideDivider={pageInfo.total < pageInfo.pageSize && list.length === idx + 1} items={items} key={idx} />
            })}
            <Pagination
              onChange={handlePageChange}
              total={pageInfo.total}
              pageSize={pageInfo.pageSize}
              current={pageInfo.pageNo}
              hideOnSinglePage={true}
            />
          </div>
        </div>
        <div className='hidden-in-pc'>
          <div className="activity-list">
            {map(mobileList, (items, idx) => {
              return (
                <ActivityItem hideDivider={list.length === idx + 1} items={items} key={idx} />
              );
            })}
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export interface ActivityData {
  activityIntroduction: string;
  activityName: string;
  liveAddress?: string;
  activityTimeStr: string;
  activityType: string;
  coverMaterial: string;
  startDate: number;
  endDate: number;
  id: number;
  createdAt: number;
}

const ActivityCarousel = () => {
  const sliderRef = useRef<Slider | null>(null);
  const [imagesData, setImagesData] = useState<string[]>([]);
  const [data, setData] = useState<ActivityData[]>([]);
  const [theme, setTheme] = useState<'dark' | 'light' | undefined>(undefined);
  const context = useContext(Context);
  const { currentSite = {}, lang } = context;
  const [activeIndex, setActiveIndex] = useState(0);
  const isShowController = data.length > 1;
  const { isMobile } = usePlatform();
  const handleGoto = (index: number) => {
    sliderRef.current?.slickGoTo(index);
  };
  const handlePrev = () => {
    sliderRef.current?.slickPrev();
  };
  const handleNext = () => {
    sliderRef.current?.slickNext();
  };
  useEffect(() => {
    if (isValid(currentSite.id)) {
      getActivityBanner(currentSite.id).then((res) => {
        const result = res as unknown as ActivityData[];
        setData(result);
        Promise.all(
          map(result, (d: ActivityData) => {
            return d.coverMaterial;
          })
        ).then((res) => {
          setImagesData(res as string[]);
        });
      });
    }
  }, [currentSite.id]);

  useEffect(() => {
    if (!isMobile && imagesData[activeIndex]) {
      getColorByUrl(imagesData[activeIndex], {
        clip: {
          offsetY: 300
        }
      }).then((c) => {
        setTheme(isDark(c) ? 'dark' : 'light');
      });
    }
  }, [activeIndex, imagesData, isMobile]);

  const settings: Settings = {
    fade: true,
    autoplay: data.length > 1,
    autoplaySpeed: 8000,
    infinite: true,
    arrows: false,
    adaptiveHeight: false,
    beforeChange: (current, next) => setActiveIndex(next)
  };

  return (
    <div className={cn("activity-carousel", {'activity-carousel-mb': isShowController})}>
      <Slider ref={sliderRef} {...settings} className="activity-carousel-slider">
        {map(data, (d, contentIndex) => {
          return (
            <div key={contentIndex}>
              <Link to={`/activity/${d.id}`}>
                {d.liveAddress && <LiveButton {...d}/>}
                <div
                  className="activity-carousel-content img-background"
                  style={{ backgroundImage: `url(${oss(imagesData[contentIndex], 2000)})` }}
                ></div>
              </Link>
            </div>
          );
        })}
      </Slider>
      <div className="activity-carousel-desc">
        <MainTitle
          size="lg"
          position="left"
          main={data[activeIndex]?.activityName}
          theme={theme}
          subStyle={{ height: 20 }}
          sub={dayjs(data[activeIndex]?.startDate).format(lang === 'cn' ? dateFormatter : enDateFormatter)}
        />
        {isShowController ? (
          <CursorPagination
            className="activity-carousel-pagination"
            onItemClick={handleGoto}
            count={data.length}
            activeIndex={activeIndex}
          />
        ) : null}
      </div>
      {isShowController ? (
        <>
          <CircleButton className="circle-left" onClick={handlePrev} type="prev" />
          <CircleButton className="circle-right" onClick={handleNext} type="next" />
        </>
      ) : null}
    </div>
  );
};

export default Activity;
