/*
 * @LastEditors: Necfol
 * @Date: 2024-03-28 13:54:14
 * @LastEditTime: 2024-03-29 15:18:49
 * @FilePath: /emp-official-ui/src/pages/Go1688/purchaser.tsx
 */
import React, { useState, useEffect, useContext } from 'react';
import Helmet from 'components/Helmet';
import { createContactUsModal } from 'components/ContactUs';
import './purchaser.scss';


function PurchaserPage() {
  return (
    <React.Fragment>
      <Helmet
        title="杭州端点网络科技有限公司-新商业软件提供商-数字化转型-全链路解决方案"
        description=''
      />
      <div key="purchaser-page-content">
        <div
          className='purchaser-banner'
        >
          <img className='purchaser-banner-background' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/996e2f76-ed5a-4395-89bb-885948e79bca.png" alt="" draggable="false" />
          <div className='purchaser-banner-inner'>
            <div className='purchaser-banner-inner-title'>企业采购平台</div>
            <div className='purchaser-banner-inner-desc'>专注大型企业采购数字化协同管理</div>
            <button className='purchaser-banner-inner-btn' onClick={() => createContactUsModal()}>立即咨询</button>
          </div>

        </div>
        <div
          className='purchaser-settle'
        >
          <img className='purchaser-settle-background' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/791c74f7-82bf-4946-94fb-6683efb5ed7a.png" alt="" draggable="false" />
          <div className="purchaser-settle-inner">
            <img className='purchaser-settle-content' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/f440004e-2db3-46ac-b8dd-39a0b13e0dcf.png" alt="" draggable="false" />
            <a className='purchaser-settle-pay' href="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/db27cd82-3d37-40d9-948e-6f5c37413841.pdf" target='_blank' rel="noreferrer">查看收款信息</a>
          </div>

        </div>
        <div className="purchaser-settle-line-wrap">
          <div className="purchaser-settle-line-inner">
            <img className='purchaser-settle-img' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/e9ade181-76c3-4703-8cb5-0d64cecdc2c0.png" alt="" draggable="false" />
          </div>
        </div>
        <div className="purchaser-settle-line-wrap" style={{ background: '#ffffff' }}>
          <div className="purchaser-settle-line-inner">
            <img className='purchaser-settle-img' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/e7965ffc-e684-468f-8f88-99587ff5d77f.png" alt="" draggable="false" />
          </div>
        </div>
        <div className="purchaser-settle-line-wrap">
          <div className="purchaser-settle-line-inner">
            <img className='purchaser-settle-img' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/8ed0ba7a-590f-4100-a2a8-33a6e4ffe563.png" alt="" draggable="false" />
          </div>
        </div>
        <div className="purchaser-settle-line-wrap" style={{ background: '#ffffff' }}>
          <div className="purchaser-settle-line-inner">
            <img className='purchaser-settle-img' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/3303df3a-3e65-4f1a-8b9b-c7d009cf1e75.png" alt="" draggable="false" />
          </div>
        </div>
        <div className="purchaser-settle-line-wrap" style={{ background: '#5B6686' }}>
          <div className="purchaser-settle-line-inner">
            <img className='purchaser-settle-img' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/3f60d785-8829-4aa2-8837-c3da3dafb981.png" alt="" draggable="false" />
          </div>
        </div>
        <div className="purchaser-settle-line-wrap">
          <div className="purchaser-settle-line-inner">
            <img className='purchaser-settle-img' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/99e47975-a12e-4868-b02e-b80ed01b66c0.png" alt="" draggable="false" />
          </div>
        </div>
        <div className="purchaser-settle-line-wrap" style={{ background: '#C3CAE3' }}>
          <div className="purchaser-settle-line-inner">
            <img className='purchaser-settle-img' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/9c777815-9f65-4c91-a9cb-af30ad5d0502.png" alt="" draggable="false" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PurchaserPage;
