import { themeColor } from './box-cut';
import CanvasImage from './canvas-image';
import Palette from './palette';
import { Color, ExtractorOptions } from './types';
import { getImageByUrl } from './utils';

export const getColor = (image: HTMLImageElement, options: ExtractorOptions = {}): Color => {
  const { clip, palette, average } = options;
  const canvas = new CanvasImage(image, clip);
  const imageData = canvas.getImageData();
  if (average) {
    return new Palette(imageData, palette).getPalette();
  } else {
    return themeColor(imageData)[0] as Color;
  }
};

export const getColorByUrl = (source: string, options: ExtractorOptions = {}): PromiseLike<Color> => {
  return new Promise((resolve, reject) => {
    try {
      getImageByUrl(source).then((res) => {
        resolve(getColor(res, options));
      });
    } catch (error) {
      reject(error);
    }
  });
};

