import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Context from 'container';
import FullRichText from 'components/FullRichText';
import { getCompanyIntro, getCompanyIntroForPreview } from './services';
import Banner from 'components/Banner';
import { BANNER_TYPE } from 'components/constants';
import { oss } from 'scripts/utils';
import './intro.scss';

export default function About() {
  const [intro, setIntro] = useState({});
  const { currentSite = {} } = useContext(Context);
  const { id, preview } = useParams();
  const { describe, logo, title, mainTitle } = intro;

  useEffect(() => {
    (async () => {
      if (currentSite.id) {
        if (preview) {
          const data = await getCompanyIntroForPreview(id);
          setIntro(data);
        } else {
          const data = await getCompanyIntro({ site: { id: currentSite.id } });
          setIntro(data);
        }
      }
    })();
  }, [currentSite.id, id, preview]);

  return (
    <>
      <Banner
        type={BANNER_TYPE.DETAIL_HEADER}
        config={{
          src: '/static/images/join-us.png',
          mobileSrc: '/static/images/join-us.png',
          title: mainTitle,
          legend: title
        }}
      />
      <div className="comp-page-center">
        <div className="about-content">
          {logo ? <img className="about-logo" width={160} src={oss(logo, 320, 320)} alt="logo" /> : null}
          <FullRichText className="rich-text" data={describe ?? ''} />
        </div>
      </div>
    </>
  );
}
