import React, { useState, useEffect } from 'react';
import Context, { useContainer } from 'container';
import Ceiling from 'components/Ceiling';
import Footer from 'components/Footer';
import Router from './router';
import { defaultLanguage } from 'components/constants';
import { getSites, getSiteInfoById } from './services';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ScrollToTop from 'components/ScrollToTop';
import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';
import useLazyImage from 'scripts/hooks/useLazyImage';

export const STORAGE_LANG = 'TERMINUS_LANG';

interface IProps {
  staticContext: any;
}

interface languageType {
  id: number;
  title: string;
  desc: string;
  logo: string;
  mark: string;
  siteUrl: string;
  siteName: string;
  bottomNavigationList: any[];
  productList: any[];
  queryList: any[];
  solutionList: any[];
  successList: any[];
  topNavigationList: any[];
}

async function getSiteInfo(id: string | undefined, sites: any[], host: String) {
  const labels = sites.map((site) => site.mark);
  const [mark] = host.split('.');

  // default language is 'cn'
  let lang = defaultLanguage;
  if (labels.includes(mark)) {
    lang = mark;
  }

  let [site] = sites?.filter((_site: any) => _site.mark === lang);
  if (!site) {
    site = sites[0];
  }

  if (site?.id) {
    const language: any = await getSiteInfoById(site.id);

    return { currentSite: site, language, lang };
  }
}
function App({ staticContext }: IProps) {
  const host = (staticContext.host || window.location.host).replace('www.', '');
  const [sites, setSites]: [any[], Function] = useState(staticContext.sites || []);
  const [currentSite, setCurrentSite] = useState(staticContext.currentSite || {});
  const [language, setLanguage]: [languageType | undefined, Function] = useState(staticContext.language);
  const [lang, setLang]: [string | undefined, Function] = useState(staticContext.lang);
  const { state, dispatch } = useContainer();
  const { updateObserve } = useLazyImage({ offset: 100 });

  useEffect(() => {
    if (!currentSite.id) {
      fetchSite();
    }
  }, []);

  useEffect(() => {
    if (language) {
      document.body.setAttribute('class', (language.mark || '').toLowerCase());
    }
  }, [language]);

  useEffect(() => {
    localStorage.setItem(STORAGE_LANG, lang ?? '');
  }, [lang]);

  async function fetchSite() {
    const result = await App.getInitialProps(host);
    setSites(result.sites || []);
    setCurrentSite(result.currentSite || {});
    setLanguage(result.language);
    setLang(result.lang);
    result.lang === 'cn' ? dayjs.locale('zh-cn') : dayjs.locale('en');
  }

  return (
    <Context.Provider
      value={{
        ...state,
        dispatch,
        sites,
        currentSite,
        language,
        host,
        lang,
        updateObserve
      }}
    >
      <Ceiling />
      <Router initialData={staticContext.initialData} route={staticContext.currentRoute} />
      <Footer />
      <ScrollToTop />
    </Context.Provider>
  );
}

App.getInitialProps = async (host: String) => {
  const sites: any = await getSites();
  const result = await getSiteInfo(undefined, sites, host);
  return { sites, ...result };
};

export default App;
