import React, { useState, useEffect, useContext } from 'react';
import Context from 'container';
import usePlatform from 'hooks/usePlatform';
import useLanguage from 'hooks/useLanguage';
import useBannerConfig from 'hooks/useBannerConfig';
import Helmet from 'components/Helmet';
import Banner from 'components/Banner';

import { BANNER_TYPE, buttonThemes, linkIcons } from 'components/constants';
import { getBannerOperationExtraAttr } from 'scripts/utils';
import { getOverviewPageData } from 'services';
import { postProductList } from './service';
import ProductList from './product-list';

function ProductPage() {
  const [visible, setVisible] = useState(false);
  const { isPc } = usePlatform() || {};
  const { i18n } = useLanguage();
  const banner = useBannerConfig();
  const context = useContext(Context);
  const { currentSite = {} } = context;
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      if (!currentSite.id) return;
      const data = await postProductList({
        site: {
          id: currentSite.id
        }
      });
      setData(data);
      // setMenus(products);
    })();
  }, [currentSite.id]);

  function videoCallback(info) {
    toggleVideo();
    window.history.pushState({ videoPlayerVisible: !visible }, '', window.location.href);
  }

  function toggleVideo() {
    setVisible(!visible);
  }

  return (
    <React.Fragment>
      <Helmet title={`${banner.title || '产品'}-端点-数字化转型-全链路解决方案`} description={banner.legend} />
      <Banner
        covered
        type={BANNER_TYPE.DETAIL_HEADER}
        config={{
          // position: isPc ? 'left' : '',
          src: '/static/images/join-us.png',
          mobileSrc: '/static/images/join-us.png',
          title: banner.title || i18n('TOPNAVIGATION', '产品'),
          legend: banner.legend,
          operation: [
            {
              ...buttonThemes,
              text: banner?.jumpBO?.buttonTitle,
              icon: linkIcons[banner?.jumpBO?.jumpContentType],
              extraAttr: getBannerOperationExtraAttr(banner, () =>
                videoCallback({
                  title: banner.title,
                  desc: banner.legend,
                  videos: banner?.videoRelation?.videos
                })
              )
            }
          ]
        }}
      />
      <ProductList data={data} />
    </React.Fragment>
  );
}

export async function getOverviewData(id) {
  const result = await getOverviewPageData(id);
  return result.map((v) => {
    return {
      name: v.title,
      children: v.data.map((d) => {
        if (d.productPlate) {
          return {
            id: d.id,
            icon: d.proLogo,
            name: d.name,
            desc: d.naviDesc,
            longDesc: d.desc,
            link: `/product/${d.id}`,
            children:
              d.productPlate &&
              d.productPlate.map((plate) => ({
                name: plate.name,
                desc: plate.plateNaviDesc,
                longDesc: plate.desc,
                // TODO: 临时
                link:
                  plate.name === 'SAAS与生态' || plate.name === 'SaaS & Ecology'
                    ? ''
                    : plate.name === '设计体系 NUSI' || plate.name === 'Interaction Design System'
                    ? 'https://nusi.terminus.io'
                    : `/product/${d.id}/${plate.id}`,
                style: plate.name === 'SAAS与生态' || plate.name === 'SaaS & Ecology' ? { cursor: 'no-drop' } : {}
              }))
          };
        } else if (d.solutions) {
          return {
            id: d.id,
            icon: d.indusIcon,
            name: d.name,
            desc: d.naviDesc,
            longDesc: d.desc,
            children:
              d.solutions &&
              d.solutions.map((solution) => ({
                name: solution.name,
                desc: solution.naviDesc,
                longDesc: solution.desc,
                link: `/solution/${solution.id}`
              }))
          };
        }
        return undefined;
      })
    };
  });
}

export default ProductPage;
