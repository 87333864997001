import { AnchorItem } from 'components/Common/anchor';
import { useEffect, useState } from 'react';
import { isValid } from 'scripts/utils';
import { ANCHOR_HEADER } from './constants';

// TODO: createName
const createName = (tagName: string, anchorCount: Record<string, number>) => {
  let base = 'rich-text';
  const parentTagName = ANCHOR_HEADER[0];
  const childTagName = ANCHOR_HEADER[1];
  const parentIndex = anchorCount[parentTagName] ?? 0;
  base += `-${parentIndex}`;
  if (tagName === childTagName) {
    const childIndex = anchorCount[childTagName] ?? 0;
    base += `-${childIndex}`;
  }
  return base;
};

interface TagStackItem {
  tagName: string;
  item: AnchorItem;
}

const useAnchor = (data: string) => {
  const [anchorList, setAnchorList] = useState<AnchorItem[]>([]);
  const [result, setResult] = useState(data ?? '');
  useEffect(() => {
    if (data === undefined) return;
    const anchorCount: Record<string, number> = {};
    const list: AnchorItem[] = [];
    let domParser = new DOMParser();
    let doc = domParser.parseFromString(data, 'text/html');
    const children = doc.querySelector('body')?.children!;
    const tagItemMap = new Map();
    let tagStack: TagStackItem[] = [];
    const isLargestTag = (tagName: string, tags: TagStackItem[]) => {
      if (tags.length === 0) return true;
      const firstTag = tags[0].tagName;
      if (ANCHOR_HEADER.indexOf(tagName) < ANCHOR_HEADER.indexOf(firstTag)) {
        return true;
      }
      return false;
    };
    const isSmallestTag = (tagName: string, tags: TagStackItem[]) => {
      if (tags.length === 0) return false;
      const lastTag = tags[tags.length - 1].tagName;
      if (ANCHOR_HEADER.indexOf(tagName) >= ANCHOR_HEADER.indexOf(lastTag)) {
        return true;
      }
      return false;
    };
    const findParentIndex = (tagName: string) => {
      if (tagStack.length === 0) return null;
      if (tagItemMap.size === 0) return null;
      for (let i = tagStack.length - 1; i >= 0; i--) {
        if (ANCHOR_HEADER.indexOf(tagStack[i].tagName) < ANCHOR_HEADER.indexOf(tagName)) {
          return i;
        }
      }
      return null;
    };
    for (let i = 0; i < children.length; i++) {
      const element = children[i];
      const innerText = (element as HTMLHeadElement).innerText;
      if (!innerText) continue;
      const tagName = element.tagName;
      if (ANCHOR_HEADER.includes(tagName)) {
        if (anchorCount[tagName]) {
          anchorCount[tagName] += 1;
        } else {
          anchorCount[tagName] = 1;
        }
        const id = createName(tagName, anchorCount);
        element.setAttribute('id', id);
        const item = { id, title: innerText, children: [] };
        if (isLargestTag(tagName, tagStack)) {
          list.push(item);
          tagStack = [];
        } else if (isSmallestTag(tagName, tagStack)) {
          const parentIndex = findParentIndex(tagName);
          if (isValid(parentIndex)) {
            const parentItem = tagStack[parentIndex!].item;
            parentItem.children!.push(item);
          } else {
            list.push(item);
          }
        } else {
          const parentIndex = findParentIndex(tagName);
          if (isValid(parentIndex)) {
            const parentItem = tagStack[parentIndex!].item;
            parentItem.children!.push(item);
          } else {
            list.push(item);
            tagStack = [];
          }
        }
        tagStack.push({ tagName, item });
        tagItemMap.set(item, item);
      }
    }
    setAnchorList(list);
    setResult(doc.querySelector('body')!.innerHTML);
  }, [data]);

  return [result, anchorList];
};

export default useAnchor;
