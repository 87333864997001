import axios from 'axios';
import { isPlainObject } from 'lodash';
import { isSSR } from 'scripts/utils';
// import Toast from 'components/Toast';

// TODO:
axios.defaults.baseURL = isSSR ? `http://${process.env.TERMINUS_OFFICIAL_SITE_HOST || 'localhost'}:${process.env.TERMINUS_OFFICIAL_SITE_PORT || 3100}` : undefined;

axios.interceptors.request.use((request) => {
  request.headers.Pragma = 'no-cache';
  return request;
});

axios.interceptors.response.use(response => {
  const responseData = response.data.data
  if (isPlainObject(responseData)) {
    const { code, data, message } = responseData;

    if (code === '200') {
        return data;
    } else {
      // Toast.error(message || '未知错误');
      throw new Error(message);
    }
  }

  return responseData;
}, error => {
  if(error.response?.status === 500) {
    if (isPlainObject(error.response.data)) {
      const { msg } = error.response.data;
      // Toast.error(msg || error.response.statusText);
      console.error(error.response.config.url, msg || error.response.statusText);
    }
  }
  // if (error.response.status === 401) {
  //   if (!error.response.config.headers.skipRedirect) {
  //     const target = `${window.location.pathname}${window.location.search}`;
  //     window.location.href = `/login${(target && target !== '/') ? `?target=${target}` : ''}`;
  //   }
  // } else {
  //   if (error.response.status === 404) {
  //     Toast.error(`${error.response.status}, ${error.response.data.path} not found.`);
  //   } else {
  //     Toast.error(error.response.data.message || error.response.data.error || error.response.data);
  //   }

  //   return Promise.reject(error);
  // }

  return Promise.reject(error);
});
