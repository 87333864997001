import React from 'react';
import dayjs from 'dayjs';
import Button from 'components/Button';
import { fieldTranslation } from 'components/constants';
import useLanguage from 'hooks/useLanguage';
import './live-button.scss'

interface IProps {
  endDate: number,
  startDate: number,
}

const showLive = (startDate: number, endDate: number) => {
  if (dayjs().isAfter(endDate, 'date')) {
    return false
  }
  if (dayjs().isBefore(startDate, 'date')) {
    return false
  }
  return true
}

const LiveButton = ({ endDate, startDate }: IProps) => {
  if (!showLive(startDate, endDate)) {
    return null
  }
  return (
    <div className="live-button">
      <img src='/static/images/live.svg' />
      直播中
    </div>
  );
};
export default LiveButton;

interface IPropsJump extends IProps{
  liveAddress?: string
}


export const LiveJump = ({ liveAddress = '', startDate }: IPropsJump) => {
  const { i18n } = useLanguage();
  return (
    <div className='live-jump'>
      <Button
        type='primary'
        suffix
        target="_blank"
        jumpConfig={{
          buttonTitle: dayjs().isBefore(startDate, 'minute') ? i18n(fieldTranslation, '预约直播') : i18n(fieldTranslation, '前往直播间'),
          buttonLink: liveAddress
        }}
      />
    </div>
)}