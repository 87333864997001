import React, { useState } from 'react';
import classnames from 'classnames';
import { isNil } from 'lodash';
import Tooltip from 'rc-tooltip';
import useLanguage from 'hooks/useLanguage';
import Icon from 'components/Icon';
import Toast from 'components/Toast';
import Input from './Input';
import TextArea from './TextArea';
import Radio from './Radio';
import Checkbox from './Checkbox';
import Select from './Select';
import Button from 'components/Button';
import './index.scss';
import './tooltip.scss';

export default function FormCreator({ className, disabled, config, onSubmit }) {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false)
  const { i18n } = useLanguage();

  function onFocusHandle(name) {
    delete errors[name];
    setErrors({ ...errors });
  }

  function onChangeHandle(value, conf) {
    onFocusHandle(conf.name);
    conf.onChange instanceof Function && conf.onChange(value);

    setValues({ ...values, [conf.name]: value });
  }

  function validator(values) {
    const error = {};
    config.forEach((conf) => {
      const value = values[conf.name];

      if (!(conf.required === false) && isNil(value)) {
        error[conf.name] = `${conf.label}不能为空`;
        return;
      }

      if (!(isNil(value) || value === '') && conf.validator instanceof Array) {
        conf.validator.forEach((valid) => {
          if (valid.pattern instanceof RegExp && !valid.pattern.test(value)) {
            error[conf.name] = valid.message;
            return;
          }
        });
      }
    });

    setErrors(error);

    if (Object.keys(error).length) {
      return false;
    }

    return true;
  }

  async function onSubmitHandle() {
    let _values = {};
    Object.keys(values).forEach((key) => {
      const value = values[key];

      if (!isNil(value) || (value instanceof Array && value.length)) {
        _values[key] = value;
      }
    });

    if (onSubmit instanceof Function) {
      if (validator(_values)) {
        if (loading) return
        setLoading(true)
        onSubmit(_values);
      } else {
        Toast.error('请正确填写');
      }
    }
    setLoading(false)
  }

  function renderFormComponent(conf, index) {
    switch (conf.type) {
      case 'INPUT':
        return (
          <Input
            config={conf}
            onFocus={() => onFocusHandle(conf.name)}
            onChange={(value) => onChangeHandle(value, conf)}
          />
        );
      case 'TEXTAREA':
        return (
          <TextArea
            config={conf}
            onFocus={() => onFocusHandle(conf.name)}
            onChange={(value) => onChangeHandle(value, conf)}
          />
        );
      case 'RADIO':
        return (
          <Radio
            config={conf}
            onFocus={() => onFocusHandle(conf.name)}
            onChange={(value) => onChangeHandle(value, conf)}
          />
        );
      case 'CHECKBOX':
        return (
          <Checkbox
            config={conf}
            onFocus={() => onFocusHandle(conf.name)}
            onChange={(value) => onChangeHandle(value, conf)}
          />
        );
      case 'SELECT':
        return (
          <Select
            config={conf}
            onFocus={() => onFocusHandle(conf.name)}
            onChange={(value) => onChangeHandle(value, conf)}
          />
        );
      default:
        return null;
    }
  }

  return (
    <form className={classnames('form-creator', className)}>
      {config.map((conf, index) => {
        if (conf.isRender === false || (conf.isRender instanceof Function && !conf.isRender(values))) {
          return null;
        }

        return (
          <section
            key={index}
            className={classnames({
              error: errors[conf.name]
            })}
          >
            {conf.label ? (
              <span
                className={classnames('form-creator-label', {
                  required: !(conf.required === false)
                })}
              >
                {conf.label}
                {conf.tips ? (
                  <Tooltip overlay={conf.tips}>
                    <Icon type="question-circle" />
                  </Tooltip>
                ) : null}
              </span>
            ) : null}
            {renderFormComponent(conf, index)}
            {errors[conf.name] ? <p className="form-creator-error">{errors[conf.name]}</p> : null}
          </section>
        );
      })}
      {config.length && onSubmit ? (
        <Button icon={false} onClick={onSubmitHandle} className={classnames({ disabled: loading })} disabled={disabled}>
          {i18n('LISTQUERY', '确定')}
        </Button>
      ) : null}
    </form>
  );
}
