import React from 'react';
import './content-wrapper.scss'

interface IProps {
  content: string
}
const ContentWrapper = ({ content }: IProps) => (
  <div className="content-detail-wrapper" dangerouslySetInnerHTML={{__html: typeof content === 'string' ? content : '' }} />
)

export default ContentWrapper