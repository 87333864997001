import { useMemo } from 'react';
import useWindowDimensions from './useWindowDimensions';

export default function usePlatform() {
  const { width } = useWindowDimensions() || {};

  const isMobile = useMemo(() => width < 768, [width]);

  const isPc = useMemo(() => width >= 768, [width]);
  return { isMobile, isPc };
}
