import MainTitle from 'components/Common/main-title';
import { fieldTranslation } from 'components/constants';
import container from 'container';
import useLanguage from 'hooks/useLanguage';
import { map } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { oss } from 'scripts/utils';
import { SolutionsData } from '.';
import { postSolutionList } from './services';
import './mobile-solution.scss';
import { Link } from 'react-router-dom';

const MobileHome = () => {
  const [data, setData] = useState<SolutionsData[]>([]);
  const { currentSite, updateObserve } = useContext(container);
  const { i18n } = useLanguage();
  useEffect(() => {
    if (currentSite.id) {
      postSolutionList({
        site: {
          id: currentSite.id
        }
      }).then((res) => {
        const result = res as unknown as SolutionsData[];
        setData(result);
        updateObserve();
      });
    }
  }, [currentSite.id]);
  return (
    <div className="hidden-in-pc">
      <MainTitle className="mobile-tabs-title" main={i18n(fieldTranslation, '行业解决方案')} />
      <div className="mobile-solution-wrapper">
        {map(data, (d, index) => {
          return (
            <div key={index} className="mobile-solution-item">
              <Link to={`/solution#${index}`}>
                <img data-src={oss(d.industry.indusIcon, 670, 256)} alt="" />
                <div className="mobile-solution-item-footer">{d.industry.name}</div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MobileHome;
