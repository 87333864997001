import { IconWrapper, ISvgIconProps } from '.';

export default IconWrapper('menu', true, (props: ISvgIconProps) => (
  <svg width={props.size} height={props.size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <polygon points="0,0 20,0 20,20 0,20 " />
    <path
      fill={props.colors[0]}
      // stroke={props.colors[0]}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
      strokeLinejoin={props.strokeLinejoin}
      d="M2.5,3.3h15V5h-15V3.3z M2.5,9.2h15v1.7h-15V9.2z M2.5,15h15v1.7h-15V15z"
    />
  </svg>
));
