import { useContext, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getPeriodicalDetail } from './setvice';
import './detail.scss';
import { Resource } from 'components/types';
import { getBannerOperationExtraAttr, oss } from 'scripts/utils';
import Context from 'container';
import Button from 'components/Button';
import FileDown from 'components/IconWrapper/file-down';
import Divider from 'components/Common/divider';
import Helmet from 'components/Helmet';
import FullRichText from 'components/FullRichText';
import Banner from 'components/Banner';
import React from 'react';
import { BANNER_TYPE, fieldTranslation } from 'components/constants';
import MainTitle from 'components/Common/main-title';
import { CaseSwitch } from '../Product/detail'
import usePlatform from 'hooks/usePlatform';
import useLanguage from 'hooks/useLanguage';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { Case } from 'pages/Cases/cases-list';

interface IPeriodicalDetail {
  buttonName: string;
  introduction: string;
  name: string;
  picture: string;
  backgroundPicture: string;
  mobileBackgroundPicture?: string;
  file: Resource;
  jumpBO: any;
  type: 'WHITE_PAPER' | 'PUBLICATION'
  publicationDescribe?: string
  exampleBOS?: Case[];
  basePicture?: string
  mobileBasePicture?: string
}

const list = [
  { name: '制造', src: "/static/images/制造.png"},
  { name: '零售', src: "/static/images/零售.png"},
  { name: '能源', src: "/static/images/能源.png"}
]

export const PeriodicalDetail = () => {
  const { isPc } = usePlatform() || {};
  const { i18n } = useLanguage();
  const { id } = useParams<{ id: string }>();
  const [detail, setDetail] = useState<IPeriodicalDetail | null>(null);
  const { lang } = useContext(Context);
  useEffect(() => {
    if (id) {
      getPeriodicalDetail(id).then((res) => {
        setDetail(res as unknown as IPeriodicalDetail);
      });
    }
  }, [id]);
  const jumpBO = detail?.jumpBO;

  const basePicture = isPc ? detail?.basePicture : detail?.mobileBasePicture
  const backgroundPicture = isPc ? detail?.backgroundPicture : detail?.mobileBackgroundPicture
  return (
    <React.Fragment>
      <Helmet title="端点-Terminus-数字化转型-商业软件" description={detail?.name} />
      {detail?.type === 'PUBLICATION' ? <div className="periodical-detail-wrapper">
        <div className="detail-item">
          <img src={oss(detail?.picture, 368, 442)} alt="" />
          <div className="detail-item-content">
            <span>{lang === 'cn' ? '刊物' : 'Journal'}</span>
            <div className="title">{detail?.name}</div>
            <div className="intro">{detail?.introduction}</div>
            {jumpBO ? (
              <Button
                suffix={false}
                prefix={<FileDown />}
                className="detail-btn"
                {...getBannerOperationExtraAttr({ jumpBO })}
              >
                {jumpBO.buttonTitle}
              </Button>
            ) : null}
          </div>
        </div>
        <Divider className="divider hidden-in-mobile" />
      </div> : <div>
        <Banner
          paddingTop={isPc ? 200 : 200}
          height={isPc ? 450 : 300}
          covered
          config={{
            type: BANNER_TYPE.CAROUSEL,
            src: backgroundPicture,
            title: detail?.name,
            legend: detail?.introduction,
            operation: [
              {
                text: jumpBO?.buttonTitle || '下载白皮书',
                extraAttr: detail && getBannerOperationExtraAttr(detail)
              },
            ]
          }}
        />
        <FullRichText className="rich-text" data={detail?.publicationDescribe} />
        <div className={classNames('plate-cases', { hidden: isEmpty(detail?.exampleBOS) })}>
          <MainTitle main={i18n(fieldTranslation, '行业企业的转型需求与应用升级')} />
          <CaseSwitch
            className="plate-cases-list cases-periodical"
            renderItem={(item: Case, index: number) => (
              <div style={{ width: 320 }} className="card">
                <Link className="card-inner" to={`/case-detail/${item.id}`} target='_blank'>
                  <img className="card-img" src={`${list[index]?.src || oss(item.backgroundMaterial, 320, 160)}`} alt="" />
                  <div className="card-content">
                    <div className="group">{list[index]?.name}</div>
                    <div className="title">{item.desc}</div>
                    <div className="name">{item.title}</div>
                    <img className="card-logo" src={oss(item.customerLogo, 100, 24, 'lfit')} alt="" />
                    <Button type='default' suffix>{i18n(fieldTranslation, '查看详情')}</Button>
                  </div>
                  <div className="card-mask"></div>
                  <div className="card-mask-footer">
                    {list[index]?.name || item.customerName}
                  </div>
                </Link>
              </div>
            )}
            data={detail?.exampleBOS} />
        </div>
        <div className='periodical-footer' style={{ backgroundImage: basePicture ? `url(${oss(basePicture, 1440, 400)})` : undefined }}>
          <Button
            type='primary'
            {...getBannerOperationExtraAttr({ jumpBO })}
          >{jumpBO?.buttonTitle || '下载白皮书'}</Button>
        </div>
      </div>}
    </React.Fragment>
  );
};
