import React, { useState, useEffect, useContext } from 'react';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import Context from 'container';
import useLanguage from 'hooks/useLanguage';
import useBannerConfig from 'hooks/useBannerConfig';
import Helmet from 'components/Helmet';
import Banner from 'components/Banner';
import FullRichText from 'components/FullRichText';
import { BANNER_TYPE } from 'components/constants';
import { getServiceAndSupportDetail, getServiceAndSupportDetailForPreview } from 'services';
import './services.scss';

function ServicesPage({ initialData }) {
  const { i18n } = useLanguage();
  const context = useContext(Context);
  const { currentSite = {} } = context;
  const params = useParams();
  const banner = useBannerConfig();
  const [data, setData] = useState(initialData || {});

  useEffect(() => {
    (async () => {
      if (isEmpty(initialData) && currentSite.id) {
        const result = await ServicesPage.getInitialProps({ context, params });
        setData(result);
      }
    })();
  }, [currentSite.id, params.id]);

  return (
    <React.Fragment>
      <Helmet title="数字化转型案例-端点-Terminus-全链路解决方案" description={banner.legend} />
      <Banner
        // covered
        type={BANNER_TYPE.DETAIL_HEADER}
        config={{
          src: '/static/images/support.png',
          mobileSrc: '/static/images/support.png',
          title: banner.title || i18n('TOPNAVIGATION', '服务与支持'),
          legend: banner.legend
        }}
      />
      <FullRichText className="service-text-wrapper" showAnchor={true} data={data?.describe} />
    </React.Fragment>
  );
}

ServicesPage.getInitialProps = async ({ context, params }) => {
  if (params.id) {
    return getServiceAndSupportDetailForPreview(params.id);
  }

  const { currentSite = {} } = context;
  return getServiceAndSupportDetail({ site: { id: currentSite.id } });
};

export default ServicesPage;
