import Home from './pages/Home';
import Product from './pages/Product';
import Cases from './pages/Cases';
import AboutUs from './pages/AboutUs';
import ProductDetail from './pages/Product/detail';
import ProductCategory from './pages/Product/product-category';
import Solution from './pages/solution';
import SolutionDetail from './pages/solution/detail';
import ArticleDetail from './pages/ArticleDetail';
import ActivityDetail from './pages/Activity/detail';
import MediaDetail from './pages/Media/detail';
import Services from './pages/Services';
import JoinUs from './pages/JoinUs';
import CaseDetail from './pages/Cases/case-detail';
import Go1688 from './pages/Go1688';
import GoSupplier from './pages/Go1688/supplier';
import GoPurchaser from './pages/Go1688/purchaser';
import NotFound from './components/NotFound';
import { Periodical } from 'pages/Periodical';
import { PeriodicalDetail } from 'pages/Periodical/detail';
import Activity from 'pages/Activity';
import Media from 'pages/Media';

const config = [
  {
    path: '/',
    exact: true,
    component: Home
  },
  {
    path: '/product-category/:id',
    exact: true,
    component: ProductCategory
  },
  {
    path: '/product',
    exact: true,
    component: Product
  },
  {
    path: '/product/:id',
    exact: true,
    component: ProductDetail
  },
  {
    path: '/solution',
    exact: true,
    component: Solution
  },
  {
    path: '/solution/:id',
    exact: true,
    component: SolutionDetail
  },
  {
    path: '/cases',
    exact: true,
    component: Cases
  },
  {
    path: '/about-us',
    exact: true,
    component: AboutUs
  },
  {
    path: '/about-us/:type',
    exact: true,
    component: AboutUs
  },
  {
    path: '/about-us/:type/:id/preview',
    exact: true,
    component: AboutUs
  },
  {
    path: '/about_us',
    exact: true,
    component: AboutUs
  },
  {
    path: '/article-detail/:type/:id',
    exact: true,
    component: ArticleDetail
  },
  {
    path: '/article-detail/:type/:id/preview',
    exact: true,
    component: ArticleDetail
  },
  {
    path: '/case-detail/:id',
    exact: true,
    component: CaseDetail
  },
  {
    path: '/form/:id',
    exact: true,
    component: Home
  },
  {
    path: '/form/:id/preview',
    exact: true,
    component: Home
  },
  {
    path: '/services',
    exact: true,
    component: Services
  },
  {
    path: '/services/:id/preview',
    exact: true,
    component: Services
  },
  {
    path: '/join-us',
    exact: true,
    component: JoinUs
  },
  {
    path: '/join-us/:id/preview',
    exact: true,
    component: JoinUs
  },
  {
    path: '/periodical',
    exact: true,
    component: Periodical
  },
  {
    path: '/periodical/:id',
    exact: true,
    component: PeriodicalDetail
  },
  {
    path: '/go1688',
    exact: true,
    component: Go1688
  },
  {
    path: '/activity',
    exact: true,
    component: Activity
  },
  {
    path: '/activity/:id',
    exact: true,
    component: ActivityDetail
  },
  {
    path: '/media',
    exact: true,
    component: Media
  },
  {
    path: '/Media/:id',
    exact: true,
    component: MediaDetail
  },
  {
    path: '/go1688',
    exact: true,
    component: Go1688
  },
  {
    path: '/go1688/purchaser',
    exact: true,
    component: GoPurchaser
  },
  {
    path: '/go1688/supplier',
    exact: true,
    component: GoSupplier
  },
  {
    path: '*',
    component: NotFound
  }
];

export default config;
