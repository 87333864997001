import { IconWrapper, ISvgIconProps } from '.';
export default IconWrapper('forward-play', true, (props: ISvgIconProps) => (
  <svg width={props.size} height={props.size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g xmlns="http://www.w3.org/2000/svg" id="编组" transform="translate(0.000000, 2.000000)">
      <polygon points="0,-2 16,-2 16,14 0,14  " />
      <path
        fill={props.colors[0]}
        d="M8.7,9.3v2H12v1.3H4v-1.3h3.3v-2H2.7C2.3,9.3,2,9,2,8.7v-8H1.3v-1.3h13.3v1.3H14v8   c0,0.4-0.3,0.7-0.7,0.7H8.7z M3.3,8h9.3V0.7H3.3V8z M6.7,2L10,4.3L6.7,6.7V2z"
      />
    </g>
  </svg>
));
