import React, { Component } from 'react';
import Icon from 'components/Icon';
import './ToastItem.scss';

export default class ToastItem extends Component {
  componentDidMount() {
    const { id, duration = 1000, onClose, isShowMask } = this.props;
    this.timer = setTimeout(() => {
      if (onClose) {
        onClose(id, isShowMask);
      }
    }, duration)
  }

  // 卸载组件后，清除定时器
  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  render() {
    const { text } = this.props;
    return (
      <div className="toast-item">
        <Icon type="info" />
        {text}
      </div>
    );
  }
}
