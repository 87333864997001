import React, { useState, useEffect, useContext } from 'react';
import Context from 'container';
import Footer from './footer';
import { isSSR } from 'scripts/utils';
import { getBottomNavigation } from './services';

export default function FooterWrapper() {
  const { currentSite = {} } = useContext(Context);
  const [footerData, setFooterData] = useState({});

  const { hostname = '' } = !isSSR ? window.location : {};
  const [host = hostname, name] = hostname.match(/([^\\.]*?)\.[^\\.]*?$/) || [];
  const recordNumber = ({
    'terminus': 3,
    'quanzhi100': 4,
    'duandian': 5,
  })[name];

  useEffect(() => {
    (async() => {
      if(currentSite.id) {
        const {
          briefIntroductionList,
          aboutEndpointsList,
          discoverMoreList,
          quickLinksList,
        } = await getBottomNavigation({ site: { id: currentSite.id } });
        setFooterData({
          quickLinks: [{
            title: '关于端点',
            content: aboutEndpointsList.map((single) => ({
              title: single.menu,
              type: single.jumpMode,
              url: single.jumpToAddress,
            })),
          }, {
            title: '快速链接',
            content: quickLinksList.map((single) => ({
              title: single.menu,
              type: single.jumpMode,
              url: single.jumpToAddress,
            })),
          }, {
            title: '发现更多',
            content: discoverMoreList.map((single) => ({
              title: single.menu,
              type: single.jumpMode,
              url: single.codePicture,
            })),
          }],
          others: briefIntroductionList.map((single) => ({
            icon: single.logo,
            text: single.introductionContent
          })),
        });
      }
    })();
  }, [currentSite.id]);

  return (
    <Footer host={host} recordNumber={recordNumber} footerData={footerData} />
  );
}
