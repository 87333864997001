import { useContext, useMemo } from 'react';
import Context from 'container';

export default function useLanguage() {
  const { language } = useContext(Context);

  const languages = useMemo(() => {
    if(language) {
      const langs = {};
      Object.values(language).forEach(_langs => {
        if(_langs instanceof Array) {
          _langs.forEach(lang => {
            if(langs[lang.identification] instanceof Array) {
              langs[lang.identification].push(lang);
            } else {
              langs[lang.identification] = [lang];
            }
          })
        }
      });
      return langs;
    }
  }, [language]);

  function i18n(module, key) {
    if(language) {
      const moduleLanguages = languages?.[module];
      if(moduleLanguages instanceof Array) {
        const [_lang] = moduleLanguages.filter(lang => lang.modular === key);
        const { languageSetting } = _lang || {};
        return languageSetting || key;
      }
    }

    return key;
  }

  return { i18n };
}
