import axios from 'axios';
import { TitleType } from './constants';

export const getTitle = (params: {
  type: TitleType;
  site: {
    id: number;
  };
}): Promise<{ title: string; desc: string }> => {
  return axios.post(`/api/internal/get/title/by/type`, params);
};
