import Button from 'components/Button';
import { IJumpBO } from 'components/types';
import { CSSProperties, useContext } from 'react';
import Context from 'container';
import './index.scss';
import classNames from 'classnames';
import MainTitle from 'components/Common/main-title';
import { createContactUsModal } from 'components/ContactUs';

interface IConsultProps {
  isDetail?: boolean;
  config?: {
    title?: string;
    desc?: string;
    jumpBO?: IJumpBO;
    [propName: string]: any;
  };
}

const Consult: React.FC<IConsultProps> = (props) => {
  const { config, isDetail } = props;
  const { title, desc } = config ?? {};
  const { lang } = useContext(Context);
  const detailButtonStyle: CSSProperties = {
    backgroundColor: '#fff',
    color: '#0366DD',
    fontWeight: 600
  };

  return (
    <div className={classNames({ consult: !isDetail }, { 'detail-consult': isDetail })}>
      {isDetail ? null : (
        <div className="content">
          <MainTitle theme="dark" main={title ?? ''} sub={desc} />
        </div>
      )}

      <div className="button">
        <Button style={detailButtonStyle} onClick={() => createContactUsModal()} fill={'#FF9C42'}>
          {lang === 'cn' ? '立即咨询' : 'Consult'}
        </Button>
      </div>
    </div>
  );
};

export default Consult;
