import cn from 'classnames';
import PlayOne from 'components/IconWrapper/play-one';
import { useContext, useState } from 'react';
import Context from 'container';
import './video-icon.scss';

interface IVideoIconProps {
  className?: string;
  active?: boolean;
  children?: React.ReactNode;
  hoverActive?: boolean;
}

const VideoIcon = (props: IVideoIconProps & React.HTMLAttributes<HTMLDivElement>) => {
  const { className, active, children, hoverActive = true, ...restProps } = props;
  const { lang } = useContext(Context);
  const [_active, _setActive] = useState(false);
  const isActive = active || _active;
  const handleEnter = () => {
    if (hoverActive) {
      _setActive(true);
    }
  };
  const handleLeave = () => {
    if (hoverActive) {
      _setActive(false);
    }
  };
  return (
    <div
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      className={cn('video-wrapper', className)}
      {...restProps}
    >
      <div className={cn({ 'active-circle': isActive }, 'video-circle')}>
        <PlayOne strokeLinejoin={'round'} fill={'#fff'} />
      </div>
      <span className={cn('active-text', { 'active-anim': isActive, 'active-anim-out': !isActive })}>
        {children || (lang === 'cn' ? '立即播放' : 'Play')}
      </span>
    </div>
  );
};

export default VideoIcon;
