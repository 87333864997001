import React from 'react';
import ContentHeader from './content-header';
import ContentWrapper from './content-wrapper';
import './index.scss';

export default function ArticleDetail({ detail, type }) {
  return (
    <div className="comp-root">
      <div className="comp-content">
        <div className="content-detail">
          <ContentHeader
            title={detail.title}
            label={detail.label}
            time={detail.createTime || detail.createdAt}
            desc={detail.desc}
            mediaCoverageList={detail.mediaCoverageList}
          />
          <ContentWrapper content={detail.content} />
          <div className="content-detail-footer">
            {detail.author ? <div>作者 / {detail.author}</div> : null}
            {detail.edit ? <div>编辑 / {detail.edit}</div> : null}
          </div>
        </div>
      </div>
    </div>
  );
}
