import React, { useState, useEffect, useContext } from 'react';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import Context from 'container';
import usePlatform from 'hooks/usePlatform';
import Helmet from 'components/Helmet';
import Banner from 'components/Banner';
// import Descriptions from 'components/Descriptions';
// import Category from 'components/Category';
import VideoPlayer from 'components/VideoPlayer';
import { BANNER_TYPE, buttonThemes, linkIcons } from 'components/constants';
import News from 'components/HomeNews';
import { getBannerOperationExtraAttr } from 'scripts/utils';
import { getHomePageData } from 'services';
import Consult from 'components/Consult';
import SolutionCarousel from 'pages/solution/solution-carousel';
import CaseShow from 'components/CaseShow';
import MobileSolution from './solution/mobile-solution';
import { createContactUsModal } from 'components/ContactUs';

function HomePage({ initialData }) {
  const { isPc } = usePlatform() || {};
  const context = useContext(Context);
  const { currentSite = {} } = context;
  const [data, setData] = useState(initialData || []);
  const [videosInfo, setVideosInfo] = useState({});
  const [visible, setVisible] = useState(false);
  const examples = data.filter((v) => v.moduleType === 'Example');
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    return () => {
      window.onpopstate = null;
    };
  }, []);

  useEffect(() => {
    if (id && window.location.pathname.includes('/form')) {
      createContactUsModal({ formId: id })
    }
  }, [id])

  useEffect(() => {
    window.onpopstate = function () {
      setVisible(false);
      window.history.replaceState({ videoPlayerVisible: false }, '');
    };
  }, [visible]);

  useEffect(() => {
    if (isEmpty(initialData) && currentSite.id) {
      (async () => {
        const result = await HomePage.getInitialProps({ context });
        setData(result);
      })();
    }
  }, [currentSite.id]);

  function videoCallback(info) {
    setVideosInfo(info);
    toggleVideo();
    if (isEmpty(window.history.state?.videoPlayerVisible)) {
      window.history.pushState({ videoPlayerVisible: true }, '', window.location.href);
    }
  }

  function toggleVideo() {
    setVisible(!visible);
    window.history.replaceState({ videoPlayerVisible: !visible }, '');
  }

  return (
    <React.Fragment>
      <Helmet
        title="杭州端点网络科技有限公司-新商业软件提供商-数字化转型-全链路解决方案"
        // description={banner.legend}
      />
      <div key="home-page-content">
        {data.map((v, index) => {
          const getConfig = (opts) => {
            if (opts instanceof Array) {
              return opts.map((carousel) => {
                const { files = [] } = carousel[isPc ? 'pcResource' : 'mobileResource'] || {};

                return {
                  // position: isPc ? 'left' : 'center',
                  src: files[0]?.url,
                  title: carousel.title,
                  subheading: carousel.subheading,
                  legend: carousel.desc,
                  operation: [
                    {
                      ...(index
                        ? buttonThemes
                        : {
                            theme: '#0366dd'
                            // backgroundImage: 'linear-gradient(134deg, #f593aa 0%, #e85a71 100%)',
                          }),
                      // icon: 'label',
                      text: carousel?.jumpBO?.buttonTitle,
                      icon: linkIcons[carousel?.jumpBO?.jumpContentType],
                      extraAttr: getBannerOperationExtraAttr(carousel)
                    }
                  ]
                };
              });
            }
          };
          if (v.moduleName === '案例') {
            return <CaseShow key={index} examples={examples} />;
          }
          if (v.moduleType === BANNER_TYPE.NEWS) {
            return <News key={index} config={v} />;
          }
          if (v.moduleType === BANNER_TYPE.BUSINESS_CONSULTING) {
            return <Consult key={index} config={v} />;
          }
          if (v.moduleType === BANNER_TYPE.SOLUTION) {
            return (
              <React.Fragment key={index}>
                <SolutionCarousel key={`_pc-${index}`} height={680} style={{ width: '100%' }} />
                <MobileSolution key={`_mobile-${index}`} />
              </React.Fragment>
            );
          }
          if (v.moduleType === BANNER_TYPE.CAROUSEL) {
            return (
              <Banner
                showAdvantage={index === 0}
                covered={!index}
                key={`home-page-${index}`}
                size="large"
                type={v.moduleType}
                name={v.moduleName}
                height={isPc ? (index ? undefined : 720) : index ? undefined : 320}
                config={getConfig(v.carouselOpt)}
              />
            );
          } else if (v.moduleType === 'Example') {
            return null;
          }

          let data;
          // let childrens;
          // if(v.moduleType === BANNER_TYPE.PRODUCT) {
          //   data = v;
          //   childrens = data.productPlateList instanceof Array && data.productPlateList.map(plate => ({ ...plate, link: `/product/${v.id}/${plate.id}` }));
          // } else if(v.moduleType === 'SOLUTION') {
          //   data = v;
          //   childrens = data.solutionList instanceof Array && data.solutionList.map(solution => ({ ...solution, link: `/solution/${solution.id}` }));
          // }

          const { files = [] } = v[isPc ? 'pcResource' : 'mobileResource'] || {};

          return (
            <React.Fragment key={`home-page-${index}`}>
              <Banner
                size="large"
                type={v.moduleType}
                // height={isPc ? undefined : 240}
                // position={isPc ? 'left' : 'center'}
                key={index}
                config={{
                  src: files[0]?.url,
                  title: v.title,
                  legend: v.desc,
                  operation: [
                    {
                      ...buttonThemes,
                      // icon: 'label',
                      text: data?.jumpBO?.buttonTitle,
                      icon: linkIcons[data?.jumpBO?.jumpContentType],
                      extraAttr: getBannerOperationExtraAttr(data)
                    }
                  ]
                }}
              />
              {/* {
                  childrens && (
                    <Category
                      theme="dark"
                      cloudTheme={v.moduleType === 'SOLUTION' ? 'quartzRose' : undefined}
                      categories={childrens.map(child => ({
                        icon: child.proPlateLogo || child.solLogo,
                        name: child.name,
                        desc: child.desc,
                        link: child.link,
                      }))}
                    />
                  )
                } */}
            </React.Fragment>
          );
        })}
      </div>
      {visible ? <VideoPlayer videosInfo={videosInfo} visible={visible} onCancel={toggleVideo} /> : null}
    </React.Fragment>
  );
}

HomePage.getInitialProps = ({ context }) => {
  const { currentSite = {} } = context;

  return getHomePageData(currentSite.id);
};

export default HomePage;
