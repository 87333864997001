import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import usePlatform from 'hooks/usePlatform';
import useLanguage from 'hooks/useLanguage';
import Helmet from 'components/Helmet';
import Banner from 'components/Banner';
import FullRichText from 'components/FullRichText';
import { BANNER_TYPE, fieldTranslation } from 'components/constants';
import MainTitle from 'components/Common/main-title';
import './detail.scss';
import './product-category.scss'
import map from 'lodash/map';
import { Resource } from 'components/types';
import CircleButton from 'components/Common/circle-button';
import Slider, { Settings } from 'react-slick';
import cn from 'classnames';
import { Case } from 'pages/Cases/cases-list';
import { oss } from 'scripts/utils';
import Context from 'container';
import Consult from 'components/Consult';
import { getBannerOperationExtraAttr } from 'scripts/utils';
import { getProductCategory } from './service';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { ProductDetail } from './detail'

interface ProductCategory {
  id: number;
  desc: string;
  name: string;
  subtitle: string;
  content: string;
  pcResource: Resource;
  mobileResource: Resource;
  exampleBOList: Case[];
  // 白皮书下载
  jumpBO?: {
    attachment: Resource;
    buttonTitle: string;
  };
  // 预约演示
  demonstration: {
    attachment: Resource;
    buttonTitle: string;
  };
}

function PlateDetailPage() {
  const { isPc } = usePlatform() || {};
  const { i18n } = useLanguage();
  const { updateObserve } = useContext(Context);
  const [data, setData] = useState<ProductCategory | null>(null);
  const [productList, setProductList] = useState<ProductDetail[]>([]);
  const [exampleList, setExampleList] = useState<any>([]);
  const params = useParams<{ id: string }>();
  const { id } = params;

  let files: { url: string }[] = [];
  const { pcResource, mobileResource } = data ?? {};
  if (pcResource || mobileResource) {
    if (isPc) {
      files = pcResource?.files || [];
    } else {
      files = mobileResource?.files || [];
    }
  }

  useEffect(() => {
    (async () => {
      if (id) {
        const result: any = (await getProductCategory(id)) as unknown as ProductCategory;
        setData(result?.productCategory);
        setProductList(result?.productList || []);
        setExampleList(result?.exampleList || []);
        updateObserve();
      }
    })();
  }, [id]);
  return (
    <div className="category-wrap">
      <Helmet title={`${data?.desc ?? ''}-端点-数字化转型-全链路解决方案`} description={data?.desc} />
      <Banner
        paddingTop={isPc ? 200 : 200}
        height={isPc ? 550 : 300}
        covered
        config={{
          type: BANNER_TYPE.CAROUSEL,
          src: files[0]?.url,
          title: data?.name,
          legend: data?.desc,
          subheading: data?.subtitle,
          operation: [
            {
              text: data?.jumpBO?.buttonTitle,
              extraAttr: data && getBannerOperationExtraAttr(data)
            },
            {
              text: data?.demonstration?.buttonTitle,
              extraAttr: data && getBannerOperationExtraAttr({ ...data, jumpBO: data?.demonstration })
            }
          ]
        }}
      />
      <div className="category-footer">
        {productList.map((d) => {
          const { id, proLogo, name, desc } = d;
          return (
            <div key={id} className="item">
              <div className="title">
                <img width={30} src={proLogo} alt="" />
                <span>{name}</span>
              </div>
              <div className="content">{desc}</div>
              <a className="link" href={`/product/${id}`} target='_blank'>
                {`${i18n(fieldTranslation, '了解更多')} >`}
              </a>
            </div>
          );
        })}
      </div>
      <FullRichText className="rich-text" data={data?.content ?? ''} showAnchor={undefined} style={undefined}/>
      <div className={classNames('plate-cases', { hidden: isEmpty(exampleList) })}>
        <MainTitle main={i18n(fieldTranslation, '客户成功案例')} />
        <CaseSwitch className="plate-cases-list" data={exampleList} />
      </div>
      <Consult isDetail={true} />
    </div>
  );
}

interface ICaseSwitchProps {
  data?: Case[];
  className?: string;
}

export const CaseSwitch = (props: ICaseSwitchProps) => {
  const { data, className } = props;
  const { i18n } = useLanguage();
  const sliderRef = useRef<Slider | null>(null);
  const isShowSlider = data?.length && data.length >= 3;
  const settings: Settings = {
    slidesToShow: isShowSlider ? 3 : 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    variableWidth: true
  };

  const renderItem = (item: Case) => {
    return (
      <div>
        <div style={{ width: 320 }} className="card">
          <Link className="card-inner" to={`/case-detail/${item.id}`}>
            <img className="card-img" src={`${oss(item.coverMaterial, 320, 160)}`} alt="" />
            <div className="card-content">
              <img className="card-logo" src={oss(item.customerLogo, 100, 24, 'lfit')} alt="" />
              <div className="title line-clamp-2">{item.title}</div>
              <div className="item-detail">{i18n(fieldTranslation, '查看详情')}</div>
            </div>
          </Link>
        </div>
      </div>
    );
  };

  const sliderContent = (
    <div className={cn('case-switch-wrapper')}>
      <Slider ref={sliderRef} {...settings}>
        {map(data, (item, index) => {
          return <React.Fragment key={index}>{renderItem(item)}</React.Fragment>;
        })}
      </Slider>
      {data?.length && data.length > 3 ? (
        <div>
          <CircleButton className="case-prev" onClick={sliderRef.current?.slickPrev} type="prev" />
          <CircleButton className="case-next" onClick={sliderRef.current?.slickNext} type="next" />
        </div>
      ) : null}
    </div>
  );

  const normalContent = (
    <div className="case-switch-list">
      {map(data, (item, index) => {
        return <React.Fragment key={index}>{renderItem(item)}</React.Fragment>;
      })}
    </div>
  );

  return (
    <React.Fragment>
      <div className={cn(className, 'case-switch hidden-in-mobile')}>
        {isShowSlider ? sliderContent : normalContent}
      </div>
      <div className={cn(className, 'case-switch hidden-in-pc')}>{normalContent}</div>
    </React.Fragment>
  );
};

export default PlateDetailPage;
