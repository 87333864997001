import cn from 'classnames';
import React from 'react';
import AnchorList from 'components/Common/anchor';
import './index.scss';
import useAnchor from './useAnchor';
import usePlatform from 'hooks/usePlatform';

const NAV_WIDTH = 280;

interface IProps {
  data?: string
  className: string
  showAnchor?: boolean
  style?: any
}

export default function FullRichText({ data, className, showAnchor, style }: IProps) {
  const [anchorData, anchorList] = useAnchor(data || '');
  const isShowAnchorList = showAnchor && anchorList.length > 0;
  const { isMobile } = usePlatform();
  const AnchorWidth = isMobile ? 0 : NAV_WIDTH;
  return (
    <div className={cn('rich-text-wrapper', className)} style={style}>
      {isShowAnchorList ? (
        <div className="rich-text-nav hidden-in-mobile" style={{ width: AnchorWidth }}>
          <AnchorList
            itemClassName={(item) => cn({ 'rich-text-nav-strong': !item.level })}
            affix={true}
            items={anchorList as any}
          />
        </div>
      ) : null}
      <div className="comp-full-rich-text" style={isShowAnchorList ? { paddingLeft: AnchorWidth } : undefined}>
        <div className="full-rich-text-content" dangerouslySetInnerHTML={{ __html: anchorData as any ?? '' }} />
      </div>
    </div>
  );
}
