import { useCallback, useEffect, useRef } from 'react';

export interface LazyOptions {
  offset: number;
}

const SET_FLAG = '__TRUE__';

const useLazyImage = (options?: LazyOptions) => {
  const { offset = 0 } = options ?? {};
  const observerRef = useRef<IntersectionObserver | null>();
  const updateObserve = useCallback(() => {
    if (observerRef.current) {
      const list = document.querySelectorAll('img[data-src]');
      for (let i = 0; i < list.length; i++) {
        const element = list[i] as HTMLImageElement;
        if (element.dataset.isSetSrc !== SET_FLAG) {
          observerRef.current.observe(element);
        }
      }
    }
  }, []);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((item) => {
          if (!item.isIntersecting) return;
          const { target } = item;
          if (target.tagName === 'IMG') {
            const imgTarget = target as HTMLImageElement;
            if (imgTarget.dataset.src) {
              imgTarget.src = imgTarget.dataset.src;
              imgTarget.dataset.isSetSrc = SET_FLAG;
              observerRef.current!.unobserve(target);
            }
          }
        });
      },
      { rootMargin: `0px 0px ${offset}px 0px` }
    );
    updateObserve();
    return () => {
      observerRef.current?.disconnect();
    };
  }, [offset]);
  return { updateObserve };
};

export default useLazyImage;
