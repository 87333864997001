import React from 'react';
import './index.scss';

export default function Icon(props) {
  const { type, width, height, ...rest } = props
  return (
    <svg className="icon" aria-hidden="true" style={{ width, height }} {...rest}>
      <use xlinkHref={`#icon-${type}`} />
    </svg>
  )
}
