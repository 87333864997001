import React, { useState, useEffect, useContext, useRef } from 'react';
import classnames from 'classnames';
import Context from 'container';
import Single from './Single';
import Multiple from './Multiple';
import { isInViewPortOfOne } from 'scripts/utils';
import CarouselFooter from './footer';
import './index.scss';

export default function Banner(props) {
  const { covered, size, height, config, type, name, showAdvantage = false, ...rest } = props;
  const [paddingTop, stePaddingTop] = useState();
  const { dispatch } = useContext(Context);
  const ref = useRef();

  useEffect(() => {
    // 清除theme
    return () => {
      dispatch({ theme: undefined });
    };
  }, []);

  useEffect(() => {
    if (height) {
      if (covered) {
        stePaddingTop(height * 0.2857);
      } else {
        stePaddingTop(height * 0.2);
      }
    } else {
      stePaddingTop();
    }
  }, [covered, height]);

  function updateTheme(theme) {
    if (covered && isInViewPortOfOne(ref.current)) {
      dispatch({ theme });
    }
  }

  function renderSingle(callback) {
    let _config = config;
    if (config instanceof Array) {
      _config = config[0];
    }
    return (
      <Single
        type={type}
        covered={covered}
        height={height}
        paddingTop={paddingTop}
        setThemeCallback={callback}
        {..._config}
        {...rest}
      />
    );
  }

  return (
    <div ref={ref} className={classnames('comp-banner', size, { covered })} style={{ minHeight: height }}>
      {config instanceof Array && config.length > 1 ? (
        <Multiple
          type={type}
          covered={covered}
          height={height}
          paddingTop={paddingTop}
          config={config}
          setCurrentThemeCallback={updateTheme}
          {...rest}
        />
      ) : (
        renderSingle(updateTheme)
      )}
      {showAdvantage ? <CarouselFooter /> : null}
    </div>
  );
}
