import { useContext, useEffect, useRef, useState } from 'react';
import Context from 'container';
import MainTitle from 'components/Common/main-title';
import Slider, { Settings } from 'react-slick';
import { isValid, oss } from 'scripts/utils';
import map from 'lodash/map';
import './news.scss';
import CircleButton from 'components/Common/circle-button';
import dayjs from 'dayjs';
import { dateFormatter, enDateFormatter } from 'components/constants';
import { getColorByUrl } from 'scripts/color-extractor/index';
import { isDark } from 'scripts/color-extractor/utils';
import { CursorPagination } from 'components/Common/cursor-pagination';
import Pagination from 'components/Common/pagination';
import { Link } from 'react-router-dom';
import Divider from 'components/Common/divider';
import { getTitle } from 'components/services';
import { getNewsCarousel, getNewsPage } from './services';
import { InfiniteScroll } from 'antd-mobile'
import cn from 'classnames'

const PAGE_SIZE = 9;
const PAGE_COLUMN = 3;

export interface NewsData {
  id: string;
  coverMaterial: string;
  title: string;
  createTime: number;
  desc: string;
}

const NewItem = ({news, lang, hideDivider}:{hideDivider?: boolean; news: NewsData[], lang: string}) => {
  return (
    <div className="news-list-row">
      {news.map((n, ni) => (
        <Link to={`/article-detail/news/${n.id}`} key={n.id}>
          <div className="news-list-row-item">
            <img
              className="news-list-row-item-img"
              width={280}
              height={158}
              data-src={oss(n.coverMaterial, 280, 158)}
              alt={n.title}
            />
            <div>
              <div className="news-list-row-item-text">
                <div className="news-list-row-item-title line-clamp-2">{n.title}</div>
                <div className="news-list-row-item-content line-clamp-2 hidden-in-mobile">{n.desc}</div>
              </div>
              <div className="news-list-row-item-time">
                {dayjs(n?.createTime).format(lang === 'cn' ? dateFormatter : enDateFormatter)}
              </div>
            </div>
          </div>
        </Link>
      ))}
      {
        hideDivider ? null : <Divider className="row-line hidden-in-mobile" />
      }
    </div>
  )
}


const News = () => {
  const context = useContext(Context);
  const { currentSite = {} } = context;
  const [titleInfo, setTitleInfo] = useState({
    mainTitle: '',
    desc: ''
  });

  useEffect(() => {
    if (currentSite.id) {
      getTitle({
        type: 'NEWS',
        site: {
          id: currentSite.id
        }
      }).then((res) => {
        const { title, desc } = res;
        setTitleInfo({
          mainTitle: title,
          desc
        });
      });
    }
  }, [currentSite.id]);

  return (
    <div className="news-wrapper">
      <div className="news-content">
        <MainTitle className="news-title hidden-in-mobile" main={titleInfo.mainTitle} sub={titleInfo.desc} />
        <NewsCarousel />
        <NewsList />
      </div>
    </div>
  );
};

const NewsCarousel = () => {
  const sliderRef = useRef<Slider | null>(null);
  const [imagesData, setImagesData] = useState<string[]>([]);
  const [data, setData] = useState<NewsData[]>([]);
  const [theme, setTheme] = useState<'dark' | 'light' | undefined>(undefined);
  const context = useContext(Context);
  const { currentSite = {}, lang } = context;
  const [activeIndex, setActiveIndex] = useState(0);
  const isShowController = data.length > 1;
  const handleGoto = (index: number) => {
    sliderRef.current?.slickGoTo(index);
  };
  const handlePrev = () => {
    sliderRef.current?.slickPrev();
  };
  const handleNext = () => {
    sliderRef.current?.slickNext();
  };
  useEffect(() => {
    if (isValid(currentSite.id)) {
      getNewsCarousel(currentSite.id).then((res) => {
        setData(res);
        Promise.all(
          res.map((d: NewsData) => {
            return d.coverMaterial;
          })
        ).then((res) => {
          setImagesData(res as string[]);
        });
      });
    }
  }, [currentSite.id]);

  useEffect(() => {
    if (imagesData[activeIndex]) {
      getColorByUrl(imagesData[activeIndex], {
        clip: {
          offsetY: 300
        }
      }).then((c) => {
        setTheme(isDark(c) ? 'dark' : 'light');
      });
    }
  }, [activeIndex, imagesData]);

  const settings: Settings = {
    fade: true,
    autoplay: data.length > 1,
    autoplaySpeed: 8000,
    infinite: true,
    arrows: false,
    adaptiveHeight: false,
    beforeChange: (current, next) => setActiveIndex(next)
  };

  return (
    <div className={cn("news-carousel", {'news-carousel-mb': isShowController})}>
      <Slider ref={sliderRef} {...settings} className="news-carousel-slider">
        {map(data, (d, contentIndex) => {
          return (
            <div key={contentIndex}>
              <Link to={`/article-detail/news/${d.id}`}>
                <div
                  className="news-carousel-content img-background"
                  style={{ backgroundImage: `url(${oss(imagesData[contentIndex], undefined,  866)})` }}
                ></div>
              </Link>
            </div>
          );
        })}
      </Slider>
      <div className={cn({'news-carousel-desc-mb': isShowController}, "news-carousel-desc")}>
        <MainTitle
          size="lg"
          position="left"
          main={data[activeIndex]?.title}
          theme={theme}
          subStyle={{ height: 20 }}
          sub={dayjs(data[activeIndex]?.createTime).format(lang === 'cn' ? dateFormatter : enDateFormatter)}
        />
        {isShowController ? (
          <CursorPagination onItemClick={handleGoto} count={data.length} activeIndex={activeIndex} />
        ) : null}
      </div>
      {isShowController ? (
        <>
          <CircleButton className="circle-left hidden-in-mobile" onClick={handlePrev} type="prev" />
          <CircleButton className="circle-right hidden-in-mobile" onClick={handleNext} type="next" />
        </>
      ) : null}
    </div>
  );
};

export const divideData = <T extends any>(data: T[], colCount: number): T[][] => {
  if (!data) return [];
  const d = [...data];
  let result: T[][] = [];
  let index = 0;
  let n = 0;
  while (d.length) {
    if (n === 0) result.push([]);
    const item = d.shift()!;
    result[index].push(item);
    if (n === colCount - 1) {
      n = 0;
      index++;
    } else {
      n++;
    }
  }
  return result;
};

const NewsList = () => {
  const context = useContext(Context);
  const { currentSite = {}, lang, updateObserve } = context;
  const [pageNews, setPageNews] = useState<NewsData[][]>([]);
  const [pageInfo, setPageInfo] = useState<{
    pageSize: number;
    pageNo: number;
    total: number;
  }>({
    pageSize: PAGE_SIZE,
    pageNo: 1,
    total: 0
  });

  const [mobileData, setData] = useState<NewsData[][]>([])
  const [hasMore, setHasMore] = useState(true)
  const pageNoMobileRef = useRef(1)

  async function loadMore() {
    const { data, total } = await getNewsPage({
      pageNo: pageNoMobileRef.current,
      pageSize: pageInfo.pageSize,
      site: { id: currentSite.id }
    })
    pageNoMobileRef.current ++
    const finalData = [...mobileData, ...divideData<NewsData>(data, PAGE_COLUMN)]
    setData(finalData)
    setHasMore(finalData.flat().length < total)
    updateObserve();
  }

  const handlePageChange = (page: number, size: number) => {
    setPageInfo((info) => ({
      ...info,
      pageNo: page,
      pageSize: size
    }));
  };

  useEffect(() => {
    if (currentSite.id) {
      getNewsPage({
        pageNo: pageInfo.pageNo,
        pageSize: pageInfo.pageSize,
        site: { id: currentSite.id }
      }).then((res) => {
        const { data, total } = res;
        setPageNews(divideData<NewsData>(data, PAGE_COLUMN));
        setPageInfo((s) => ({
          ...s,
          total: total
        }));
        updateObserve();
      });
    }
  }, [currentSite.id, pageInfo.pageNo, pageInfo.pageSize]);


  return (
    <div className="news-list-wrapper">
      <div className='hidden-in-mobile'>
        {pageNews.map((news, i) => {
          return (
            <NewItem hideDivider={pageInfo.total < pageInfo.pageSize && pageNews.length === i + 1} news={news} key={i} lang={lang} />
          );
        })}
        <Pagination
          onChange={handlePageChange}
          className="news-pagination"
          pageSize={pageInfo.pageSize}
          total={pageInfo.total}
          current={pageInfo.pageNo}
        />
      </div>
      <div className="hidden-in-pc">
        <div>
          {mobileData.map((news, i) => {
            return (
              <NewItem lang={lang} news={news} key={i} />
            );
          })}
        </div>
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
      </div>
    </div>
  );
};

export default News;
