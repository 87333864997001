import axios from 'axios';
import { SearchParams } from '.';

export const getBanner = (id: string | number) => {
  return axios.get(`/api/internal/multimedia/resource/carousel/list/${id}`);
};

export const postList = (params: SearchParams) => {
  return axios.post(`/api/internal/multimedia/resource/list`, params);
};

export const getDetail = (id: string) => {
  return axios.get(`/api/internal/multimedia/resource/${id}`);
};

export const getTypes = () => {
  return axios.get(`/api/internal/multimedia/resource/type/list/1`);
};