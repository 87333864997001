import { divideData } from 'components/About/news';
import AnchorList from 'components/Common/anchor';
import Divider from 'components/Common/divider';
import { oss } from 'scripts/utils';
import './cases-list.scss';
import Context from 'container';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import VideoIcon from 'components/Video/video-icon';
import { createVideoModal } from 'components/Video/video';
import usePlatform from 'hooks/usePlatform';

export interface Case {
  coverMaterial: string;
  backgroundMaterial: string;
  customerLogo: string;
  customerName: string;
  desc: string;
  title: string;
  id: number;
  createdAt: number;
  videoMaterial?: {
    files: {
      url: string;
    }[];
  };
}

interface CasesData {
  industry: {
    id: number;
    name: string;
  };
  exampleBOS: Case[];
}

interface ICasesListProps {
  data: CasesData[];
}

const CasesList = (props: ICasesListProps) => {
  const { data } = props;
  const { lang, updateObserve } = useContext(Context);
  const [activeId, setActiveId] = useState<string | null>(null);

  const { isMobile } = usePlatform();
  const PAGE_COLUMN = isMobile ? 2 : 3;

  useEffect(() => {
    if (data) updateObserve();
  }, [data, updateObserve]);
  if (!data) return null;

  const anchorItems = data.map((d) => ({ id: `_${d.industry.id}`, title: d.industry.name }));

  return (
    <div className="cases-list-wrapper">
      <div className="affix-list hidden-in-mobile">
        <AnchorList affix={true} affixOffset={50} items={anchorItems} />
      </div>
      <div className="hidden-in-pc">
        <AnchorList contentType="tabs" affix={true} affixOffset={0} items={anchorItems} />
      </div>

      <div className="cases-list">
        {data.map((group) => {
          const { industry, exampleBOS } = group;
          return (
            <div className="cases-list-group" key={industry.id}>
              <div id={`_${industry.id}`} className="cases-list-group-title">
                {industry.name}
              </div>
              <div className="cases-list-group-content">
                {divideData<Case>(exampleBOS, PAGE_COLUMN).map((cases, casesIndex) => {
                  return (
                    <div key={casesIndex}>
                      <div className="cases-list-group-content-row">
                        {cases.map((c, i) => {
                          const video = c.videoMaterial?.files[0]?.url;
                          return (
                            <Link
                              onMouseLeave={() => video && setActiveId(null)}
                              onMouseEnter={() => video && setActiveId(`${industry.id}_${c.id}`)}
                              to={`/case-detail/${c.id}`}
                              className="cases-list-group-content-item"
                              key={i}
                            >
                              <div className="item-img-wrapper">
                                {video ? (
                                  <VideoIcon
                                    active={`${industry.id}_${c.id}` === activeId}
                                    className="item-play"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      ev.stopPropagation();
                                      createVideoModal(video);
                                    }}
                                  >
                                    {lang === 'cn' ? '立即播放' : 'Play'}
                                  </VideoIcon>
                                ) : null}
                                <img className="item-img" data-src={oss(c.coverMaterial, 280, 158)} alt={c.title} />
                              </div>

                              <img
                                className="item-logo"
                                // src={oss(c.customerLogo, 100, 24, 'lfit')}
                                data-src={oss(c.customerLogo, 200, 48, 'lfit')}
                                alt={c.customerName}
                                title={c.customerName}
                              />
                              <div className="item-title">{c.title}</div>
                              <div className="item-detail line-clamp-2">{lang === 'cn' ? '查看详情' : 'Detail'}</div>
                            </Link>
                          );
                        })}
                      </div>
                      <Divider className="row-divider" />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CasesList;
