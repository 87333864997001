/*
 * @LastEditors: Necfol
 * @Date: 2024-03-28 13:54:14
 * @LastEditTime: 2024-04-19 14:42:04
 * @FilePath: /emp-official-ui/src/pages/Go1688/supplier.tsx
 */
import React, { useState, useEffect, useContext } from 'react';
import Helmet from 'components/Helmet';
import { createContactUsModal } from 'components/ContactUs';
import './supplier.scss';


function SupplierPage() {
  return (
    <React.Fragment>
      <Helmet
        title="杭州端点网络科技有限公司-新商业软件提供商-数字化转型-全链路解决方案"
        description=''
      />
      <div key="supplier-page-content">
        <div
          className='supplier-banner'
        >
          <img className='supplier-banner-background' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/35e65b0c-faf3-42c3-8025-7e6b4e304d29.png" alt="" draggable="false" />
          <div className='supplier-banner-inner'>
            <div className='supplier-banner-inner-title'>云商机</div>
            <div className='supplier-banner-inner-desc'>为企业提供一站式智能拓客解决方案</div>
            <button className='supplier-banner-inner-btn' onClick={() => createContactUsModal()}>立即咨询</button>
          </div>

        </div>
        <div
          className='supplier-settle'
        >
          <img className='supplier-settle-background' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/fad02694-c374-489e-bea7-6a47c3297c62.png" alt="" draggable="false" />
          <div className="supplier-settle-inner">
            <img className='supplier-settle-content' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/c19e064e-830d-4908-896c-48fcb3d88835.png" alt="" draggable="false" />
            <a className='supplier-settle-protocol' href="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/4e3d5672-5b8b-4793-9d0d-6e2141a9c864.pdf">下载协议</a>
            <a className='supplier-settle-pay' href="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/db27cd82-3d37-40d9-948e-6f5c37413841.pdf" target='_blank' rel="noreferrer" >查看收款信息</a>
          </div>

        </div>
        <div className="supplier-settle-wrap">
          <div className="supplier-settle-wrap-inner">
            <img className='supplier-settle-detail' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/7cc1548c-2d48-43a2-a825-4652bb631e81.png" alt="" draggable="false" />
            <img className='supplier-settle-detail' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/fe0c5fdb-13f4-419d-ace4-4cbd2dc8f805.png" alt="" draggable="false" />
            <img className='supplier-settle-data' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/10fc149d-36f0-493e-b26f-4d477c130fb0.png" alt="" draggable="false" />
            <img className='supplier-settle-detail' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/f01969ce-07d6-41ae-a597-6022e67f399f.png" alt="" draggable="false" />
            <img className='supplier-settle-detail' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/95e06faa-c0bc-440a-84bc-30946b1f2681.png" alt="" draggable="false" />
          </div>
        </div>

      </div>
    </React.Fragment>
  );
}

export default SupplierPage;
