import './index.scss';
import cn from 'classnames';
import Arrow from 'components/IconWrapper/arrow';
import { IJumpBO } from 'components/types';
import { isValid } from 'scripts/utils';
import PlayOne from 'components/IconWrapper/play-one';

type ButtonType = 'primary' | 'default' | 'white' | 'video';
interface IButtonProps {
  fill?: string;
  jumpConfig?: IJumpBO;
  type?: ButtonType;
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
  disabled?: boolean;
}

const getClassNameByType = (type: ButtonType) => {
  switch (type) {
    case 'primary':
      return 'buttonPrimary';
    case 'white':
      return 'buttonWhite';
    default:
      return 'buttonDefault';
  }
};

const Button: React.FC<Partial<Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'prefix'>> & IButtonProps> = (
  props
) => {
  const {
    children,
    className,
    fill = '#0366DD',
    style,
    jumpConfig,
    type = 'primary',
    suffix,
    prefix,
    disabled,
    ...restProps
  } = props;
  const isJump = isValid(jumpConfig);
  const { buttonTitle, buttonLink } = jumpConfig ?? {};

  const getStyleByType = (type: ButtonType) => {
    switch (type) {
      case 'primary':
        return {
          backgroundColor: fill,
        };
      case 'default':
        return {
          backgroundColor: 'transparent',
          color: fill,
          border: `1px solid ${fill}`
        };
      default:
        return {};
    }
  };
  if (type === 'video') {
    return (
      <div className={cn('button-video-icon', className)} {...(restProps as any)}>
        <div className="button-video-circle flex-h-center">
          <PlayOne strokeLinejoin={'round'} fill={'#0366DD'} />
        </div>
        <span className="button-video-text">{children}</span>
      </div>
    );
  }
  if (buttonTitle == null && children == null) {
    return null;
  }

  return (
    <a
      className={cn(className, getClassNameByType(type), { disabled })}
      style={Object.assign(getStyleByType(type), style)}
      href={isJump ? buttonLink : undefined}
      {...restProps}
    >
      {prefix ? <span style={{ marginRight: 4 }}>{prefix}</span> : null}
      {isJump ? buttonTitle : children}
      {suffix && <Arrow className="icon" />}
    </a>
  );
};

export default Button;
