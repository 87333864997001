import { IconWrapper, ISvgIconProps } from '.';

export default IconWrapper('guest', true, (props: ISvgIconProps) => (
  <svg width={props.size} height={props.size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <polygon points="0,0 20,0 20,20 0,20 " />
    <path
      d="M3.3,18.3c0-3.7,3-6.7,6.7-6.7s6.7,3,6.7,6.7H3.3L3.3,18.3z M10.8,13.4v3.3h3.9  C14.1,15,12.6,13.7,10.8,13.4z M9.2,16.7v-3.3c-1.8,0.3-3.3,1.6-3.9,3.3H9.2L9.2,16.7z M10,10.8c-2.8,0-5-2.2-5-5s2.2-5,5-5  s5,2.2,5,5S12.8,10.8,10,10.8z M10,9.2c1.8,0,3.3-1.5,3.3-3.3S11.8,2.5,10,2.5S6.7,4,6.7,5.8S8.2,9.2,10,9.2z"
      stroke={props.colors[0]}
      fill={props.colors[0]}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
      strokeLinejoin={props.strokeLinejoin}
    />
    <path
      d="M24 12L36 24L24 36"
      fill={props.colors[0]}
      stroke={props.colors[0]}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
      strokeLinejoin={props.strokeLinejoin}
    />
  </svg>
));
