import React, { useState, useEffect, useContext } from 'react';
import Context from 'container';
import usePlatform from 'hooks/usePlatform';
import useLanguage from 'hooks/useLanguage';
import useBannerConfig from 'hooks/useBannerConfig';
import Helmet from 'components/Helmet';
import Banner from 'components/Banner';
import VideoPlayer from 'components/VideoPlayer';
import { BANNER_TYPE, buttonThemes, linkIcons } from 'components/constants';
import { getBannerOperationExtraAttr } from 'scripts/utils';
import { getArticleList } from 'services';
import CasesList from './cases-list';
import { getBrands } from 'components/Menu/services';

function CasesPage() {
  const { isPc } = usePlatform() || {};
  const { i18n } = useLanguage();
  const context = useContext(Context);
  const { currentSite = {} } = context;
  const banner = useBannerConfig();
  const [videosInfo, setVideosInfo] = useState({});
  const [visible, setVisible] = useState(false);
  const [casesData, setCasesData] = useState([]);

  let files = [];
  const { pcResource, mobileResource } = banner;
  if (pcResource || mobileResource) {
    if (isPc) {
      files = pcResource?.files || [];
    } else {
      files = mobileResource?.files || [];
    }
  }

  useEffect(() => {
    (async () => {
      if (currentSite.id) {
        const data = await getBrands({
          site: {
            id: currentSite.id
          }
        });
        setCasesData(data);
      }
    })();
  }, [currentSite.id]);

  function videoCallback(info) {
    setVideosInfo(info);
    toggleVideo();
    window.history.pushState({ videoPlayerVisible: !visible }, '', window.location.href);
  }

  function toggleVideo() {
    setVisible(!visible);
  }
  return (
    <React.Fragment>
      <Helmet title="数字化转型案例-端点-Terminus-全链路解决方案" description={banner.legend} />
      <Banner
        type={BANNER_TYPE.DETAIL_HEADER}
        config={{
          src: '/static/images/join-us.png',
          mobileSrc: '/static/images/join-us.png',
          title: banner.title || i18n('TOPNAVIGATION', '成功案例'),
          legend: banner.legend,
          operation: [
            {
              ...buttonThemes,
              // icon: 'label',
              text: banner?.jumpBO?.buttonTitle,
              icon: linkIcons[banner?.jumpBO?.jumpContentType],
              extraAttr: getBannerOperationExtraAttr(banner, () =>
                videoCallback({
                  title: banner.title,
                  desc: banner.legend,
                  videos: banner?.videoRelation?.videos
                })
              )
            }
          ]
        }}
      />
      <CasesList data={casesData} />
      {visible ? <VideoPlayer videosInfo={videosInfo} visible={visible} onCancel={toggleVideo} /> : null}
    </React.Fragment>
  );
}

CasesPage.getInitialProps = async ({ context, params }) => {
  const { currentSite = {} } = context;

  return getArticleList({
    ...params,
    type: 'example',
    site: currentSite.id
  });
};

export default CasesPage;
