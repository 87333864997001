import { Resource } from 'components/types';
import { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { getActivityDetail } from './service';
import './detail.scss';
import { oss } from 'scripts/utils';
import useLanguage from 'hooks/useLanguage';
import { fieldTranslation } from 'components/constants';
import Divider from 'components/Common/divider';
import map from 'lodash/map';
import size from 'lodash/size';
import dayjs from 'dayjs';
import Affix from 'components/Affix';
import MainTitle from 'components/Common/main-title';
import cn from 'classnames';
import Guest from 'components/IconWrapper/guest';
import Time from 'components/IconWrapper/time';
import Intro from 'components/IconWrapper/intro';
import Review from 'components/IconWrapper/review';
import VideoIcon from 'components/Video/video-icon';
import Helmet from 'components/Helmet';
import Button from 'components/Button';
import FullRichText from 'components/FullRichText';
import React from 'react';
import classNames from 'classnames';
import { createContactUsModal } from 'components/ContactUs';
import { createVideoModal } from 'components/Video/video';
import LiveButton, { LiveJump } from './live-button'

interface IActivityDetail {
  id: number,
  activityIntroduction: string;
  activityName: string;
  enrollButtonTitle?: string
  liveAddress?: string
  activityTimeStr: string;
  videoMaterial?: Resource;
  coverMaterial: string;
  endDate: number,
  startDate: number,
  enrollStatus: boolean,
  enrollTypeDict: string,
  enrollDetail: any
  thirdLink: string,
  activityShowType: 'STANDARD' | 'CUSTOM',
  activityDescribe: string,
  activityAgendas: {
    agendaStr: string;
    title: string;
    activitySpeaker: {
      name: string;
      position: string;
      avatar: string;
    };
  }[];
  activitySpeakers: {
    id: number
    name: string;
    position: string;
    avatar: string;
  }[];
  activityReviews: {
    id: number
    title: string;
    description: string;
    coverMaterial: string
    playType: 'UPLOAD' | 'URL';
    jumpBO: any;
  }[];
}

const events = ['resize', 'scroll', 'touchstart', 'touchmove', 'touchend'];
enum buttonType {
  ALREADY_STARTED = 'ALREADY_STARTED',
  ALREADY_OVER = 'ALREADY_OVER'
}
const buttonMap = {
  ALREADY_STARTED: { name: '线下参会', disabled: false },
  ALREADY_OVER: { name: '已经结束', disabled: true }
};

const ActivityDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [detail, setDetail] = useState<IActivityDetail | null>(null);
  const [activeId, setActiveId] = useState<string | null>();
  const { i18n } = useLanguage();

  useEffect(() => {
    if (id) {
      getActivityDetail(id).then((res) => {
        setDetail(res as unknown as IActivityDetail);
      });
    }
  }, [id]);

  useEffect(() => {
    const triggerFn = () => {
      const selector = map(['introduce', 'agenda', 'guest'], (item) => `#${item}`).join(',');
      if (!selector) return;
      const allTitle = document.body?.querySelectorAll(selector);
      for (let i = 0; i < allTitle.length; i++) {
        const title = allTitle[i] as HTMLHeadElement;
        const rectTop = title.getClientRects()[0]?.top;
        if (rectTop >= 0) {
          setActiveId(title.id);
          break;
        }
      }
    };

    events.forEach((ev) => {
      window.addEventListener(ev, triggerFn);
    });
    triggerFn();

    return () => {
      events.forEach((ev) => {
        window.removeEventListener(ev, triggerFn);
      });
    };
  }, []);

  const activitySpeakers = detail?.activitySpeakers;
  const activityReviews = detail?.activityReviews

  const button = useMemo(() => {
    if (dayjs().isAfter(detail?.endDate, 'day')) {
      return buttonType.ALREADY_OVER;
    }
    return buttonType.ALREADY_STARTED;
  }, [detail]);

  const buttonInfo = buttonMap[button];
  if (detail?.enrollButtonTitle) {
    buttonInfo.name = detail?.enrollButtonTitle || buttonInfo.name
  }

  const onVideoClick = (review: any) => {
    const afterSuccess = () => {
      if (review.playType === 'URL') {
        window.location.href = review.jumpBO?.buttonLink
      } else if (review.playType === "UPLOAD") {
        createVideoModal(review.jumpBO?.attachmentUrl)
      }
    }
    if (review.jumpBO?.jumpContentType === 'FORM') {
      createContactUsModal({
        formId: review.jumpBO?.formBO.id,
        afterSuccess,
      })
    } else {
      afterSuccess()
    }
  }

  return (
    <React.Fragment>
      <Helmet title="端点-Terminus-数字化转型-商业软件" description={detail?.activityName} />
      <div className="activity-detail">
        <div className="detail-title">{detail?.activityName}</div>
        <div className="flex-h-center">
          <span className="detail-time">{detail?.activityTimeStr}</span>
        </div>
        {detail?.activityShowType === 'STANDARD' ? <>
          <div className="detail-banner">
            <div className={classNames("banner-main", { pl100: (detail?.activityAgendas.length || 0) === 0 })}>
              {detail?.liveAddress && <><LiveButton {...detail}/> <LiveJump {...detail} /></>}
              {detail?.videoMaterial?.files[0]?.url ? (
                <video src={detail.videoMaterial.files[0].url} controls />
              ) : (
                <img src={oss(detail?.coverMaterial, 800, 450)} alt="" />
              )}
            </div>
            {detail?.activityAgendas.length > 0 && <div className="banner-side hidden-in-mobile">
              <div className="side-intro">
                <img src={oss(detail?.coverMaterial, 168, 95)} alt="" />
                <div className="side-tip">{i18n(fieldTranslation, '活动议程')}</div>
                <Divider />
              </div>
              <div className="time-line">
                {map(detail?.activityAgendas, (item, index) => {
                  return (
                    <div key={index} className="time-line-item">
                      <div className="time-line-item-header">
                        <div className="circle"></div>
                        <div className="time">{item.agendaStr}</div>
                      </div>
                      <div className="dash-block line-clamp-2">{item.title}</div>
                    </div>
                  );
                })}
              </div>
            </div>}
          </div>
          <Affix>
            <div className="detail-tabs flex-h-center">
              <a
                className={cn({ active: activeId === 'introduce' })}
                href="#introduce"
                onClick={() => setActiveId('introduce')}
              >
                <Intro className="tabs-icon" strokeWidth={1} />
                {i18n(fieldTranslation, '活动简介')}
              </a>
              {detail?.activityAgendas.length > 0 && <a
                className={cn({ active: activeId === 'agenda' })}
                href="#agenda"
                onClick={() => setActiveId('agenda')}
              >
                <Time className="tabs-icon" strokeWidth={1} />
                {i18n(fieldTranslation, '活动议程')}
              </a>}
              {size(activitySpeakers) > 0 && <a
                className={cn({ active: activeId === 'guest' })}
                href="#guest"
                onClick={() => setActiveId('guest')}
              >
                <Guest className="tabs-icon" strokeWidth={1} /> {i18n(fieldTranslation, '活动嘉宾')}
              </a>}
              {size(activityReviews) > 0 && <a
                className={cn({ active: activeId === 'review' })}
                href="#review"
                onClick={() => setActiveId('review')}
              >
                <Review className="tabs-icon" strokeWidth={1} /> {i18n(fieldTranslation, '精彩回顾')}
              </a>}
            </div>
          </Affix>
          <div className="detail-intro">
            <div id="introduce" className="affix-block"></div>
            <MainTitle className="detail-intro-title" main={i18n(fieldTranslation, '活动简介')} size="lg" />
            <div className="detail-intro-content">{detail?.activityIntroduction}</div>
          </div>
          <div className="detail-agenda">
            <div id="agenda" className="affix-block"></div>
            <MainTitle className="detail-agenda-title" main={i18n(fieldTranslation, '活动议程')} size="lg" />
            <div className="detail-agenda-content">
              {map(detail?.activityAgendas, (item, index) => {
                return (
                  <div key={index} className="time-line-item">
                    <div className="time-line-item-header">
                      <div className="time">{item.agendaStr}</div>
                      <div className="dash-content">
                        <div className="circle"></div>
                        <div className="dash"></div>
                      </div>
                    </div>
                    <div className="time-line-item-content">
                      <div className="content-title line-clamp-1">{item.title}</div>
                      <div className="content-intro">
                        <img src={oss(item.activitySpeaker.avatar, 20, 20)} alt="" />
                        <span className="intro">{item.activitySpeaker.name}</span>
                        <span className="position">{item.activitySpeaker.position}</span>
                      </div>
                      {detail?.activityAgendas.length && index === detail?.activityAgendas.length - 1 ? null : (
                        <Divider className="divider" />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </> : <div className='content-detail'>
          <FullRichText className="rich-text" data={detail?.activityDescribe} />
        </div>}
        
        {size(activitySpeakers) > 0 && <div className="detail-guest">
          <div id="guest" className="affix-block"></div>
          <MainTitle className="detail-guest-title" main={i18n(fieldTranslation, '活动嘉宾')} size="lg" />
          <div
            className={classNames(
              'guest-wrapper',
              {
                'grid-col-1': activitySpeakers && activitySpeakers.length === 1
              },
              {
                'grid-col-2': activitySpeakers && activitySpeakers.length === 2
              },
              {
                'grid-col-3': activitySpeakers && activitySpeakers.length === 3
              }
            )}
          >
            {map(activitySpeakers, (speaker) => {
              return (
                <div className="guest-item" key={speaker.id}>
                  <img src={oss(speaker.avatar, 96, 96)} alt="" />
                  <div className="name">{speaker.name}</div>
                  <div className="position">{speaker.position}</div>
                </div>
              );
            })}
          </div>
        </div>}
        {size(activityReviews) > 0 && <div className="detail-guest">
          <div id="guest" className="affix-block"></div>
          <MainTitle className="detail-guest-title" main={i18n(fieldTranslation, '精彩回顾')} size="lg" />
          <div className={classNames("review-list-row", {smaller: size(activityReviews) < 2} )}>
            {activityReviews?.map((n) => (
              <div className={classNames("review-list-row-item", {
                bigger: activityReviews.length < 3,
                'mobile-bigger':  activityReviews.length < 2,
              })} key={n.id}>
                <div  className="review-list-row-item-img-wrap">
                  <img
                    className="review-list-row-item-img"
                    src={oss(n.coverMaterial, 317, 158)}
                    alt={n.title}
                  />
                  {n.jumpBO?.buttonTitle && <div className='review-list-row-item-button' onClick={() => onVideoClick(n)}><VideoIcon>{n.jumpBO?.buttonTitle}</VideoIcon></div>}
                </div>
                <div>
                  <div className="review-list-row-item-text">
                    <div className="review-list-row-item-title line-clamp-2">{n.title}</div>
                    <div className="review-list-row-item-content line-clamp-2">{n.description}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>}
        {detail?.enrollStatus && (
            <div className="detail-footer">
              <Button
                type='primary'
                disabled={buttonInfo.disabled}
                onClick={() => 
                  button === buttonType.ALREADY_STARTED &&
                  detail?.enrollTypeDict === 'FORM' &&
                  createContactUsModal({ formId: detail?.enrollDetail?.id })
                }
                jumpConfig={{ 
                  buttonTitle: buttonInfo.name,
                  buttonLink: button === buttonType.ALREADY_STARTED
                  ? detail?.enrollTypeDict === 'FORM'
                    ? '#!'
                    : detail?.thirdLink
                  : '#!'
                }}
              />
            </div>
          )}
      </div>
    </React.Fragment>
  );
};

export default ActivityDetail;
