import Button from 'components/Button';
import { IJumpBO } from 'components/types';
import dayjs from 'dayjs';
import { useContext } from 'react';
import './index.scss';
import Context from 'container';
import { dateFormatter, enDateFormatter } from 'components/constants';
import { Link } from 'react-router-dom';
import { oss } from 'scripts/utils';
import MainTitle from 'components/Common/main-title';
import usePlatform from 'hooks/usePlatform';

interface INewsItem {
  title: string;
  content: string;
  coverMaterial: string;
  desc: string;
  createTime: number;
  createAt: number;
  id: number;
}

interface INewsProps {
  config?: {
    title: string;
    desc: string;
    jumpBO: IJumpBO;
    newsList: INewsItem[];
  };
}

const News: React.FC<INewsProps> = (props) => {
  const { config } = props;
  const { title, desc, jumpBO, newsList = [] } = config ?? {};
  const { lang } = useContext(Context);
  const { isMobile } = usePlatform();
  return (
    <div className="news">
      <div className="news-container">
        <div className="title">
          <MainTitle main={title ?? ''} sub={desc} />
        </div>
        <div className="content-wrap">
          <div className="content">
            {newsList.map((d, i) => {
              return (
                <div key={i} className="card">
                  <Link to={`/article-detail/news/${d.id}`}>
                    <div
                      className="pic"
                      style={{
                        backgroundImage: `url(${oss(d.coverMaterial, 384, 188)})`
                      }}
                    ></div>
                    <div className="title-box">
                      <div className="title">{d.title}</div>
                      <div className="sub-title">{d.desc}</div>
                    </div>
                    <div className="time">
                      {dayjs(d.createTime).format(lang === 'cn' ? dateFormatter : enDateFormatter)}
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>

        <div className="button">
          <Button
            style={isMobile ? { border: 'none' } : undefined}
            type="default"
            fill={isMobile ? '#0366DD' : '#01000e80'}
            jumpConfig={jumpBO}
          />
        </div>
      </div>
    </div>
  );
};

export default News;
