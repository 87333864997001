import React, { useState, useEffect, useRef, useContext } from 'react';
import classnames from 'classnames';
import Slider from 'react-slick';
import Context from 'container';
import usePlatform from 'hooks/usePlatform';
import Single from './Single';

export default function Multiple({ covered, height, paddingTop, config, setCurrentThemeCallback }) {
  const [ing, setIng] = useState(false);
  const [themes, setThemes] = useState([]);
  const { isPc } = usePlatform() || {};
  const { hasMask } = useContext(Context);
  const ref = useRef();
  const slider = useRef();

  useEffect(() => {
    if (hasMask) {
      slider.current.slickPause();
    } else {
      slider.current.slickPlay();
    }
  }, [hasMask]);

  useEffect(() => {
    setCurrentThemeCallback(themes[0]);
  }, [themes[0]]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    swipe: !isPc,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (prevIndex, index) => {
      setIng(true);

      setCurrentThemeCallback(themes[index]);
    },
    afterChange: () => {
      // getContentHeight();
      setIng(false);
    },
    appendDots: (dots) => (
      <div>
        <div className="comp-root">
          <ul className={classnames({ ing })}>{dots}</ul>
        </div>
      </div>
    )
  };

  return (
    <div ref={ref}>
      <Slider {...settings} ref={slider}>
        {config.map((conf, index) => (
          <Single
            key={`banner-${index}`}
            covered={covered}
            height={height}
            paddingTop={paddingTop}
            setThemeCallback={(theme) => {
              themes[index] = theme;
              setThemes([...themes]);
            }}
            {...conf}
          />
        ))}
      </Slider>
    </div>
  );
}
