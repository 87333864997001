import MainTitle from 'components/Common/main-title';
import Helmet from 'components/Helmet';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Context from 'container';
import './index.scss';
import { postPeriodicalList } from './setvice';
import { getTitle } from 'components/services';
import { divideData } from 'components/About/news';
import map from 'lodash/map';
import { oss } from 'scripts/utils';
import Divider from 'components/Common/divider';
import Pagination from 'components/Common/pagination';
import { Link } from 'react-router-dom';
import { InfiniteScroll } from 'antd-mobile'

interface PeriodicalItem {
  id: string;
  name: string;
  picture: string;
  introduction: string;
}

const PAGE_COLUMN = 2;

export const Periodical = () => {
  const { currentSite } = useContext(Context);
  const [pageInfo, setPageInfo] = useState({
    pageNo: 1,
    pageSize: 10,
    total: 0
  });

  const [headerInfo, setHeaderInfo] = useState({
    title: '',
    desc: ''
  });

  const [data, setData] = useState<PeriodicalItem[][]>([]);

  const [mobileData, setMobileData] = useState<PeriodicalItem[][]>([])
  const [hasMore, setHasMore] = useState(true)
  const pageNoMobileRef = useRef(1)

  async function loadMore() {
    if (!currentSite.id) return
    const res = await postPeriodicalList({
      pageNo: pageNoMobileRef.current,
      pageSize: 1,
      site: { id: currentSite.id }
    })
    const { data, total } = res as unknown as { total: number; data: PeriodicalItem[] }
    pageNoMobileRef.current ++
    const finalData = [...mobileData, ...divideData<PeriodicalItem>(data, PAGE_COLUMN)]
    setMobileData(finalData)
    setHasMore(finalData.flat().length < total)
  }

  const handlePageChange = (page: number, size: number) => {
    setPageInfo((info) => ({
      ...info,
      pageNo: page,
      pageSize: size
    }));
  };

  useEffect(() => {
    if (currentSite.id) {
      postPeriodicalList({
        site: {
          id: currentSite.id
        },
        pageNo: pageInfo.pageNo,
        pageSize: pageInfo.pageSize
      }).then((res) => {
        const { total, data } = res as unknown as { total: number; data: PeriodicalItem[] };
        setPageInfo((s) => ({ ...s, total }));
        setData(divideData<PeriodicalItem>(data, PAGE_COLUMN));
      });
    }
  }, [currentSite.id, pageInfo.pageNo, pageInfo.pageSize]);

  useEffect(() => {
    if (currentSite.id) {
      getTitle({
        site: {
          id: currentSite.id
        },
        type: 'Corporate publication'
      }).then((res) => {
        setHeaderInfo({
          title: res.title,
          desc: res.desc
        });
      });
    }
  }, [currentSite.id]);

  const PeriodicalItem = ({items, hideDivider}:{hideDivider?:boolean, items: PeriodicalItem[]}) => {
    return (
      <div>
        <div className="periodical-list-row">
          {map(items, (item, i) => {
            return (
              <Link to={`/periodical/${item.id}`} className="periodical-list-row-item" key={i}>
                <img src={oss(item.picture, 160, 192)} alt="" />
                <div className="content">
                  <div className="title">{item.name}</div>
                  <div className="intro line-clamp-4">{item.introduction}</div>
                </div>
              </Link>
            );
          })}
        </div>
        {
          hideDivider ? <div className='divider' /> : <Divider className="divider hidden-in-mobile" />
        }
    </div>
    )
  }

  return (
    <React.Fragment>
      <Helmet title="端点-Terminus-数字化转型-商业软件" description={headerInfo.desc} />
      <div className="periodical-wrapper">
        <MainTitle
          className="periodical-title hidden-in-mobile"
          main={headerInfo.title}
          sub={headerInfo.desc}
          size={'xl'}
        />
        <div className="periodical-list hidden-in-mobile">
          {map(data, (items, idx) => {
            return (<PeriodicalItem hideDivider={(idx + 1) === data.length && pageInfo.pageSize >= pageInfo.total} items={items} key={idx} />
            );
          })}
          <Pagination
            onChange={handlePageChange}
            current={pageInfo.pageNo}
            pageSize={pageInfo.pageSize}
            total={pageInfo.total}
            hideOnSinglePage={true}
          />
        </div>
        <div className="hidden-in-pc periodical-list">
          <div>
            {map(mobileData, (items, idx) => {
                return (<PeriodicalItem items={items} key={idx} />
                );
              })}
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
          </div>
      </div>
      </div>
    </React.Fragment>
  );
};
