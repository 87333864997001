import { useContext, useEffect, useState } from 'react';
import Context from 'container';
import './footer.scss';
import { getAdvantage } from 'services';
import { isValid } from 'scripts/utils';

interface FooterItem {
  id: number;
  title: string;
  desc: string;
  logo: string;
}

const CarouselFooter = () => {
  const context = useContext(Context);
  const { currentSite = {} } = context;
  const [list, setList] = useState<FooterItem[]>([]);

  useEffect(() => {
    const init = async () => {
      const data = (await getAdvantage({ id: currentSite.id })) as unknown as FooterItem[];
      setList(data);
    };
    if (isValid(currentSite.id)) {
      init();
    }
  }, [currentSite.id]);

  return (
    <div className="banner-footer">
      <div className="filter-blur hidden-in-mobile"></div>
      {list.map((d, i) => {
        const { id, logo, title, desc } = d;
        return (
          <div key={id} className="item">
            <div className="title">
              <img width={24} src={logo} alt="" />
              <div className="text">{title}</div>
            </div>
            <div className="content">{desc}</div>
          </div>
        );
      })}
    </div>
  );
};
export default CarouselFooter;
