import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon';
import './index.scss';

export default function Modal({ children, visible, onClose, title = null  }) {
  if(!visible) return null;

  return (
    <div className={classNames("modal", !visible && "modal-hide")}>
      <div className="modal-trans" onClick={onClose} />
      <div className="modal-cont">
        <div className="modal-top">
          {title && <span className="modal-top-title">{ title }</span>}
          <h3 className="modal-top-close" onClick={onClose}>
            <Icon type="close" width="4.25em"  />
          </h3>
        </div>
        <div className="modal-content">
          { children }
        </div>
      </div>
    </div>

  )
}
