import axios from 'axios';
import { SearchParams } from '.';

export const getActivityBanner = (id: string | number) => {
  return axios.get(`/api/internal/fixed/activity/list/${id}`);
};

export const getActivityPlace = (id: string | number) => {
  return axios.get(`/api/internal/activity/place/list/${id}`);
};

export const postActivityList = (params: SearchParams) => {
  return axios.post(`/api/internal/activity/list`, params);
};

export const getActivityDetail = (id: string) => {
  return axios.get(`/api/internal/activity/${id}`);
};
