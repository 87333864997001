import { IconWrapper, ISvgIconProps } from '.';

export default IconWrapper('time', true, (props: ISvgIconProps) => (
  <svg width={props.size} height={props.size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <polygon points="0,0 20,0 20,20 0,20 " />
    <path
      stroke={props.colors[0]}
      fill={props.colors[0]}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
      strokeLinejoin={props.strokeLinejoin}
      d="M10,18.3c-4.6,0-8.3-3.7-8.3-8.3S5.4,1.7,10,1.7s8.3,3.7,8.3,8.3S14.6,18.3,10,18.3z M10,16.7  c3.7,0,6.7-3,6.7-6.7s-3-6.7-6.7-6.7s-6.7,3-6.7,6.7S6.3,16.7,10,16.7z M10.8,10h3.3v1.7h-5V5.8h1.7V10z"
    />
  </svg>
));
