export const earthRoutes = [
  [
    [120.434453, 30.229503],
    [113.810664, 22.639258]
  ],
  [
    [120.434453, 30.229503],
    [121.577961, 25.049214]
  ],
  [
    [120.434453, 30.229503],
    [118.127739, 24.544036]
  ],
  [
    [120.434453, 30.229503],
    [116.584556, 40.080111]
  ],
  [
    [120.434453, 30.229503],
    [121.805214, 31.143378]
  ],
  [
    [120.434453, 30.229503],
    [113.298786, 23.392436]
  ],
  [
    [120.434453, 30.229503],
    [115.9, 28.865]
  ],
  [
    [120.434453, 30.229503],
    [111.824103, 40.851422]
  ],
  [
    [120.434453, 30.229503],
    [120.374436, 36.266108]
  ],
  [
    [120.434453, 30.229503],
    [103.994433, 1.350189]
  ],
  [
    [120.434453, 30.229503],
    [77.103088, 28.5665]
  ],
  [
    [120.434453, 30.229503],
    [-91.799017, 18.653739]
  ],
  [
    [120.434453, 30.229503],
    [29.018833, 40.989305]
  ],
  [
    [120.434453, 30.229503],
    [-73.778925, 40.639751]
  ],
  [
    [120.434453, 30.229503],
    [0.235, 51.885]
  ],
  [
    [120.434453, 30.229503],
    [149.195, -35.306944]
  ]
];
