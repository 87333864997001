import axios from 'axios';

export function getSites() {
  return axios.get('/api/internal/site/list');
}

export function getSiteInfoById(id) {
  return axios.get(`/api/internal/site/${id}`);
}

export function getHomePageData(id) {
  return axios.post('/api/internal/homepage', { id });
}

export function getOverviewPageData(id) {
  return axios.post('/api/internal/topheader', { id });
}

export function getProductPageData(id) {
  return axios.get(`/api/internal/product/${id}`);
}

export function getPlateDetailData(id) {
  return axios.get(`/api/internal/product/child/${id}`);
}

export function getSolutionPageData(id) {
  return axios.get(`/api/internal/solution/${id}`);
}

export function getFormInfo(id) {
  return axios.get(`/api/internal/form/${id}`);
}

export function getArticleList({ type, pageNo = 1, pageSize = 10, site, condition }) {
  return axios.post(`/api/internal/${type}/list`, {
    site: { id: site },
    pageNo,
    pageSize,
    ...condition
  });
}

export function getArticleDetail({ id, type }) {
  return axios.get(`/api/internal/${type}/${id}`);
}

export function getServiceAndSupportDetail(pampas) {
  return axios.post('/api/internal/serviceAndSupport', pampas);
}

export function getServiceAndSupportDetailForPreview(id) {
  return axios.get(`/api/internal/serviceAndSupport/${id}`);
}

export function getJoinUsDetail(pampas) {
  return axios.post('/api/internal/joinUs', pampas);
}

export function getJoinUsDetailForPreview(id) {
  return axios.get(`/api/internal/joinUs/${id}`);
}

export function getActivityDocumnets(id) {
  return axios.get(`/api/internal/activity/document/list/${id}`);
}

export function getAdvantage(params) {
  return axios.post('/api/internal/enterprise/advantage/list', params);
}
