import { useState, useEffect, useContext } from 'react';
import Context from 'container';
import usePlatform from 'hooks/usePlatform';
import { getBannerOperationExtraAttr } from 'scripts/utils';
import FullRichText from 'components/FullRichText';
import Banner from 'components/Banner';
import { BANNER_TYPE } from 'components/constants';
import { getArticleDetail } from 'services';
import { useParams } from 'react-router-dom';
import './case-detail.scss';

interface Detail {
  title: string;
  customerLogo: string;
  content: string;
  coverMaterial: string;
  videoMaterial: {
    files: {
      url: string;
    }[];
  };
  backgroundMaterial: string,
  jumpBO: any
}

export default function CaseDetail() {
  const [detail, setDetail] = useState<Detail | null>(null);
  const { currentSite = {}, lang } = useContext(Context);
  const { id } = useParams<{ id: string }>();
  const { isPc } = usePlatform() || {};

  const { title, customerLogo, content, backgroundMaterial, videoMaterial, jumpBO } = detail ?? {};

  useEffect(() => {
    (async () => {
      if (currentSite.id) {
        const result = await getArticleDetail({
          type: 'example',
          id
        });
        setDetail(result as unknown as Detail);
      }
    })();
  }, [currentSite.id, id]);

  return (
    <div className='case-detail'>
      <Banner
        covered
        paddingTop={200}
        height={isPc ? 450 : 300}
        config={{
          type: BANNER_TYPE.CAROUSEL,
          src: backgroundMaterial,
          mobileSrc: backgroundMaterial,
          title: title,
          video: videoMaterial?.files?.[0]?.url,
          operation: jumpBO ? [{
            text: jumpBO?.buttonTitle || '咨询案例',
            extraAttr: detail && getBannerOperationExtraAttr(detail)
          }] : undefined
        }}
      />
      <div className="comp-page-center">
        <div className="about-content">
          {customerLogo ? <img width={160} src={customerLogo} alt="logo" /> : null}
          <div className="case-detail-tip"> {lang === 'cn' ? '案例详情' : 'Case detail'} </div>
          <FullRichText className="rich-text" data={content ?? ''} showAnchor={undefined} style={undefined} />
        </div>
      </div>
    </div>
  );
}
