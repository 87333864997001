import React, { useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { oss } from 'scripts/utils';
import './index.scss';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { filter } from 'lodash';
import { CursorPagination } from 'components/Common/cursor-pagination';
import usePlatform from 'hooks/usePlatform';

export default function BrandWall({ data }) {
  const { isMobile } = usePlatform() || {};
  const { width } = useWindowDimensions();
  const [index, setIndex] = useState(0);

  const getSlidesToShow = () => {
    if (!width) return 6;
    // if (width >= 1600) return 6;
    if (width >= 1400) return 6;
    if (width >= 1200) return 5;
    if (width >= 900) return 4;
    if (width < 375) return 2;
    return 3;
  };

  const rows = isMobile ? 4 : 2;

  const settings = {
    infinite: true,
    swipe: isMobile,
    autoplay: isMobile ? false : true,
    autoplaySpeed: 0,
    slidesToScroll: isMobile ? getSlidesToShow() : 1,
    slidesToShow: getSlidesToShow(),
    speed: 2000,
    cssEase: 'linear',
    rows: rows,
    arrows: false,
    beforeChange: (current, next) => {
      setIndex(next);
    },
    centerPadding: '0px'
  };

  const filterData = filter(data, (d) => !!d.customerLogo) ?? [];

  return (
    <div className={classnames('brands-wall-wrapper')}>
      <div className="brands-wall">
        <Slider {...settings}>
          {filterData.map((brand, i) => (
            <div key={`brand-wall-${i}`} className={classnames('brands-item-wrap')}>
              <Link to={`/case-detail/${brand.id}`}>
                <img
                  src={oss(brand.customerLogo, 240, 80, 'lfit')}
                  alt={brand.customerName}
                  title={brand.customerName}
                />
              </Link>
            </div>
          ))}
        </Slider>
        <div className="mobile-wall-page">
          <CursorPagination
            cols={3}
            count={Math.ceil(filterData.length / rows / getSlidesToShow())}
            activeIndex={index}
          />
        </div>
      </div>
    </div>
  );
}
