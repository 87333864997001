import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from './routes-config';

interface IProps {
  initialData: any,
  route: any,
}

export default function Router({ initialData, route = {} }: IProps) {
  return (
    <Switch>
      {
        routes.map(({ component: Component, ...rest }, i) => {
          return (
            <Route
              key={i}
              {...rest}
              render={(props: any) => <Component {...props} initialData={rest.path === route.path ? initialData : undefined} />}
            />
          );
        })
      }
    </Switch>
  );
}
