import React from 'react';
import ReactHelmet from 'react-helmet';

export default function Helmet({ title, description }) {
  return (
    <ReactHelmet>
      {title ? <title>{title}</title> : null}
      {title ? <meta property="og:title" content={title} /> : null}
      {description ? <meta name="description" content={description} /> : null}
      {description ? <meta property="og:description" content={description} /> : null}
    </ReactHelmet>
  );
}
