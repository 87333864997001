import { createVideoModal } from 'components/Video/video';
import { createContactUsModal } from 'components/ContactUs';
import { isEmpty, isNil, get } from 'lodash';

export const isSSR = typeof window !== 'object';
const isSupportWebp = (() => {
  try {
    return (document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0);
  } catch (err) {
    return false;
  }
})();
const _format = isSupportWebp ? '/format,webp' : '';

export function oss(param, width, height, mode = 'lfit') {
  if (!param) return param;
  if (param?.includes('x-oss-process')) {
    return param
  }
  return `${param}?x-oss-process=image/resize,limit_0,m_${
    mode || (isNil(width) || isNil(height) ? 'lfit' : 'fill')
  },w_${parseInt(width*1.5) || 0},h_${parseInt(height*1.5) || 0}/quality,q_100${_format}`;
}

/**
 * 滚动到页面指定位置
 * @param { Number } target 目标位置
 * @param { Number } current 当前位置
 */
export function scrollTo(target = 0, current) {
  let currentTop = current;
  if (isNil(currentTop)) {
    currentTop = document.documentElement.scrollTop || document.body.scrollTop;
  }

  let needScrollTop = target - currentTop;
  let _currentTop = currentTop;
  setTimeout(() => {
    const dist = Math.ceil(needScrollTop / 10);
    _currentTop += dist;
    window.scrollTo(_currentTop, currentTop);
    if (needScrollTop > 10 || needScrollTop < -10) {
      scrollTo(target, _currentTop);
    } else {
      window.scrollTo(_currentTop, target);
    }
  }, 1);
}

/**
 * 判断元素是否在可视范围内
 * @param {Element} element 目标元素
 * @returns {boolean} 是/否
 */
export function isInViewPortOfOne(element) {
  const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const top = element.getBoundingClientRect() && element.getBoundingClientRect().top;
  return Math.abs(top) <= viewPortHeight;
}

/**
 *
 * @param {Object} data
 */
export function getBannerOperationExtraAttr(data) {
  const { jumpContentType, buttonLink, attachment, formBO } = data?.jumpBO || {};
  const extraAttr = {
    href: ''
  };
  if (jumpContentType === 'FORM') {
    extraAttr.href = undefined;
    extraAttr.onClick = () =>
      createContactUsModal({
        formId: formBO?.id,
        attachment: attachment
      });
  } else if (jumpContentType === 'DOCUMENT') {
    if (attachment?.files) {
      const [file] = attachment.files;
      if (isVideoType(file?.type)) {
        extraAttr.href = undefined;
        extraAttr.onClick = () => createVideoModal(file.url);
        extraAttr.type = 'video';
      } else {
        extraAttr.href += `${file.url}`;
      }
    }
    extraAttr.target = '_blank';
    extraAttr.rel = 'noopener noreferrer';
  } else if (jumpContentType === 'URL') {
    extraAttr.href += buttonLink;
    if (!isEmpty(get(attachment, 'files'))) {
      if (attachment?.files) {
        const [file] = attachment.files;
        extraAttr.href += `?target=${file.url}`;
      }
    }
    if (!formBO && /^https?:\/\//.test(buttonLink)) {
      extraAttr.target = '_blank';
      extraAttr.rel = 'noopener noreferrer';
    }
  } else if (jumpContentType === 'VIDEO') {
    extraAttr.href = undefined;
    const [file] = attachment?.files;
    extraAttr.onClick = () => createVideoModal(file?.url);
  } else {
    extraAttr.href = buttonLink;
  }
  return extraAttr;
}

export const isValid = (val) => {
  return val !== undefined && val !== null;
};

export const getPureText = (s) => {
  if (typeof s === 'string') {
    return s.replace(/<div(.*?)<\/div>|<p(.*?)>|<\/p>/g, '');
  }
  return '';
};

export function contactLink() {
  const list = arguments;
  let link = '';
  for (let i = 0; i < list.length; i++) {
    let str = arguments[i];
    const first = str.slice(0, 1);
    const last = link.slice(-1);
    if (link === '') {
      link += str;
    } else if (['/', '#'].includes(last)) {
      if (['/', '#'].includes(first)) {
        link = link.slice(0, -1);
      }
      link += last;
    } else {
      if (!['/', '#'].includes(first)) {
        link += '/';
      }
      link += str;
    }
  }
  return link;
}

export const RGBA2RGB = (rgba, bg) => {
  const { r, g, b, a } = rgba;
  const { r: bgR, g: bgG, b: bgB } = bg;

  const resR = Math.round(r * a + bgR * (1 - a));
  const resG = Math.round(g * a + bgG * (1 - a));
  const resB = Math.round(b * a + bgB * (1 - a));
  return { r: resR, g: resG, b: resB };
};

export const videoSuffixes = [
  'wmv',
  'asf',
  'asx',
  'rm',
  'rmvb',
  'm',
  'mp4',
  'mov',
  'm4vavi',
  'dat',
  'mkv',
  'flv',
  'vob'
];

export const isVideoType = (type) => {
  if (typeof type !== 'string') {
    return false;
  }
  return videoSuffixes.includes(type);
};
