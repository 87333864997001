import React, { useContext } from 'react';
import Context from 'container';
import useLanguage from 'hooks/useLanguage';
import FooterItem from './footer-item';
import './index.scss';
import { fieldTranslation } from 'components/constants';

export default function Footer({ host, recordNumber, footerData }) {
  const { i18n } = useLanguage();
  const { lang } = useContext(Context);
  const is1688 = typeof window !== 'undefined' && window?.location?.pathname.startsWith('/go1688')
  const others = is1688 ? footerData.others?.filter(s => !(s.text?.includes('电话') || s.text?.includes('邮箱') || s.text?.includes('售前咨询') || s.text?.includes('媒体合作') || s.text?.includes('供应商合作'))) : footerData.others

  return (
    <div className="footer-root">
      <img className="footer-img" src={lang ? `/static/images/footer-map-${lang}.png` : undefined} alt="" />
      <div className="footer-wrapper">
        <div className="comp-content footer-top">
          <div className="footer-left">
            {/* <h2>
              <img className="footer-left-icon" src="/static/images/logo.png" alt="杭州端点网络科技有限公司" />
            </h2> */}
            <div className="contact-us">{i18n(fieldTranslation, '联系我们')} </div>
            {others instanceof Array &&
              others.map((item, i) => (
                <div key={i} className="contact-line">
                  <img width={12} height={12} src={item.icon} alt="" />
                  <span key={i} className="footer-left-text">
                    {item.text}
                  </span>
                </div>
              ))}
          </div>
          <div className="footer-right">
            {footerData.quickLinks instanceof Array &&
              footerData.quickLinks.map((item, i) => (
                <FooterItem key={i} title={i18n('BOTTOMNAVIGATION', item.title)} content={item.content} />
              ))}
          </div>
        </div>
        {lang === 'cn' ? (
          <div className="footer-bottom">
            <img className="footer-bottom-logo" src="/static/images/logo.png" alt="杭州端点网络科技有限公司" />
            <div className="footer-bottom-detail">
              <div style={{ marginRight: 24 }} className="footer-bottom-txt footer-bottom-txt-prefix">
                <img data-src="/static/images/footlogo1.png" alt="" />
                <img data-src="/static/images/footlogo2.png" alt="" />
                <a
                  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802003150"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  浙公网安备 33010802003150号
                </a>
              </div>
              <div className="footer-bottom-txt">
                © 2012-{new Date().getFullYear()} {host} &nbsp;
                <span className="hidden-in-mobile">
                  备案/许可证号：
                  <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">
                    浙ICP备13004315号{!isNaN(recordNumber) ? `-${recordNumber}` : null}
                  </a>
                </span>
                <span className="hidden-in-pc">All rights reserved</span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
