import { IconWrapper, ISvgIconProps } from ".";

export default IconWrapper(
  'array',
  true,
  (props: ISvgIconProps) => (
    <svg width={props.size} height={props.size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31 24.0083H12"
        stroke={props.colors[0]}
        strokeWidth={props.strokeWidth}
        strokeLinecap={props.strokeLinecap}
        strokeLinejoin={props.strokeLinejoin}
      />
      <path d="M24 12L36 24L24 36"
        stroke={props.colors[0]}
        strokeWidth={props.strokeWidth}
        strokeLinecap={props.strokeLinecap}
        strokeLinejoin={props.strokeLinejoin}
      />
    </svg>
  )
);
