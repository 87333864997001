import { fieldTranslation } from 'components/constants';
import CloseSmall from 'components/IconWrapper/close-small';
import Overlay from 'components/Overlay';
import useLanguage from 'hooks/useLanguage';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import './video.scss';

interface IVideoModalProps {
  src: string;
  open?: boolean;
  onClose?: () => void;
}

const VideoModal = (props: IVideoModalProps) => {
  const { src, open, onClose } = props;
  const { i18n } = useLanguage();

  const videoContent = (
    <div className="video-modal-wrapper">
      <video controls src={src} />
      <div onClick={() => onClose?.()} className="video-close hidden-in-mobile">
        {i18n(fieldTranslation, '关闭')}
      </div>
      <div onClick={() => onClose?.()} className="video-close-mobile hidden-in-pc">
        <CloseSmall size={20} />
      </div>
    </div>
  );

  return <Overlay maskClass="video-black" open={open} content={videoContent} />;
};

const ROOT_ID = 'video-modal';

export const createVideoModal = (src?: string) => {
  if (!src) return null;
  const Comp = () => {
    const [open, setOpen] = useState(true);
    return <VideoModal src={src} open={open} onClose={() => setOpen(false)} />;
  };

  const videoModalRoot = document.getElementById(ROOT_ID);
  if (!videoModalRoot) {
    const root = document.createElement('div');
    root.id = ROOT_ID;
    document.body.appendChild(root);
  }

  ReactDOM.render(<Comp />, document.getElementById(ROOT_ID));
};

export default VideoModal;
