import { IconWrapper, ISvgIconProps } from '.';

export default IconWrapper('intro', true, (props: ISvgIconProps) => (
  <svg width={props.size} height={props.size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <polygon points="0,0 20,0 20,20 0,20 " />
    <path
      fill={props.colors[0]}
      stroke={props.colors[0]}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
      strokeLinejoin={props.strokeLinejoin}
      d="M16.7,18.3H3.3c-0.5,0-0.8-0.4-0.8-0.8v-15c0-0.5,0.4-0.8,0.8-0.8h13.3c0.5,0,0.8,0.4,0.8,0.8v15  C17.5,18,17.1,18.3,16.7,18.3z M15.8,16.7V3.3H4.2v13.3H15.8z M6.7,5.8h6.7v1.7H6.7V5.8z M6.7,9.2h6.7v1.7H6.7V9.2z M6.7,12.5h4.2  v1.7H6.7V12.5z"
    />
  </svg>
));
