import cn from 'classnames';
import CloseSmall from 'components/IconWrapper/close-small';
import Overlay from 'components/Overlay';
import usePlatform from 'hooks/usePlatform';
import { useRef } from 'react';
import { isValid } from 'scripts/utils';
import './contact-modal.scss';

export interface IContactModalProps {
  open?: boolean;
  onClose?: () => void;
  title?: React.ReactNode;
  content?: React.ReactNode;
  destroyOnClose?: boolean;
}

const ContactModal = (props: IContactModalProps) => {
  const { open = false, onClose, title, content, destroyOnClose } = props;
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { isPc } = usePlatform() ?? {};

  const handleClose = () => {
    onClose?.();
  };

  const getContentScroll = () => {
    if (isValid(contentRef.current?.scrollHeight) && isValid(contentRef.current?.clientHeight)) {
      return contentRef.current!.scrollHeight > contentRef.current!.clientHeight;
    }
    return false;
  };

  const isContentScroll = getContentScroll();
  const modal = (
    <div className={`comp-modal-container ${isPc ? 'comp-modal-pc-animation' : 'comp-modal-mo-animation'}`}>
      <div className={cn('comp-modal-header', { 'comp-modal-header-shadow': isContentScroll })}>
        <div className="comp-modal-closer" onClick={handleClose}>
          <CloseSmall size={isPc ? undefined : 20} strokeWidth={isPc ? 8 : 4} />
        </div>
        {title}
      </div>
      <div ref={contentRef} className="comp-modal-content">
        {content}
      </div>
    </div>
  );
  return <Overlay destroy={destroyOnClose} open={open} content={modal} />;
};

export default ContactModal;
