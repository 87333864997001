import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { dateFormatter, enDateFormatter } from 'components/constants';
import Context from 'container';
import Icon from 'components/Icon';
import './content-header.scss';
import Divider from 'components/Common/divider';

interface IMedia {
  address: string;
  mediaName: string;
}
interface IProps {
  title: string;
  label?: string;
  time?: string;
  desc?: string;
  mediaCoverageList?: IMedia[];
}

const ContentHeader = ({ title, label, time, desc, mediaCoverageList }: IProps) => {
  const { lang } = useContext(Context);
  return (
    <div className="content-detail-header">
      <h2>{title}</h2>
      <div className="content-detail-other">
        {label && (
          <div className="content-detail-tags">
            {label.split(',').map((tag, index) => (
              <span key={index}>
                <Icon type="label" />
                {tag}
              </span>
            ))}
          </div>
        )}
        {time && (
          <div className="content-detail-time">
            {dayjs(time).format(lang === 'cn' ? dateFormatter : enDateFormatter)}
          </div>
        )}
      </div>
      {desc && <div className="content-detail-desc">{desc}</div>}
      <Divider className="content-line" />
      {mediaCoverageList instanceof Array && mediaCoverageList.length ? (
        <div className="content-detail-source">
          媒体报道：
          {mediaCoverageList.map((source, index) => (
            <a key={index} href={source.address} title={source.mediaName}>
              #{source.mediaName}
            </a>
          ))}
        </div>
      ) : null}
    </div>
  );
};
export default ContentHeader;
