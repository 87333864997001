import React, { useState, useEffect, useRef, useContext, ReactChildren } from 'react';
import { Link, useParams } from 'react-router-dom';
import usePlatform from 'hooks/usePlatform';
import useLanguage from 'hooks/useLanguage';
import { Tabs } from 'antd';
import Helmet from 'components/Helmet';
import Banner from 'components/Banner';
import { BANNER_TYPE, fieldTranslation } from 'components/constants';
import MainTitle from 'components/Common/main-title';
import './detail.scss';
import map from 'lodash/map';
import { Resource } from 'components/types';
import { CursorPagination } from 'components/Common/cursor-pagination';
import CircleButton from 'components/Common/circle-button';
import Slider, { Settings } from 'react-slick';
import cn from 'classnames';
import { Case } from 'pages/Cases/cases-list';
import { oss } from 'scripts/utils';
import Context from 'container';
import Consult from 'components/Consult';
import { getProductDetail } from './service';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import Divider from 'components/Common/divider';
import SliderDetail, { ISingle } from './SliderDetail';

const { TabPane } = Tabs
type ApplicationScenarios = {
  desc: string;
  id: string;
  title: string;
  resource: Resource;
};

type ProductSuperiority = {
  id: number;
  desc: string;
  title: string;
  resource: Resource;
};

type BusinessValue = {
  id: number;
  name: string;
  desc: string;
  config: {
    id: number;
    title: string;
    desc: string;
  }[];
  resource: Resource;
};

type ProductConfig = {
  name: string;
  desc: string;
  config: {
    id: number;
    title: string;
    desc: string;
    resource: Resource;
  }[];
};

type CoreFunctionProps = {
  id: number
  name: string;
  iconUrl: string;
  functionList: ISingle[];
};

type BusinessBluePrint = {
  id: number
  title: string;
  logo: string;
  resourceUrl: string;
}
export interface ProductDetail {
  id: number;
  desc: string;
  name: string;
  proLogo: string;
  plateNaviDesc: string;
  proPlateLogo: string;
  pcResource: Resource;
  isShowEnterpriseDemandTitle: boolean // 展示企业需求标题
  productConfig: ProductConfig; // 企业需求
  businessValues: BusinessValue[]; // 提供的业务价值
  mobileResource: Resource;
  exampleBOList: Case[];
  applicationScenarios: ApplicationScenarios[]; // 应用场景
  productSuperiority: ProductSuperiority[]; // 产品优势
  coreFunctions: CoreFunctionProps[]
  businessBluePrints: BusinessBluePrint[]
  // 白皮书下载
  jumpBO?: {
    attachment: Resource;
    buttonTitle: string;
  };
  // 预约演示
  demonstration: {
    attachment: Resource;
    buttonTitle: string;
  };
}

function PlateDetailPage() {
  const { isPc } = usePlatform() || {};
  const { i18n } = useLanguage();
  const { updateObserve } = useContext(Context);
  const [data, setData] = useState<ProductDetail | null>(null);
  const params = useParams<{ id: string }>();
  const { id } = params;
  const sceneSliderRef = useRef<Slider | null>(null);
  const [activeSceneIndex, setActiveSceneIndex] = useState(0);
  const [activeKey, setActiveKey] = useState<string>()

  const productConfig = data?.productConfig?.config;
  const productSuperiority = data?.productSuperiority;
  const applicationScenarios = data?.applicationScenarios;
  const coreFunctions = data?.coreFunctions || []
  const businessBluePrints = data?.businessBluePrints || []
  useEffect(() => {
    if ((coreFunctions.length === 0)) {
      return
    }
    setActiveKey(coreFunctions[0]?.id.toString())
  }, [coreFunctions])

  let files: { url: string }[] = [];
  const { pcResource, mobileResource } = data ?? {};
  if (pcResource || mobileResource) {
    if (isPc) {
      files = pcResource?.files || [];
    } else {
      files = mobileResource?.files || [];
    }
  }

  const businessValues = data?.businessValues ?? [];
  const sliderSceneSetting: Settings = {
    centerMode: true,
    slidesToScroll: 1,
    variableWidth: isPc ? true : false,
    arrows: false,
    beforeChange: (_: number, index: number) => {
      setActiveSceneIndex(index);
    }
  };

  useEffect(() => {
    (async () => {
      if (id) {
        const result = (await getProductDetail(id)) as unknown as ProductDetail;
        setData(result);
        updateObserve();
      }
    })();
  }, [id]);

  return (
    <React.Fragment>
      <Helmet title={`${data?.desc ?? ''}-端点-数字化转型-全链路解决方案`} description={data?.desc} />
      <Banner
        covered
        paddingTop={isPc ? 300 : 200}
        config={{
          type: BANNER_TYPE.PLATE_DETAIL,
          src: files[0]?.url,
          title: data?.name,
          legend: data?.desc,
          operation: {
            jumpBO: data?.jumpBO,
            demonstration: data?.demonstration
          }
        }}
      />
      <div className={classNames('plate-demand', { hidden: isEmpty(productConfig) })}>
        {data?.isShowEnterpriseDemandTitle && <MainTitle
          className="plate-main-title"
          main={data?.productConfig?.name ?? ''}
          sub={data?.productConfig?.desc ?? ''}
        />}
        <div
          className={classNames('plate-demand-wrapper', {
            'grid-col-2': productConfig && productConfig.length <= 2
          })}
        >
          {map(data?.productConfig?.config, (scene) => {
            const icon = scene.resource?.files?.[0].url;
            return (
              <div className="plate-demand-item" key={scene.id}>
                {icon ? <img width={32} height={32} data-src={oss(icon, 64, 64)} alt="" /> : null}
                <div className="plate-demand-item-text">
                  <div className="plate-demand-item-title">{scene.title}</div>
                  <div className="plate-demand-item-desc pre-line">{scene.desc}</div>
                </div>
              </div>
            );
          })}
        </div>
        <Divider className="demand-divider hidden-in-pc" />
      </div>
      {businessValues.length > 0 ? (
        <div className={classNames('plate-values')}>
          <MainTitle className="plate-values-title" main={i18n(fieldTranslation, '探索产品优势')} />
          <SliderDetail list={businessValues as any}/>
        </div>
      ) : null}
      <div className={classNames('plate-advantage', { hidden: isEmpty(productSuperiority) })}>
        <MainTitle className="plate-advantage-title" main={i18n(fieldTranslation, '提供的业务价值')} />
        <div
          className={classNames(
            'plate-advantage-wrapper',
            {
              'grid-col-3': productSuperiority && productSuperiority.length === 3
            },
            {
              'grid-col-2': productSuperiority && productSuperiority.length <= 2
            }
          )}
        >
          {map(productSuperiority, (item) => {
            const logo = item.resource?.files?.[0].url;
            return (
              <div className="plate-advantage-item" key={item.id}>
                {logo ? <img width={32} height={32} data-src={oss(logo, 64, 64)} alt="" /> : null}
                <div className="plate-advantage-item-title">{item.title}</div>
                <div className="plate-advantage-item-desc pre-line">{item.desc}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classNames('plate-values', { hidden: isEmpty(coreFunctions) })}>
        <MainTitle className="plate-core-title" main={i18n(fieldTranslation, '核心功能')} />
        <Tabs activeKey={activeKey} onChange={(v) => setActiveKey(v)}>
          {map(coreFunctions, (value) => {
            return (
              <TabPane
                key={value.id.toString()}
                tabKey={value.id.toString()}
                tab={<div>
                  <img src={value.iconUrl}/>
                  <span className='fun-tab-name'>{value.name}</span>
                </div>}
              >
                <SliderDetail activeKey={activeKey} list={value?.functionList || []} />
              </TabPane>
            )
          })}
        </Tabs>
      </div>
      <div className={classNames('plate-values', { hidden: isEmpty(businessBluePrints) })}>
        <MainTitle className="plate-core-title" main={i18n(fieldTranslation, '业务蓝图')} />
        <Tabs defaultActiveKey={businessBluePrints[0]?.id.toString()}>
          {map(businessBluePrints, (value) => {
            return (
              <TabPane
                key={value.id}
                tab={<div>
                  <img src={value.logo}/>
                  <span className='fun-tab-name'>{value.title}</span>
                </div>}
              >
                <div className='blue-print-wraper'>
                  <img src={value.resourceUrl} className='blue-print-img'/>
                </div>
              </TabPane>
            )
          })}
        </Tabs>
      </div>
      <div className={classNames('plate-scene', { hidden: isEmpty(applicationScenarios) })}>
        <MainTitle className="plate-main-title" main={i18n(fieldTranslation, '应用场景')} />
        <div className="carousel">
          <Slider {...sliderSceneSetting} ref={sceneSliderRef} className="scene-content-wrapper">
            {map(applicationScenarios, (value, index) => {
              const icon = value.resource?.files?.[0].url;
              return (
                <div
                  className={cn('scene-content', { 'scene-content-active': activeSceneIndex === index })}
                  style={{ width: 680 }}
                  key={index}
                >
                  <main>
                    <div className="scene-logo flex-h-center">
                      {icon ? <img width={40} height={40} data-src={oss(icon, 40, 40)} alt="" /> : null}
                    </div>
                    <div className="scene-text">
                      <div className="vertical-line"></div>
                      <div className="scene-content-title">{value.title}</div>
                      <div className="scene-content-desc line-clamp-2">{value.desc}</div>
                    </div>
                  </main>
                </div>
              );
            })}
          </Slider>
          {applicationScenarios && applicationScenarios.length > 1 ? (
            <div className="flex-h-center">
              <div className="flex-h-center scene-carousel-footer">
                <CircleButton
                  className="scene-circle hidden-in-mobile"
                  onClick={sceneSliderRef.current?.slickPrev}
                  type="prev"
                />
                <CursorPagination
                  className="scene-pagination"
                  onItemClick={(i) => sceneSliderRef.current?.slickGoTo(i)}
                  activeIndex={activeSceneIndex}
                  count={applicationScenarios?.length ?? 0}
                />
                <CircleButton
                  className="scene-circle hidden-in-mobile"
                  onClick={sceneSliderRef.current?.slickNext}
                  type="next"
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className={classNames('plate-cases', { hidden: isEmpty(data?.exampleBOList) })}>
        <MainTitle main={i18n(fieldTranslation, '客户成功案例')} />
        <CaseSwitch className="plate-cases-list" data={data?.exampleBOList} />
      </div>
      <Consult isDetail={true} />
    </React.Fragment>
  );
}

interface ICaseSwitchProps {
  data?: Case[];
  className?: string;
  renderItem?: (item: Case, index: number) => JSX.Element
}

export const CaseSwitch = (props: ICaseSwitchProps) => {
  const { data, className, renderItem: originRenderItem } = props;
  const { i18n } = useLanguage();
  const sliderRef = useRef<Slider | null>(null);
  const isShowSlider = data?.length && data.length >= 3;
  const settings: Settings = {
    slidesToShow: isShowSlider ? 3 : 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    variableWidth: true
  };

  const renderItem = originRenderItem ? originRenderItem : (item: Case) => {
    return (
      <div>
        <div style={{ width: 320 }} className="card">
          <Link className="card-inner" to={`/case-detail/${item.id}`}>
            <img className="card-img" src={`${oss(item.coverMaterial, 320, 160)}`} alt="" />
            <div className="card-content">
              <img className="card-logo" src={oss(item.customerLogo, 100, 24, 'lfit')} alt="" />
              <div className="title line-clamp-2">{item.title}</div>
              <div className="item-detail">{i18n(fieldTranslation, '查看详情')}</div>
            </div>
          </Link>
        </div>
      </div>
    );
  };

  const sliderContent = (
    <div className={cn('case-switch-wrapper')}>
      <Slider ref={sliderRef} {...settings}>
        {map(data, (item, index) => {
          return <React.Fragment key={index}>{renderItem(item, index)}</React.Fragment>;
        })}
      </Slider>
      {data?.length && data.length > 3 ? (
        <div>
          <CircleButton className="case-prev" onClick={sliderRef.current?.slickPrev} type="prev" />
          <CircleButton className="case-next" onClick={sliderRef.current?.slickNext} type="next" />
        </div>
      ) : null}
    </div>
  );

  const normalContent = (
    <div className="case-switch-list">
      {map(data, (item, index) => {
        return <React.Fragment key={index}>{renderItem(item, index)}</React.Fragment>;
      })}
    </div>
  );

  return (
    <React.Fragment>
      <div className={cn(className, 'case-switch hidden-in-mobile')}>
        {isShowSlider ? sliderContent : normalContent}
      </div>
      <div className={cn(className, 'case-switch hidden-in-pc')}>{normalContent}</div>
    </React.Fragment>
  );
};

export default PlateDetailPage;
