import cn from 'classnames';
import Arrow from 'components/IconWrapper/arrow';
import { HtmlHTMLAttributes } from 'react';
import './index.scss';

interface ICircleButton {
  type?: 'next' | 'prev' | 'top' | 'bottom';
  block?: boolean;
}

const CircleButton = (props: ICircleButton & HtmlHTMLAttributes<HTMLDivElement>) => {
  const { type = 'next', block, className, ...restProps } = props;
  return (
    <div className={cn('common-circle-button', { block: block }, className)} {...restProps}>
      <Arrow className={cn(`rotate-${type}`)} />
    </div>
  );
};

export default CircleButton;
