import React, { useState, useEffect, useContext } from 'react';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import Context from 'container';
import usePlatform from 'hooks/usePlatform';
import useLanguage from 'hooks/useLanguage';
import useBannerConfig from 'hooks/useBannerConfig';
import Helmet from 'components/Helmet';
import Banner from 'components/Banner';
import FullRichText from 'components/FullRichText';
import VideoPlayer from 'components/VideoPlayer';
import { BANNER_TYPE, buttonThemes, linkIcons } from 'components/constants';
import { getBannerOperationExtraAttr } from 'scripts/utils';
import { getJoinUsDetail, getJoinUsDetailForPreview } from 'services';
import './join-us.scss';

function JoinUsPage({ initialData }) {
  const { isPc } = usePlatform() || {};
  const { i18n } = useLanguage();
  const context = useContext(Context);
  const { currentSite = {} } = context;
  const params = useParams();
  const banner = useBannerConfig();
  const [data, setData] = useState(initialData || {});
  const [videosInfo, setVideosInfo] = useState({});
  const [visible, setVisible] = useState(false);

  let files = [];
  const { pcResource, mobileResource } = banner;
  if (pcResource || mobileResource) {
    if (isPc) {
      files = pcResource?.files || [];
    } else {
      files = mobileResource?.files || [];
    }
  }
  const src = files[0]?.url;

  useEffect(() => {
    (async () => {
      if (isEmpty(initialData) && currentSite.id) {
        const result = await JoinUsPage.getInitialProps({ context, params });
        setData(result);
      }
    })();
  }, [currentSite.id, params.id]);

  function videoCallback(info) {
    setVideosInfo(info);
    toggleVideo();
    window.history.pushState({ videoPlayerVisible: !visible }, '', window.location.href);
  }

  function toggleVideo() {
    setVisible(!visible);
  }

  return (
    <React.Fragment>
      <Helmet title="数字化转型案例-端点-Terminus-全链路解决方案" description={banner.legend} />
      <Banner
        // covered
        type={BANNER_TYPE.DETAIL_HEADER}
        config={{
          src: '/static/images/join-us.png',
          mobileSrc: '/static/images/join-us.png',
          title: banner.title || i18n('TOPNAVIGATION', '加入我们'),
          legend: banner.legend,
          operation: [
            {
              ...buttonThemes,
              // icon: 'label',
              text: banner?.jumpBO?.buttonTitle,
              icon: linkIcons[banner?.jumpBO?.jumpContentType],
              extraAttr: getBannerOperationExtraAttr(banner, () =>
                videoCallback({
                  title: banner.title,
                  desc: banner.legend,
                  videos: banner?.videoRelation?.videos
                })
              )
            }
          ]
        }}
      />
      <FullRichText className="join-us-text" data={data?.describe} />

      {visible ? <VideoPlayer videosInfo={videosInfo} visible={visible} onCancel={toggleVideo} /> : null}
    </React.Fragment>
  );
}

JoinUsPage.getInitialProps = async ({ context, params }) => {
  if (params.id) {
    return getJoinUsDetailForPreview(params.id);
  }

  const { currentSite = {} } = context;
  return getJoinUsDetail({ site: { id: currentSite.id } });
};

export default JoinUsPage;
