import React, { useState, useEffect, useContext, useRef } from 'react';
import Context from 'container';
import usePlatform from 'hooks/usePlatform';
import useLanguage from 'hooks/useLanguage';
import Helmet from 'components/Helmet';
import { postSolutionList } from './services';
import { Case } from 'pages/Cases/cases-list';
import { Resource } from 'components/types';
import Slider, { Settings } from 'react-slick';
import { map } from 'lodash';
import MainTitle from 'components/Common/main-title';
import { isDark } from 'scripts/color-extractor/utils';
import './index.scss';
import { isSSR, oss } from 'scripts/utils';
import CircleButton from 'components/Common/circle-button';
import cn from 'classnames';
import { getColorByUrl } from 'scripts/color-extractor';
import { Link } from 'react-router-dom';
import VideoIcon from 'components/Video/video-icon';
import { createVideoModal } from 'components/Video/video';
import { divideData } from 'components/About/news';
import Divider from 'components/Common/divider';

interface SolutionItem {
  id: number;
  name: string;
  pcResource: Resource;
  mobileResource: Resource;
}
export interface SolutionsData {
  examples: Case[];
  industry: {
    name: string;
    desc: string;
    indusIcon: string;
  };
  solutions: SolutionItem[];
}

function SolutionPage() {
  const { isPc } = usePlatform() || {};
  const { i18n } = useLanguage();
  const context = useContext(Context);
  const { currentSite = {}, theme, dispatch, lang, updateObserve } = context;
  const [data, setData] = useState<SolutionsData[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef<Slider | null>(null);
  const [activeCaseId, setActiveCaseId] = useState<string | null>(null);
  const hash = isSSR ? '0' : window.location.hash.slice(1);
  const PAGE_COLUMN = isPc ? 3 : 2;
  const settings: Settings = {
    fade: true,
    initialSlide: Number(hash) ?? 0,
    arrows: false,
    beforeChange: (current, next) => {
      setActiveIndex(next);
      if (!isSSR) {
        window.location.hash = `${next}`;
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (!currentSite.id) return;
      const solutions = await postSolutionList({
        site: {
          id: currentSite.id
        }
      });
      setData(solutions as unknown as SolutionsData[]);
      updateObserve();
    })();
  }, [currentSite.id]);

  useEffect(() => {
    if (sliderRef.current) {
      const jumpIndex = Number(hash) ? Number(hash) : 0;
      sliderRef.current.slickGoTo(jumpIndex);
    }
  }, [hash]);

  useEffect(() => {
    const item = data[activeIndex];
    if (item?.industry.indusIcon) {
      getColorByUrl(item.industry.indusIcon).then((res) => {
        dispatch({ theme: isDark(res) ? 'dark' : 'light' });
      });
    }
    updateObserve()
  }, [activeIndex, data, dispatch]);

  useEffect(() => {
    return () => {
      dispatch({ theme: undefined });
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Helmet title={`${'解决方案'}-端点-数字化转型-全链路解决方案`} description={data[activeIndex]?.industry.desc} />
      <div className="solution-page-wrapper">
        <div className="carousel">
          <Slider ref={sliderRef} {...settings}>
            {map(data, (item, index) => {
              return (
                <div className="carousel-wrapper" key={index}>
                  <div
                    className="carousel-bg"
                    style={{ backgroundImage: `url(${oss(item.industry.indusIcon, 1440, 760)})` }}
                  ></div>
                  <MainTitle
                    className="carousel-content"
                    theme={theme}
                    main={item.industry.name}
                    sub={item.industry.desc}
                    size="xl"
                  />
                </div>
              );
            })}
          </Slider>
          {data.length > 1 ? (
            <div className="carousel-footer">
              <CircleButton onClick={() => sliderRef.current?.slickPrev()} className="circle" type="prev" />
              <div className="carousel-footer-wrapper">
                {map(data, (item, index) => {
                  const isItemActive = index === activeIndex;
                  return (
                    <div
                      className={cn('carousel-footer-item', { 'carousel-footer-item-active': isItemActive })}
                      key={index}
                      onClick={() => sliderRef?.current?.slickGoTo(index)}
                      style={{ backgroundImage: `url(${oss(item.industry.indusIcon, 128, 56)})` }}
                    >
                      <div className="title">{item.industry.name}</div>
                      {isItemActive ? null : <div className="item-mask"></div>}
                    </div>
                  );
                })}
              </div>
              <CircleButton onClick={() => sliderRef.current?.slickNext()} className="circle" type="next" />
            </div>
          ) : null}
        </div>
        <div className="solutions">
          <MainTitle className="title" main={i18n('SOLUTIOON', '解决方案')} />
          <div className="grid">
            {map(data[activeIndex]?.solutions, (solution) => {
              const resource = (isPc ? solution.pcResource : solution.mobileResource) || solution.pcResource;
              const img = resource?.files?.[0]?.url;
              return (
                <Link to={`/solution/${solution.id}`} key={solution.id}>
                  <div className="solution-item">
                    <span> {solution.name}</span>
                    <div
                      className="solution-item-img img-background flex-h-center"
                      style={{ backgroundImage: `url(${oss(img, 280, 158)})` }}
                    ></div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
        <div className="cases">
          <MainTitle className="title" main={i18n('SOLUTIOON', '相关案例')} />
          <div className="cases-list">
            {divideData<Case>(data[activeIndex]?.examples, PAGE_COLUMN).map((cases, casesIndex) => {
              return (
                <div key={casesIndex}>
                  <div className="cases-list-row">
                    {cases.map((c, i) => {
                      const video = c.videoMaterial?.files[0]?.url;
                      return (
                        <Link
                          onMouseLeave={() => video && setActiveCaseId(null)}
                          onMouseEnter={() => video && setActiveCaseId(`${c.id}`)}
                          to={`/case-detail/${c.id}`}
                          className="cases-list-item"
                          key={i}
                        >
                          <div className="item-img-wrapper">
                            {video ? (
                              <VideoIcon
                                active={`${c.id}` === activeCaseId}
                                className="item-play"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  ev.stopPropagation();
                                  createVideoModal(video);
                                }}
                              >
                                {lang === 'cn' ? '立即播放' : 'Play'}
                              </VideoIcon>
                            ) : null}
                            <img className="item-img" data-src={oss(c.coverMaterial, 280, 158)} alt={c.title} />
                          </div>
                          <img
                            className="item-logo"
                            data-src={oss(c.customerLogo, 100, 24, 'lfit')}
                            alt={c.customerName}
                            title={c.customerName}
                          />
                          <div className="item-title line-clamp-2">{c.title}</div>
                          <div className="item-detail line-clamp-2">{lang === 'cn' ? '查看详情' : 'Detail'}</div>
                        </Link>
                      );
                    })}
                  </div>
                  <Divider className="row-divider" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SolutionPage;
