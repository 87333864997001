import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Context from 'container';

export default function useBannerConfig() {
  const { topNav } = useContext(Context);
  const [banner, setBanner] = useState({});
  const params = useParams();

  useEffect(() => {
    topNav instanceof Array && topNav.forEach((single) => {
      let { pathname } = window.location;

      Object.values(params).forEach(value => {
        pathname = pathname.replace(`/${value}`, '');
      })
      if(pathname.startsWith(single.pageAddress) ) {
        setBanner({
          title: single.displayName,
          legend: single.desc,
          mobileResource: single.mobileResource,
          pcResource: single.pcResource,
          jumpBO: single?.jumpBO,
          videoRelation: single.videoRelation,
        });
      }
    })
  }, [params, topNav]);

  return banner;
}
