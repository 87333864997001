import { DatePicker } from 'antd-mobile';
import classNames from 'classnames';
import CloseSmall from 'components/IconWrapper/close-small';
import DownOne from 'components/IconWrapper/down-one';
import dayjs, { Dayjs } from 'dayjs';
import { useRef, useState } from 'react';
import './mobile-date-picker.scss';
import useLanguage from 'hooks/useLanguage';
import { fieldTranslation } from 'components/constants';

type PickerValue = undefined | null | [Dayjs, Dayjs];
type _PickerValue = undefined | null | [Dayjs | undefined, Dayjs | undefined];

interface IMobileDatePickerProps {
  value?: PickerValue;
  onChange?: (val: PickerValue) => void;
  className?: string;
  placeholder?: React.ReactNode;
}

const MobileDatePicker = (props: IMobileDatePickerProps) => {
  const { value, onChange, placeholder, className } = props;
  const [_value, _setValue] = useState<PickerValue>(value);
  const realValue = value ?? _value;
  const processValue = useRef<_PickerValue>(realValue);
  const [startVisible, setStartVisible] = useState(false);
  const [endVisible, setEndVisible] = useState(false);
  const { i18n } = useLanguage();

  const isDateFull = !!(processValue.current && processValue.current[0] && processValue.current[1]);
  const handleClickInput = () => {
    if (Array.isArray(processValue.current) && !processValue.current[1]) {
      setEndVisible(true);
      setStartVisible(false);
    } else {
      setStartVisible(true);
      setEndVisible(false);
    }
  };

  const setStartValue = (d: Date | null | undefined) => {
    if (processValue.current) {
      // 有值的情况
      if (d !== null && d !== undefined) {
        const _v: _PickerValue = [dayjs(d), processValue.current[1]];
        processValue.current = _v;
        if (d && processValue.current[1]) {
          _setValue(_v as PickerValue);
          onChange?.(_v as PickerValue);
          setStartVisible(false);
          setEndVisible(false);
        }
      } else {
        if (processValue.current[1]) {
          processValue.current = [undefined, processValue.current[1]];
        } else {
          processValue.current = undefined;
          _setValue(undefined);
        }
      }
    } else {
      processValue.current = [dayjs(d), undefined];
      // 下一步
      handleClickInput();
    }
  };

  const setEndValue = (d: Date | null | undefined) => {
    if (processValue.current) {
      // 有值的情况
      if (d !== null && d !== undefined) {
        const _v: _PickerValue = [processValue.current[0], dayjs(d)];
        processValue.current = _v;
        if (processValue.current[0]) {
          onChange?.(_v as PickerValue);
          _setValue(_v as PickerValue);
          setStartVisible(false);
          setEndVisible(false);
        }
      } else {
        if (processValue.current[0]) {
          processValue.current = [processValue.current[0], undefined];
        } else {
          processValue.current = undefined;
          _setValue(undefined);
        }
      }
    } else {
      processValue.current = [undefined, dayjs(d)];
      // 下一步
      handleClickInput();
    }
  };

  const handleClear = () => {
    processValue.current = undefined;
    _setValue(undefined);
    onChange?.(undefined);
  };

  const timeStr = realValue ? `${realValue[0].format('YYYY-MM-DD')} ~ ${realValue[1].format('YYYY-MM-DD')}` : undefined;

  return (
    <>
      <div className={classNames('mobile-date-picker', className)}>
        <div
          className={classNames('mobile-date-picker-content', { 'mobile-date-picker-content-active': timeStr })}
          onClick={handleClickInput}
        >
          {/* {timeStr ? timeStr : placeholder} */}
          {placeholder}
        </div>
        <div className={classNames('mobile-date-picker-closer', { hidden: !isDateFull })} onClick={handleClear}>
          <CloseSmall strokeWidth={4} />
        </div>
        <div className={classNames('mobile-date-picker-down', { hidden: isDateFull })}>
          <DownOne />
        </div>
      </div>

      <DatePicker
        visible={startVisible}
        title={i18n(fieldTranslation, '开始时间')}
        onClose={() => {
          setStartVisible(false);
          // if (!isDateFull) {
          //   handleClear();
          // }
        }}
        value={realValue?.[0]?.toDate()}
        precision="day"
        onConfirm={setStartValue}
      />
      <DatePicker
        visible={endVisible}
        title={i18n(fieldTranslation, '结束时间')}
        onClose={() => {
          setEndVisible(false);
          // if (!isDateFull) {
          //   handleClear();
          // }
        }}
        value={realValue?.[1]?.toDate()}
        precision="day"
        onConfirm={setEndValue}
      />
    </>
  );
};

export default MobileDatePicker;
