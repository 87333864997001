import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import './index.scss';
import Popover from 'components/Pophover';
import useWindowDimensions from 'hooks/useWindowDimensions';

import { isArray, map } from 'lodash';
import Right from 'components/IconWrapper/right';
import classNames from 'classnames';

export default function Menu({ className, data, theme }) {
  const [currentMenu, setCurrentMenu] = useState({});
  let location = useLocation();
  const { width } = useWindowDimensions();
  const isCenter = width < 1680;

  useEffect(() => {
    document.body.style.overflow = '';
  }, []);

  useEffect(() => {
    setCurrentMenu({});
  }, [location]);

  const renderSubMenu = (menu) => {
    if (!isArray(menu.children)) return null;

    return (
      <div className="hover-sub-menu">
        {map(menu.children, (parent, index) => {
          return (
            <div key={index} className="hover-sub-menu-col">
              {/* 二级菜单不再跳转 */}
              {parent.link ? (
                <Link to={parent.link} className={classNames('hover-sub-menu-col-title', { hidden: !parent.name })}>
                  {parent.name}
                  <Right />
                </Link>
              ) : (
                <span className={classNames('hover-sub-menu-col-title', { hidden: !parent.name })}>{parent.name}</span>
              )}

              {map(parent.children, (c, cIndex) => {
                if (c.link) {
                  return (
                    <Link key={cIndex} to={c.link} className="hover-sub-menu-col-child">
                      {c.name}
                    </Link>
                  );
                } else {
                  return (
                    <span key={cIndex} onClick={c.onClick} className="hover-sub-menu-col-child">
                      {c.name}
                    </span>
                  );
                }
              })}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <React.Fragment>
      <ul className={classnames('comp-menu', className, { [theme]: theme })}>
        {data?.map((menu, index) => {
          const lessItem = menu.children && menu.children.length <= 6;
          return (
            <Popover
              style={{ height: '100%' }}
              // overlayStyle={{ maxWidth: lessItem ? '608px' : '96%' }}
              overlayClassName={classNames({ 'menu-overlay': !lessItem }, { 'menu-overlay-less': lessItem })}
              overlay={renderSubMenu(menu)}
              placement={!isCenter || lessItem ? 'bottom-start' : 'bottom'}
              key={index}
              getPopupContainer={() => (!isCenter || lessItem ? undefined : document.querySelector('#ceiling'))}
            >
              <li key={index} className={classnames({ hover: currentMenu.index === index })}>
                {menu.link ? (
                  /^https?:\/\//.test(menu.link) ? (
                    <a className="link" href={menu.link} target="_blank" rel="noopener noreferrer">
                      {menu.name}
                    </a>
                  ) : (
                    <Link className="link" to={menu.link}>
                      {menu.name}
                    </Link>
                  )
                ) : (
                  <span className="link">{menu.name}</span>
                )}
              </li>
            </Popover>
          );
        })}
      </ul>
    </React.Fragment>
  );
}
