import dayjs from 'dayjs'
import { oss } from 'scripts/utils';
import { Resource } from 'components/types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDetail } from './service';
import '../Activity/detail.scss';
import './detail.scss'
import Helmet from 'components/Helmet';
import FullRichText from 'components/FullRichText';
import React, { useContext } from 'react';
import Context from 'container';
import { createContactUsModal } from 'components/ContactUs';
import { createVideoModal } from 'components/Video/video';
import VideoIcon from 'components/Video/video-icon';

interface IDetail {
  id: number,
  activityIntroduction: string;
  titleContent: string;
  videoMaterial?: Resource;
  coverMaterial?: Resource;
  resourceDesc: string,
  createdAt: number
  jumpBO?: any
  isNeedContact: boolean
}

const Detail = () => {
  const context = useContext(Context);
  const {  lang } = context;
  const { id } = useParams<{ id: string }>();
  const [detail, setDetail] = useState<IDetail | null>(null);

  useEffect(() => {
    if (id) {
      getDetail(id).then((res) => {
        setDetail(res as unknown as IDetail);
      });
    }
  }, [id]);

  const onVideoClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const afterSuccess = () => {
      createVideoModal(detail?.videoMaterial?.files[0].url)
    }
    if (detail?.isNeedContact && detail?.jumpBO?.jumpContentType === 'FORM') {
      createContactUsModal({
        formId: detail.jumpBO?.formBO?.id,
        afterSuccess,
      })
    } else {
      afterSuccess()
    }
  }

  return (
    <React.Fragment>
      <Helmet title="端点-Terminus-数字化转型-商业软件" description={detail?.titleContent} />
      <div className="activity-detail">
        <div className="detail-title">{detail?.titleContent}</div>
        <div className="flex-h-center">
          <span className="detail-time">{dayjs(detail?.createdAt).format('YYYY年MM月DD日')}</span>
        </div>
        <div className="detail-banner">
          <div className="banner-main pl100">
            {detail?.videoMaterial?.files[0].url &&
            <div className='video-icon-wraper'>
              <VideoIcon
                active={false}
                onClick={onVideoClick}
              >
                {lang === 'cn' ? '立即播放' : 'Play'}
              </VideoIcon>
            </div>}
            <img src={oss(detail?.coverMaterial?.files[0].url, 800, 450)} alt="" className='media-img'/>
          </div>
        </div>
        <div className='content-detail'>
          <FullRichText className="rich-text" data={detail?.resourceDesc} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Detail;
