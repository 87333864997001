import { IconWrapper, ISvgIconProps } from '.';
<svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>;

export default IconWrapper('more', true, (props: ISvgIconProps) => (
  <svg width={props.size} height={props.size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="24" r="3" fill={props.colors[0]} />
    <circle cx="24" cy="24" r="3" fill={props.colors[0]} />
    <circle cx="36" cy="24" r="3" fill={props.colors[0]} />
  </svg>
));
