import { IconWrapper, ISvgIconProps } from '.';

export default IconWrapper('consult', true, (props: ISvgIconProps) => (
  <svg width={props.size} height={props.size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <polygon points="0,0 20,0 20,20 0,20 " />
    <path
      // stroke={props.colors[0]}
      fill={props.colors[0]}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
      strokeLinejoin={props.strokeLinejoin}
      d="M4.5,12.5l-3.7,2.9V2.5c0-0.5,0.4-0.8,0.8-0.8h12.5C14.6,1.7,15,2,15,2.5v10H4.5z M4,10.8h9.4V3.3  H2.5V12L4,10.8z M6.7,14.2h8.5l1.5,1.2V6.7h0.8c0.5,0,0.8,0.4,0.8,0.8v11.2l-3.7-2.9H7.5c-0.5,0-0.8-0.4-0.8-0.8V14.2z"
    />
  </svg>
));
