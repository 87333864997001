import React, { useState } from 'react';

function TextArea({ config, onFocus, onChange }, ref) {
  const [value, setValue] = useState(config.defaultValue);

  function onChangeHandle(event) {
    const val = event.target.value;

    setValue(val);

    if(config.name) {
      onChange(val);
    }
  }

  return (
    <textarea
      className="form-creator-textarea"
      name={config.name}
      placeholder={config.customProps?.placeholder || "请输入"}
      rows="3"
      value={value}
      onChange={onChangeHandle}
      onFocus={onFocus}
    />
  );
}

export default React.forwardRef(TextArea);
