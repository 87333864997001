import React from 'react';
import './radio.scss';

function Radio({ config, onFocus, onChange }, ref) {
  function onChangeHandle(event) {
    const { value: val } = event.target;

    if(config.name) {
      onChange(val);
    }
  }

  return (
    <div className="form-creator-radio-group">
      {config.customProps.options.map((option, index) => (
        <div className="form-creator-radio-group-row" key={index}>
          <label>
            <input
              name={config.name}
              type="radio"
              value={option.value}
              onChange={onChangeHandle}
              onFocus={onFocus}
            />
            <span>{option.label}</span>
          </label>
        </div>
      ))}
    </div>
  )
}

export default React.forwardRef(Radio)
