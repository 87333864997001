import './index.scss';
import Dropdown from 'components/IconWrapper/dropdown';
import RCPagination, { PaginationProps } from 'rc-pagination';
import cn from 'classnames';
import Context from 'container';
import { useContext, useState } from 'react';
import More from 'components/IconWrapper/more';
import DoubleRight from 'components/IconWrapper/double-right';

const JumpIcon = ({ type }: { type: 'next' | 'prev' }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div onMouseEnter={() => setIsActive(true)} onMouseLeave={() => setIsActive(false)}>
      {isActive ? <DoubleRight style={type === 'prev' ? { transform: 'rotate(180deg)' } : {}} /> : <More />}
    </div>
  );
};

const Pagination = (props: PaginationProps) => {
  const { className, ...restProps } = props;
  const ctx = useContext(Context);
  const { lang } = ctx;
  const locale = lang === 'cn' ? zhPageLocale : enPageLocale;

  return (
    <RCPagination
      locale={locale}
      prefixCls="common-pagination"
      className={cn(className)}
      prevIcon={<Dropdown style={{ transform: 'rotate(90deg)', cursor: 'inherit' }} />}
      nextIcon={<Dropdown style={{ transform: 'rotate(-90deg)' }} />}
      jumpNextIcon={<JumpIcon type="next" />}
      jumpPrevIcon={<JumpIcon type="prev" />}
      {...restProps}
    />
  );
};

const zhPageLocale = {
  items_per_page: '条/页',
  jump_to: '跳至',
  jump_to_confirm: '确定',
  page: '页',
  prev_page: '上一页',
  next_page: '下一页',
  prev_5: '向前 5 页',
  next_5: '向后 5 页',
  prev_3: '向前 3 页',
  next_3: '向后 3 页',
  page_size: '页码'
};
const enPageLocale = {
  items_per_page: 'Items/Page',
  jump_to: 'Jump to',
  jump_to_confirm: 'Confirm',
  page: 'Page',
  prev_page: 'Previous page',
  next_page: 'Next page',
  prev_5: 'Previous 5 page',
  next_5: 'Next 5 page',
  prev_3: 'Previous 3 page',
  next_3: 'Next 3 page',
  page_size: 'Page size'
};

export default Pagination;
