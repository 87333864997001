import { CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import './solution-carousel.scss';
import cn from 'classnames';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import Button from 'components/Button';
import Slider, { Settings } from 'react-slick';
import MainTitle from 'components/Common/main-title';
import { isDark } from 'scripts/color-extractor/utils';
import { Color } from 'scripts/color-extractor/types';
import { getColorByUrl } from 'scripts/color-extractor';
import { postSolutionList } from './services';
import Context from 'container';
import { SolutionsData } from '.';
import { oss } from 'scripts/utils';
import useLanguage from 'hooks/useLanguage';
import { fieldTranslation } from 'components/constants';

interface ITagCarouselProps {
  autoPlay?: boolean;
  autoPlaySpeed?: number;
  className?: string;
  style?: CSSProperties;
  height?: number;
}

const TagCarousel: React.FC<ITagCarouselProps> = (props) => {
  const { className, style, autoPlay = true, autoPlaySpeed = 8000, height } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [mainColor, setMainColor] = useState<Color | null>(null);
  const { currentSite } = useContext(Context);
  const [data, setData] = useState<SolutionsData[]>([]);
  const { i18n } = useLanguage();
  const themeMap = useRef(new Map());

  const sliderRef = useRef<Slider | null>(null);

  const settings: Settings = {
    fade: true,
    autoplay: data.length > 1 && autoPlay,
    autoplaySpeed: autoPlaySpeed,
    infinite: true,
    adaptiveHeight: false,
    arrows: false,
    beforeChange: (current, next) => {
      setActiveIndex(next);
    }
  };

  const handleGoto = (index: number) => {
    sliderRef.current?.slickGoTo(index);
  };

  const getTheme = () => {
    if (!mainColor) return undefined;
    return isDark({
      r: mainColor[0],
      g: mainColor[1],
      b: mainColor[2]
    })
      ? 'dark'
      : 'light';
  };

  const getLinearColor = () => {
    if (!mainColor) return undefined;
    const [r, g, b] = mainColor;
    return `linear-gradient(180deg, rgba(${r},${g},${b},125) 0%, rgba(${r},${g},${b},0) 100%)`;
  };

  const renderTabs = () => {
    return (
      <div className="tab-carousel-tabs" style={{ backgroundImage: getLinearColor() }}>
        <MainTitle theme={getTheme()} className="tabs-title" main={i18n(fieldTranslation, '行业解决方案')} />
        <div className="tabs-items">
          {map(data, (item, index) => {
            return (
              <div
                key={index}
                onClick={() => handleGoto(index)}
                className={cn('tabs-item', { 'tabs-item-active': activeIndex === index })}
              >
                {item.industry.name}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const icon = data[activeIndex]?.industry.indusIcon;
    if (icon) {
      const map = themeMap.current;
      if (map.has(icon)) {
        setMainColor(map.get(icon));
      } else {
        getColorByUrl(icon).then((res) => {
          setMainColor(res);
          map.set(icon, res);
        });
      }
    }
  }, [activeIndex, data]);

  useEffect(() => {
    if (currentSite.id) {
      postSolutionList({
        site: {
          id: currentSite.id
        }
      }).then((res) => {
        const result = res as unknown as SolutionsData[];
        setData(result);
      });
    }
  }, [currentSite.id]);

  if (!isArray(data) || data.length === 0) return null;
  return (
    <div className={cn('tab-carousel-wrap hidden-in-mobile', className)} style={style}>
      {renderTabs()}
      <Slider ref={sliderRef} {...settings} className="content">
        {map(data, (d, contentIndex) => {
          return <ContentItem index={contentIndex} height={height} key={contentIndex} item={d} />;
        })}
      </Slider>
    </div>
  );
};

interface IContentItemProps {
  item: SolutionsData;
  index: number;
  height?: number;
}

const ContentItem: React.FC<IContentItemProps> = (props) => {
  const { item: d, height, index } = props;
  const { i18n } = useLanguage();
  return (
    <div className="content-item" style={{ backgroundImage: `url(${d.industry.indusIcon})`, height: height }}>
      <div className="content-inner">
        <div>
          <div className="title">{d.industry.name}</div>
          <div className="desc line-clamp-3">{d.industry.desc}</div>
          {/* <div className="tips">{i18n(fieldTranslation, '客户案例')} :</div> */}
          <div className="case-wrapper">
            <div className="icon-wrapper">
              {map(d.examples?.slice(0, 3), (item, i) => {
                return (
                  <div key={i} className="icon">
                    <img src={oss(item.customerLogo, undefined, 50, 'lfit')} alt={item.customerName} />
                  </div>
                );
              })}
            </div>
            <Button href={`/solution#${index}`}>{i18n(fieldTranslation, '查看详情')}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagCarousel;
