import cn from 'classnames';
import { map } from 'lodash';
import './tips-list.scss';

interface ITipsListProps {
  theme: 'blue' | 'purple';
  direction: 'left' | 'right';
  data: React.ReactNode[];
  width?: number;
  className?: string;
  title?: React.ReactNode;
}

const TipsList = (props: ITipsListProps) => {
  const { direction, data = [], width, className, title, theme } = props;
  const color = theme === 'purple' ? '#5E4BE3' : '#1B83FF';
  const linear =
    theme === 'purple'
      ? 'linear-gradient(270deg, #9080FF 0%, #C3B7FF 100%)'
      : 'linear-gradient(270deg, #1881FF 0%, #81D4FF 100%)';

  return (
    <div className={cn(className)} style={{ width }}>
      {title ? (
        <div style={{ justifyContent: direction === 'left' ? 'flex-start' : 'flex-end' }} className="tips-list-title">
          {title}
        </div>
      ) : null}
      <div
        style={{ justifyContent: direction === 'left' ? 'flex-start' : 'flex-end' }}
        className={cn('tips-list-wrapper')}
      >
        {map(data, (item, index) => {
          const isActive = index <= 1;
          return (
            <div
              style={{
                color: isActive ? undefined : color,
                borderColor: isActive ? undefined : color,
                backgroundImage: isActive ? linear : undefined
              }}
              className={cn(
                'tips-list-item',
                { 'item-active': isActive },
                {
                  'border-left': direction === 'left'
                },
                {
                  'border-right': direction === 'right'
                }
              )}
              key={index}
            >
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TipsList;
