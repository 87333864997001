import BrandWall from 'components/BrandWall';
import MainTitle from 'components/Common/main-title';
import { fieldTranslation } from 'components/constants';
import EarthMap from 'components/EarthMap';
import useLanguage from 'hooks/useLanguage';
import usePlatform from 'hooks/usePlatform';
import { useMemo } from 'react';
import './index.scss';
import TipsList from './tips-list';

interface IExample {
  customerName: string;
  customerLogo: string;
}

interface ICaseShowProps {
  examples: IExample;
}

const CaseShow: React.FC<ICaseShowProps> = (props) => {
  const { examples } = props;
  const { i18n } = useLanguage();
  const { isPc } = usePlatform();

  const outerData = useMemo(
    () => [
      <span>
        <span className="label-strong">1 </span>
        {i18n(fieldTranslation, '个新加坡子公司')}
      </span>,
      <span>
        <span className="label-strong">10+</span>
        {i18n(fieldTranslation, '个国家和地区业务')}
      </span>,
      i18n(fieldTranslation, '本土打造核心产品及合作生态'),
      i18n(fieldTranslation, '聚焦本地业务交付及创新需求导入')
    ],
    [i18n]
  );

  const innerData = useMemo(
    () => [
      <span>
        <span className="label-strong">1 </span>
        {i18n(fieldTranslation, '个研发基地')}
      </span>,
      <span>
        <span className="label-strong">5 </span>
        {i18n(fieldTranslation, '个运营与服务机构')}
      </span>,
      i18n(fieldTranslation, '从企业技术平台到核心业务产品的完整支撑'),
      i18n(fieldTranslation, '行业最佳实践及专业服务能力')
    ],
    [i18n]
  );

  const outerHeader = useMemo(() => {
    return (
      <div className="tips-header">
        {/* <img src="/static/images/outer.png" alt={i18n(fieldTranslation, '国外')} /> */}
        <div>{i18n(fieldTranslation, '国外')}</div>
      </div>
    );
  }, [i18n]);

  const innerHeader = useMemo(() => {
    return (
      <div className="tips-header">
        {/* <img src="/static/images/inner.png" alt={i18n(fieldTranslation, '国内')} /> */}
        <div>{i18n(fieldTranslation, '国内')}</div>
      </div>
    );
  }, [i18n]);

  return (
    <div className="case-show">
      <div className="case-wrapper">
        <MainTitle
          className="desc-wrapper"
          main={i18n(fieldTranslation, '众多头部企业客户信赖')}
          sub={i18n(fieldTranslation, '为企业的数字化建设护航')}
        />
      </div>
      <div className="label">
        <div className="label-wrapper">
          <>
            <TipsList
              className="hidden-in-mobile"
              theme="blue"
              width={373}
              title={outerHeader}
              direction="right"
              data={outerData}
            />
            <TipsList
              className="hidden-in-mobile"
              theme="purple"
              width={373}
              title={innerHeader}
              direction="left"
              data={innerData}
            />
          </>
          <>
            <TipsList
              className="hidden-in-pc"
              theme="purple"
              width={373}
              title={innerHeader}
              direction="left"
              data={innerData}
            />
            <TipsList
              className="hidden-in-pc"
              theme="blue"
              width={373}
              title={outerHeader}
              direction="left"
              data={outerData}
            />
          </>
        </div>
      </div>
      <div className="static-earth hidden-in-pc">
        <div></div>
      </div>
      <div className="earth-wrapper hidden-in-mobile">
        <EarthMap />
      </div>

      <div className="wall-wrapper">
        <BrandWall data={examples} />
      </div>
    </div>
  );
};

export default CaseShow;
