/*
 * @LastEditors: Necfol
 * @Date: 2024-03-28 13:54:14
 * @LastEditTime: 2024-03-29 17:11:55
 * @FilePath: /emp-official-ui/src/pages/Go1688/index.tsx
 */
import React, { useState, useEffect, useContext } from 'react';
import Helmet from 'components/Helmet';
import './index.scss';


function GoPage() {


  return (
    <React.Fragment>
      <Helmet
        title="杭州端点网络科技有限公司-新商业软件提供商-数字化转型-全链路解决方案"
        description=''
      />
      <div key="home-page-content">
        <div
          className='go-banner'
        >
          <img className='go-banner-background' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/d06e75c9-c2b1-46f2-b26e-f31f6720b0c8.png" alt="" draggable="false" />
          <div className='go-banner-inner'>
            <img className='go-banner-inner-img1' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/c86549c8-f2c1-4cf8-b596-546a3db9019a.png" alt="" draggable="false" />
            <img className='go-banner-inner-img2' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/f68f7bfd-29e9-48f1-bc0e-991b712f526f.png" alt="" draggable="false" />
            <div className="go-banner-left">
              <a href="/go1688/purchaser">
                <img className='go-banner-left-img1' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/370d5ea3-f52b-4a4f-9f36-b955716c03f4.png" alt="" draggable="false" />
              </a>
              <a href="/go1688/supplier">
                <img className='go-banner-left-img2' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/68d634bc-6dc1-4b50-991e-636a8769614d.png" alt="" draggable="false" />
              </a>
            </div>
          </div>

        </div>
        <div
          className='go-product'
        >
          <img className='go-product-background' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/d434ac38-fdf9-4a03-973b-6097133a1645.png" alt="" draggable="false" />
          <div className="go-product-inner">
            <img className='go-product-title' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/9538acf6-069e-4dc5-a911-63b4bf158a5a.png" alt="" draggable="false" />
            <img className='go-product-content' src="https://terminus-emp.oss-cn-hangzhou.aliyuncs.com/trantor/attachments/90e0eb55-e889-4b27-958e-fc29dff5dca4.png" alt="" draggable="false" />
          </div>

        </div>
      </div>
    </React.Fragment>
  );
}

export default GoPage;
