import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { isNil } from 'lodash';
import './index.scss';
import Dropdown from 'components/IconWrapper/dropdown';
import Line from 'components/IconWrapper/line';
import Popover from 'components/Pophover';

export default function DropdownList({ className, options = [], value: defaultValue, onChange, id }) {
  const [value, setValue] = useState(defaultValue);
  const mainRef = useRef();

  let label;
  if (!isNil(value) && options instanceof Array) {
    const [option] = options.filter((option) => option.value === value);
    label = option?.label;
  }

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  function onChangeHandle(_value) {
    setValue(_value);
    onChange instanceof Function && onChange(_value);
  }

  const overlay = (
    <ul>
      {options instanceof Array &&
        options.map((option, index) => (
          <li
            key={index}
            className={classnames({ active: value === option.value })}
            onClick={() => onChangeHandle(option.value)}
            value={option.value}
          >
            {option.icon ? <img src={option.icon} alt={option.label} /> : null}
            {option.label}
          </li>
        ))}
    </ul>
  );

  return (
    <Popover
      overlay={overlay}
      overlayClassName="comp-dropdown-overlay"
      className={classnames('comp-dropdown-list', className)}
      id={id}
      placement="bottom"
      getPopupContainer={() => mainRef.current}
    >
      <div className="dropdown-label">
        <Line size={20} className="dropdown-label-line" />
        <span ref={mainRef}>{label || '请选择'}</span>
        <Dropdown />
      </div>
    </Popover>
  );
}
