import { earthRoutes } from 'components/CaseShow/routes';
import { useEffect } from 'react';
import './index.scss';
import { isSSR } from 'scripts/utils';

const init = async () => {
  if (isSSR) return null;
  await import('echarts-gl');
  const echarts = await import('echarts/core');
  const chartDom = document.getElementById('earth-map');
  const myChart = chartDom && echarts.init(chartDom);
  const routes = earthRoutes;
  const option = {
    backgroundColor: 'transparent',
    globe: {
      baseTexture: '/static/images/water_2k.png',
      shading: 'color',
      atmosphere: {
        show: false
      },
      viewControl: {
        distance: 200,
        zoomSensitivity: 0,
        autoRotateAfterStill: 1,
        autoRotateSpeed: 6,
        targetCoord: [120.434453, 10.229503]
      }
    },
    series: [
      {
        type: 'lines3D',
        coordinateSystem: 'globe',
        blendMode: 'source-over',
        effect: {
          show: true,
          trailWidth: 3,
          trailOpacity: 1,
          trailLength: 0.15,
          trailColor: '#83B5EE',
          constantSpeed: 6
        },
        lineStyle: {
          width: 1,
          color: '#83B5EE',
          opacity: 0.3
        },
        data: routes
      }
    ]
  };
  option && myChart && myChart.setOption(option);
};

const EarthMap = () => {
  useEffect(() => {
    init();
  }, []);

  return <main className="earth-map" id="earth-map"></main>;
};

export default EarthMap;
