import cn from 'classnames';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';

interface IAffixProps {
  children?: React.ReactNode;
  className?: string;
  target?: HTMLElement;
  offsetTop?: number;
}

const events = ['resize', 'scroll', 'touchstart', 'touchmove', 'touchend', 'pageshow', 'load'];

const Affix = (props: IAffixProps) => {
  const { className, children, target, offsetTop = 0, ...restProps } = props;
  const placeholderRef = useRef<HTMLDivElement>(null);
  const fixRef = useRef<HTMLDivElement>(null);

  const [affixStyle, setAffixStyle] = useState<CSSProperties>();
  const [placeholderStyle, setPlaceholderStyle] = useState<CSSProperties>();

  useEffect(() => {
    if (fixRef.current) {
      setPlaceholderStyle({
        width: fixRef.current.clientWidth + 'px',
        height: fixRef.current.clientHeight + 'px'
      });
    }
  }, []);

  useEffect(() => {
    const trigger = () => {
      if (!placeholderRef.current) {
        // 处于未固定状态
        const rect = fixRef.current?.getBoundingClientRect();
        if (rect?.top && rect?.top < offsetTop) {
          setAffixStyle({
            position: 'fixed',
            width: rect.width,
            height: rect.height,
            top: offsetTop + 'px',
            zIndex: 1
          });
        }
      } else {
        const rect = placeholderRef.current?.getBoundingClientRect();
        const targetEle = document.getElementById('affix-id')
        const docEle = document.getElementsByTagName('html')[0]
        const targetHeight = targetEle?.clientHeight || 0
        const docScrollHeight = docEle.scrollHeight
        const docScrollTop = docEle.scrollTop
        const docHeight = docEle.clientHeight
        // 底部高度
        const BTN_HEIGHT = 459
        // margin
        const MT_HEIGHT = 20 + offsetTop
        // 剩余未滚动高度 （当前屏幕 + 底部剩余未展示）
        const scrollRest = docScrollHeight - docScrollTop

        const TARGET_HEIGHT = scrollRest - BTN_HEIGHT - MT_HEIGHT
        // 到达底部 BTN_HEIGHT 处
        if (TARGET_HEIGHT <= targetHeight) {
          setAffixStyle({
            position: 'fixed',
            width: rect.width,
            height: rect.height,
            // 底部高度 - 未展示高度 = anchor 离bottom距离
            bottom: (BTN_HEIGHT - (scrollRest - docHeight))  + 'px',
            zIndex: 1
          });
        }

        // 还原
        if (TARGET_HEIGHT > targetHeight) {
          setAffixStyle({
            position: 'fixed',
            width: rect.width,
            height: rect.height,
            top: offsetTop + 'px',
            zIndex: 1
          });
        }
        if (rect?.top && rect?.top > offsetTop) {
          setAffixStyle(undefined);
        }
      }
    };
    events.forEach((ev) => {
      const container = target ?? window;
      container.addEventListener(ev, trigger);
    });

    return () => {
      events.forEach((ev) => {
        const container = target ?? window;
        container.removeEventListener(ev, trigger);
      });
    };
  }, [target]);

  return (
    <div {...restProps}>
      {affixStyle && <div ref={placeholderRef} style={placeholderStyle} aria-hidden="true" />}
      <div id="affix-id" className={cn(className)} ref={fixRef} style={{...affixStyle, height: 'fit-content'}}>
        {children}
      </div>
    </div>
  );
};

export default Affix;
