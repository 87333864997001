import cn from 'classnames';
import { HTMLAttributes } from 'react';
import './index.scss';

const Divider = (props: HTMLAttributes<HTMLDivElement>) => {
  const { className, ...restProps } = props;
  return <div className={cn('common-divider', className)} {...restProps}></div>;
};

export default Divider;
