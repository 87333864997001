export const defaultLanguage = 'cn';

export const buttonThemes = {
  theme: '#0366dd'
  // backgroundColor: '#0366dd'
  // backgroundImage: 'linear-gradient(138deg, #add7e8 0%, #3c9edb 100%)',
};

export const linkIcons = {
  DOCUMENT: 'download',
  VIDEO: 'video'
};

export enum MomentUnitEnum {
  Day = 'day'
}

export const dateFormatter = 'YYYY年MM月DD日';
export const enDateFormatter = 'YYYY/MM/DD';

export const EMAIL_REGEXP = /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/;
export const PHONE_REGEXP = /^1[3456789]\d{9}$/;

export const BANNER_TYPE = {
  ACTIVITY: 'ACTIVITY',
  NO_END_ACTIVITY: 'NO_END_ACTIVITY',
  PRODUCT: 'PRODUCT',
  NEWS: 'NEWS',
  CAROUSEL: 'CAROUSEL',
  BUSINESS_CONSULTING: 'BUSINESS_CONSULTING',
  SOLUTION: 'SOLUTION',
  DETAIL_HEADER: 'DETAIL_HEADER',
  CASE_DETAIL: 'CASE_DETAIL',
  PLATE_DETAIL: 'PLATE_DETAIL'
};

export type TitleType =
  | 'SERVICE_AND_SUPPORT'
  | 'CASES'
  | 'NEWS'
  | 'BRAND_ACTIVITIES'
  | 'Corporate publication'
  | 'JOINS'
  | 'INTRODUCE'
  | 'MULTIMEDIA_RESOURCE'

export const fieldTranslation = 'FIELD_TRANSLATION';
