import { useEffect, useState } from 'react';
import ContactModal, { IContactModalProps } from './contact-modal';
import './index.scss';
import { getFormInfo } from 'services';
import Form from 'components/Form';
import Toast from 'components/Toast';
import { Resource } from 'components/types';
import ReactDOM from 'react-dom';
import { STORAGE_LANG } from 'App';

interface ContactData {
  name: string;
  description: string;
  id: number;
  formFields: unknown[];
}

interface IModalProps {
  formId?: string;
  attachment?: Resource;
  afterSuccess?: () => void
}
const ContactUs = (props: IContactModalProps & IModalProps) => {
  const { formId, attachment, open, onClose, afterSuccess, ...restProps } = props;
  const [data, setData] = useState<null | ContactData>(null);

  const onSubmitSuccess = () => {
    if (afterSuccess) {
      afterSuccess()
      return
    }
    const url = attachment?.files?.[0]?.url;
    if (!url) {
      return
    }
    if (/(iphone|ipad|ipod|ios)/i.test(navigator.userAgent.toLowerCase())) {
      Toast.info('文件较大，即将加载', 3000);
      setTimeout(() => {
        window.location.href = url
      }, 300)
      return
    } else {
      const a = document.createElement('a');
      a.setAttribute('href', url);
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  useEffect(() => {
    (async () => {
      const lang = localStorage.getItem(STORAGE_LANG);
      const is1688 = typeof window !== 'undefined' && window?.location?.pathname.startsWith('/go1688')
      const id = formId ? formId : lang === 'cn' ? (is1688 ? 14008 : 1) : 4001;
      if (id) {
        const result = await getFormInfo(id);
        setData(result as unknown as ContactData);
      }
    })();
  }, [formId]);

  const title = (
    <div className="contact-header">
      <div className="contact-title">{data?.name}</div>
      <div className="contact-desc">{data?.description}</div>
    </div>
  );

  const content = (
    <Form formId={data?.id} onClose={onClose} formConfig={data?.formFields} onSuccess={onSubmitSuccess} />
  );
  return (
    <ContactModal onClose={onClose} destroyOnClose={true} open={open} title={title} content={content} {...restProps} />
  );
};

export default ContactUs;

const ROOT_ID = 'contact-us-modal';

export const createContactUsModal = (props?: IModalProps) => {
  const Comp = () => {
    const [open, setOpen] = useState(true);
    return <ContactUs open={open} onClose={() => setOpen(false)} {...props} />;
  };

  const contactUsRoot = document.getElementById(ROOT_ID);
  if (!contactUsRoot) {
    const root = document.createElement('div');
    root.id = ROOT_ID;
    document.body.appendChild(root);
  }

  ReactDOM.render(<Comp />, document.getElementById(ROOT_ID));
};
