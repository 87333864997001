import React, { useReducer } from 'react';

function reducer(state: any, action: any) {
  return { ...state, ...action };
}

export function useContainer() {
  const [state, dispatch] = useReducer(reducer, {});

  return {
    state,
    dispatch,
  };
}

export default React.createContext({} as any);
