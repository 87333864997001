import cn from 'classnames';
import { CSSProperties, useMemo } from 'react';
import './index.scss';

interface IMainTitleProps {
  main: string;
  mainStyle?: CSSProperties;
  subStyle?: CSSProperties;
  theme?: 'dark' | 'light';
  size?: 'default' | 'xl' | 'lg' | 'md' | 'sm';
  position?: 'left' | 'center' | 'right';
  sub?: string;
  className?: string;
  id?: string;
}

const sizeClassMap = {
  xl: ' main-title-xl',
  lg: ' main-title-lg',
  md: ' main-title-md',
  sm: ' main-title-sm',
  default: ' main-title'
};

const positionMap = {
  left: ' left',
  center: ' center',
  right: ' right'
};

const themeMap = {
  dark: ' dark',
  light: ''
};

const MainTitle = (props: IMainTitleProps) => {
  const {
    main,
    sub,
    mainStyle,
    subStyle,
    size = 'default',
    className,
    position = 'center',
    theme = 'light',
    ...restProps
  } = props;

  const titleClass = useMemo(() => {
    let _class = '';
    _class += sizeClassMap[size];
    return _class;
  }, [size]);

  const wrapperClass = useMemo(() => {
    let _class = '';
    _class += positionMap[position];
    _class += themeMap[theme];
    return _class;
  }, [position, theme]);

  return (
    <div className={cn('main-title-wrapper', wrapperClass, className)} {...restProps}>
      <div style={mainStyle} className={cn(titleClass)}>
        {main}
      </div>
      {sub ? (
        <div style={subStyle} className={cn('main-sub-title', { dark: theme === 'dark' })}>
          {sub}
        </div>
      ) : null}
    </div>
  );
};

export default MainTitle;
