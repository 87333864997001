import classnames from 'classnames';
// import ScrollAnimation from 'scripts/scroll-animation';
import Button from 'components/Button';
import './render.scss';
import { getBannerOperationExtraAttr, isValid } from 'scripts/utils';
import MainTitle from 'components/Common/main-title';
import VideoIcon from 'components/Video/video-icon';
import { createVideoModal } from 'components/Video/video';
import FileDown from 'components/IconWrapper/file-down';
import ForwardPlay from 'components/IconWrapper/forward-play';
import { oss } from 'scripts/utils';
import { fieldTranslation } from 'components/constants';

export const renderDefault = (props) => {
  const { styles, primaryColor, media, position, theme, title, subheading, legend, operation } = props;
  return (
    <>
      <div
        className={classnames('img-background')}
        style={{
          ...styles,
          backgroundColor: primaryColor,
          backgroundImage: `url(${oss(media, undefined,  866)})`
        }}
      >
        <div
          className={classnames('comp-content', 'banner-content', 'default-banner-content', {
            [position]: position,
            [theme]: theme
          })}
        >
          {/* <ScrollAnimation animateOnce animateIn="fadeInUp"> */}
            <div className="banner-title-wrapper">
              <div className="banner-title">{title}</div>
              {isValid(subheading) ? <div className="banner-sub-title">{subheading}</div> : null}
            </div>
          {/* </ScrollAnimation> */}
          {/* <ScrollAnimation animateOnce delay={100} animateIn="fadeInUp"> */}
            <div className="banner-legend" dangerouslySetInnerHTML={{ __html: legend }} />
          {/* </ScrollAnimation> */}
          {operation instanceof Array ? (
            <div className="pc-banner-operation-wrapper"> {/* animateOnce delay={200} animateIn="fadeInUp" */}
              <div className="banner-operation">
                {operation.map((opt, index) => {
                  if (opt.text) {
                    return (
                      <Button key={`banner-operation-${index}`} {...opt.extraAttr} fill={opt.theme}>
                        {opt.text}
                      </Button>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {operation instanceof Array ? (
        <div className="mobile-banner-operation-wrapper banner-operation">
          {operation.map((opt, index) => {
            if (opt.text) {
              return (
                <Button key={`banner-operation-${index}`} {...opt.extraAttr} fill={opt.theme}>
                  {opt.text}
                </Button>
              );
            }
            return null;
          })}
        </div>
      ) : null}
    </>
  );
};

export const RenderProduction = (props) => {
  const { styles, media, theme, title, legend } = props;
  return (
    <div
      className={classnames('comp-root', 'product-banner-wrapper')}
      // data-original={covered ? undefined : media}
      style={{
        ...styles,
        backgroundColor: '#fff'
      }}
    >
      <div
        className={classnames('comp-content banner-custom-content', {
          [theme]: theme
        })}
      >
        <MainTitle theme={theme} main={title} sub={legend} />
        <img className="production-image" src={media} alt="production" />
      </div>
    </div>
  );
};

export const renderDetailHeader = (props) => {
  const { styles, theme, title, legend, src } = props;
  return (
    <div
      className={classnames('comp-root', 'detail-header-wrapper')}
      style={{
        ...styles,
        backgroundColor: '#fff',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: `url(${oss(src, undefined,  866)})`
      }}
    >
      <div
        className={classnames('title-wrapper', {
          [theme]: theme
        })}
      >
        <MainTitle theme={theme} className="title-content" size="lg" main={title} sub={legend} />
      </div>
    </div>
  );
};

export const renderCaseDetail = (props) => {
  const { styles, media, theme, title, video, i18n } = props;
  return (
    <div
      className={classnames('comp-root', 'banner-case-detail-wrapper')}
      style={{
        ...styles,
        backgroundImage: `url(${oss(media, undefined,  866)})`
      }}
    >
      <div
        className={classnames('title-wrapper', {
          [theme]: theme
        })}
      >
        <div className="banner-case-detail-title">{title}</div>
        {video ? (
          <>
            <div className="flex-center">
              <VideoIcon className="case-video-icon" onClick={() => createVideoModal(video)} />
            </div>
            {/* <div className="flex-center play-text hidden-in-mobile">{i18n(fieldTranslation, '视频播放')} </div> */}
          </>
        ) : null}
      </div>
    </div>
  );
};

export const renderPlateDetail = (props) => {
  const { styles, media, theme, title, legend, operation } = props;
  const { jumpBO, demonstration } = operation ?? {};
  return (
    <div
      className={classnames('comp-root', 'plate-detail-wrapper')}
      // data-original={covered ? undefined : media}
      style={{
        ...styles,
        backgroundColor: '#fff',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: `url(${oss(media, undefined,  866)})`
      }}
    >
      <div
        className={classnames('title-wrapper', {
          [theme]: theme
        })}
      >
        <MainTitle theme={theme} className="title-content" size="xl" main={title} sub={legend} />
      </div>
      <div className="plate-footer">
        {jumpBO ? (
          <Button
            target="_blank"
            {...getBannerOperationExtraAttr({ jumpBO })}
            // href={jumpBO.attachment?.files?.[0].url}
            className="footer-button"
            prefix={<FileDown size={16} fill={'#fff'} />}
            suffix={false}
          >
            {jumpBO?.buttonTitle}
          </Button>
        ) : null}
        {demonstration ? (
          <Button
            type="white"
            {...getBannerOperationExtraAttr({ jumpBO: demonstration })}
            className="footer-button"
            prefix={<ForwardPlay size={16} fill={'#9196AA'} />}
            suffix={false}
          >
            {demonstration.buttonTitle}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export const RenderSolutionDetail = (props) => {
  const { styles, media, theme, title, legend, operation } = props;
  const { jumpBO, industry } = operation;
  return (
    <div
      className={classnames('comp-root', 'solution-detail-wrapper')}
      style={{
        ...styles,
        backgroundColor: '#fff',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: `url(${oss(media, undefined,  866)})`
      }}
    >
      <div
        className={classnames('title-wrapper', {
          [theme]: theme
        })}
      >
        <MainTitle theme={theme} className="title-content" size="xl" main={title} sub={legend} />
      </div>
      <div className="solution-footer">
        <Button
          {...getBannerOperationExtraAttr({ jumpBO })}
          className={` ${getBannerOperationExtraAttr({ jumpBO })?.className || ''} ${getBannerOperationExtraAttr({ jumpBO })?.type === 'video' ? 'btn-video' : 'btn-main'} footer-button`
          }
        >{jumpBO?.buttonTitle}</Button>
        {industry ? (
          <Button
            type="default"
            className="form footer-button"
            prefix={<ForwardPlay size={14} fill={'#fff5'} />}
            suffix={false}
            fill="#fff"
            style={{ borderColor: '#fff5' }}
            {...getBannerOperationExtraAttr({ jumpBO: industry })}
          >
            {industry.buttonTitle}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
