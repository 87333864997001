import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Context from 'container';
import useBannerConfig from 'hooks/useBannerConfig';
import Helmet from 'components/Helmet';
import Intro from 'components/About/intro';
import News from 'components/About/news';
import { getArticleList } from 'services';

function AboutUsPage() {
  const { type } = useParams();
  const context = useContext(Context);
  const { lang } = context;
  const [activeTabKey, setActiveTabKey] = useState(type || lang === 'cn' ? 'intro' : 'intro');
  const banner = useBannerConfig();

  useEffect(() => {
    if (type) {
      setActiveTabKey(type);
    }
  }, [type]);

  return (
    <React.Fragment>
      <Helmet title="端点-Terminus-数字化转型-商业软件" description={banner.legend} />
      {/* TODO: 临时 */}
      {activeTabKey === 'news' ? <News /> : null}
      {activeTabKey === 'intro' ? <Intro /> : null}
    </React.Fragment>
  );
}

AboutUsPage.getInitialProps = async ({ context, params, type }) => {
  const { currentSite = {} } = context;
  return await getArticleList({
    type: type || 'news',
    ...params,
    site: currentSite.id
  });
};

export default AboutUsPage;
