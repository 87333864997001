import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import './index.scss';

export interface IOverlayProps {
  open?: boolean;
  destroy?: boolean;
  content?: React.ReactNode;
  maskClass?: string;
}

const ROOT_ID = 'overlay-root';

const Overlay = (props: IOverlayProps) => {
  const { open = false, destroy = true, content, maskClass } = props;
  const dom = (
    <div className={cn('comp-overlay-mask ', { 'comp-overlay-none': open === false }, maskClass)}>{content}</div>
  );

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      if (open) {
        const scrollBarWidth = window.innerWidth - body.clientWidth;
        body.className += ' overlay-open';
        body.style.marginRight = scrollBarWidth + 'px';
      } else {
        body.className = body.className.replaceAll(' overlay-open', '');
        body.style.marginRight = 0 + 'px';
      }
    }
  }, [open]);

  try {
    const overlayRoot = document.getElementById(ROOT_ID);
    if (!overlayRoot) {
      const root = document.createElement('div');
      root.id = ROOT_ID;
      document.body.appendChild(root);
    }
  } catch (error) {}

  if (open === false && destroy) return null;

  return ReactDOM.createPortal(dom, document.getElementById(ROOT_ID)!);
};

export default Overlay;
