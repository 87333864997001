import { IconWrapper, ISvgIconProps } from '.';

export default IconWrapper('set-top', true, (props: ISvgIconProps) => (
  <svg width={props.size} height={props.size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <polygon points="0,0 16,0 16,16 0,16 " />
    <path
      fill={props.colors[0]}
      stroke={props.colors[0]}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
      strokeLinejoin={props.strokeLinejoin}
      d="M2,2h12v1.3H2V2z M8.7,7.3V14H7.3V7.3h-2L8,4.7l2.7,2.7H8.7z"
    />
  </svg>
));
