import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useInteractions,
  FloatingFocusManager,
  Placement,
  useTransitionStyles,
  useClick,
  useDismiss
} from '@floating-ui/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import './index.scss';

interface IPopoverProps {
  children: React.ReactNode;
  overlay: React.ReactNode;
  trigger?: ('click' | 'hover')[];
  overlayClassName?: string;
  overlayStyle?: React.CSSProperties;
  className?: string;
  style?: React.CSSProperties;
  open?: boolean;
  offset?: number;
  placement?: Placement;
  getPopupContainer?: () => HTMLElement | undefined;
}

const Popover: React.FC<IPopoverProps> = (props) => {
  const {
    children,
    overlay,
    overlayClassName,
    overlayStyle,
    className,
    style,
    offset: _offset = 8,
    open: outOpen,
    placement: _placement,
    getPopupContainer,
    trigger = ['hover'],
    ...restProps
  } = props;
  const [_open, _setOpen] = useState(false);
  const open = outOpen ?? _open;

  const { x, y, reference, floating, strategy, context, positionReference } = useFloating({
    open,
    onOpenChange: _setOpen,
    middleware: [offset(_offset), flip(), shift()],
    whileElementsMounted: autoUpdate,
    strategy: 'absolute',
    placement: _placement
  });

  const { isMounted, styles: transitionStyle } = useTransitionStyles(context, {
    initial: () => ({
      opacity: 0
    })
  });

  const hover = useHover(context, { delay: { open: 100, close: 200 }, enabled: trigger.includes('hover') });

  const click = useClick(context, { enabled: trigger.includes('click') });
  const dismiss = useDismiss(context, {
    escapeKey: false,
    outsidePressEvent: 'click'
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([click, hover, dismiss]);

  useEffect(() => {
    if (getPopupContainer?.()) {
      positionReference(getPopupContainer()!);
    }
  }, [getPopupContainer, positionReference]);

  return (
    <span
      className={classNames('popover', className)}
      style={style}
      ref={reference}
      {...getReferenceProps()}
      {...restProps}
    >
      {children}
      {isMounted && open && overlay && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={floating}
            style={Object.assign(
              {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                width: 'max-content'
              },
              transitionStyle,
              overlayStyle
            )}
            className={classNames('popover-content', overlayClassName)}
            {...getFloatingProps()}
          >
            {overlay}
          </div>
        </FloatingFocusManager>
      )}
    </span>
  );
};

export default Popover;
