import map from 'lodash/map';
import cn from 'classnames';
import './index.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import Affix from 'components/Affix';

export interface AnchorItem {
  id: string;
  title: string;
  children?: AnchorItem[];
}

interface MapItem {
  id: string;
  title: string;
  parentId: string | null;
  level: number;
}

interface IAnchor {
  items: AnchorItem[];
  affixOffset?: number;
  affix?: boolean;
  target?: HTMLElement;
  className?: string;
  itemClassName?: string | ((item: MapItem) => string);
  contentType?: 'list' | 'tabs';
}

const events = ['resize', 'scroll', 'touchstart', 'touchmove', 'touchend'];
const LEFT_OFFSET = 8;

const mapItems = (items: AnchorItem[]) => {
  const mapItems: MapItem[] = [];
  const travel = (parent: null | AnchorItem, items: AnchorItem[], level: number) => {
    for (let item, i = 0; i < items.length; i++) {
      item = items[i];
      const mapItem: MapItem = {
        id: `${item.id}`,
        title: item.title,
        parentId: parent ? parent.id : null,
        level
      };
      mapItems.push(mapItem);
      if (item.children) {
        travel(item, item.children, level + 1);
      }
    }
  };
  travel(null, items, 0);
  return mapItems;
};

const AnchorList = (props: IAnchor) => {
  const {
    items,
    className,
    target,
    affix = false,
    contentType = 'list',
    affixOffset = 10,
    itemClassName,
    ...restProps
  } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [activeId, setActiveId] = useState<string | null>(items[0]?.id);

  const resultItems: MapItem[] = useMemo(() => mapItems(items), [items]);
  const handleClick = (event: React.ChangeEvent, id: string) => {
    setActiveId(id);

    const el = event.currentTarget;
    setTimeout(() => {
      el.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
    }, 100)
  };

  useEffect(() => {
    const wrapper = target ?? document.body;
    const triggerFn = () => {
      const selector = map(resultItems, (item) => `#${item.id}`).join(',');
      if (!selector) return;
      const allTitle = wrapper?.querySelectorAll(selector);
      for (let i = 0; i < allTitle.length; i++) {
        const title = allTitle[i] as HTMLHeadElement;
        const rectTop = title.getClientRects()[0]?.top;
        if (rectTop >= 0) {
          setActiveId(title.id);

          const els = document.querySelectorAll(`#tab__${title.id}`);
          setTimeout(() => {
            els.forEach(el => el?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' }));
          }, 100)

          break;
        }
      }
    };

    events.forEach((ev) => {
      const trigger = target ?? window;
      trigger.addEventListener(ev, triggerFn);
    });
    triggerFn();

    return () => {
      events.forEach((ev) => {
        const trigger = target ?? window;
        trigger.removeEventListener(ev, triggerFn);
      });
    };
  }, [resultItems, target]);

  const listContent = (
    <div ref={wrapperRef} className={cn('anchor-wrapper', className)} {...restProps}>
      {map(resultItems, (item) => {
        const cls = typeof itemClassName === 'function' ? itemClassName(item) : itemClassName;
        return (
          <div
            id={`tab__${item.id}`}
            onClick={event => handleClick(event, item.id)}
            className={cn({ active: item.id === activeId })}
            key={item.id}
          >
            <a className={cn(cls)} style={{ paddingLeft: LEFT_OFFSET * item.level }} href={`#${item.id}`}>
              {item.title}
            </a>
          </div>
        );
      })}
    </div>
  );

  const tabsList = (
    <div className="mobile-tabs-wrapper">
      {map(items, (item) => {
        return (
          <a
            key={item.id}
            id={`tab__${item.id}`}
            onClick={event => handleClick(event, item.id)}
            className={cn({ active: item.id === activeId })}
            href={`#${item.id}`}
          >
            {item.title}
          </a>
        );
      })}
    </div>
  );

  const content = contentType === 'list' ? listContent : tabsList;

  return affix ? <Affix offsetTop={affixOffset}>{content}</Affix> : content;
};

export default AnchorList;
