import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Helmet from 'components/Helmet';
import ArticleDetail from 'components/ArticleDetail';
import { getArticleDetail } from 'services';

function ArticleDetailPage({ initialData }) {
  const params = useParams() || {};
  const [data, setData] = useState(initialData || []);
  const { type } = params;
  useEffect(() => {
    (async () => {
      if (isEmpty(initialData)) {
        const result = await getArticleDetail(params);
        setData(result);
      }
    })();
  }, [params]);

  return (
    <React.Fragment>
      <Helmet
        title="端点-Terminus-数字化转型-商业软件"
        // description={banner.legend}
      />
      <ArticleDetail type={type} detail={data} />
    </React.Fragment>
  );
}

export default ArticleDetailPage;
