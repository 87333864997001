import axios from 'axios';
import { NewsData } from './news';

export function getCompanyIntro(pampas: { site: { id: number } | { id: number } }) {
  return axios.post('/api/internal/introduce', pampas);
}

export function getCompanyIntroForPreview(id: number) {
  return axios.get(`/api/internal/introduce/${id}`);
}

export const getNewsCarousel = (id: number): Promise<NewsData[]> => {
  return axios.get(`/api/internal/fixed/news/list/${id}`);
};

export const getNewsPage = (params: {
  site: {
    id: number;
  };
  pageNo: number;
  pageSize: number;
}): Promise<{ count: number; total: number; data: NewsData[] }> => {
  return axios.post('/api/internal/news/list', params);
};
