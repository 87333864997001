import React, { useState, useEffect, useRef, useContext } from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import Context from 'container';
import usePlatform from 'hooks/usePlatform';
import './index.scss';

let Player;

export default function VideoPlayer({ visible, videosInfo = {}, inline, onCancel }) {
  const { title, desc, videos = [] } = videosInfo;
  const { isPc } = usePlatform() || {};
  const { dispatch } = useContext(Context);
  const [activeId, setActiveId] = useState();
  const [url, setUrl] = useState();
  const instance = useRef();

  const config = {
    source: url,
    width: '100%',
    height: isPc ? '100%' : '65vw',
    autoplay: true,
    isLive: false,
    rePlay: false,
    playsinline: true,
    preload: true,
    controlBarVisibility: 'hover',
    useH5Prism: true,
    components: [
      {
        name: 'RateComponent',
        type: Player && Player.components.RateComponent
      }
    ]
  };

  const player = Player && (
    <Player
      config={config}
      onGetInstance={(_instance) => {
        instance.current = _instance;
      }}
      // Optional: custom cdn url
      // sourceUrl={'https://g.alicdn.com/de/prismplayer/2.8.2/aliplayer-min.js'}
    />
  );

  instance.current && instance.current.on('ended', onEndedHandle);

  useEffect(() => {
    (async () => {
      Player = (await import('aliplayer-react')).default;

      if (!isEmpty(videos)) {
        onChangeVideo(videos[0]);
      }
    })();

    return () => {
      dispatch({ hasMask: false });
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    dispatch({ hasMask: visible });
    document.body.style.overflow = visible ? 'hidden' : 'auto';
  }, [visible]);

  function onEndedHandle() {
    if (videos.length > 1) {
      let index;
      videos.forEach((video, i) => {
        if (activeId === video.id) {
          index = i;
        }
      });

      if (index < videos.length - 1) {
        onChangeVideo(videos[1 + index]);
      }
    }
  }

  function onChangeVideo({ id, video }) {
    const {
      files: [{ url: _url }]
    } = video;
    setUrl(_url);

    if (config.source) {
      instance.current && instance.current.loadByUrl(_url);
    } else {
      config.source = _url;
    }

    setActiveId(id);
  }

  if (!inline) {
    return (
      <div
        className={classnames('video-player-mask', { mobile: !isPc })}
        style={{ display: visible ? 'block' : 'none' }}
      >
        <div className="video-player-wrapper">
          <div className="video-player">{config.source ? player : null}</div>
          {videos.length > 1 ? (
            <div className="video-player-side">
              {title ? <div className="video-player-title">{title}</div> : null}
              {desc ? <div className="video-player-desc">{desc}</div> : null}
              <ul className="video-player-list">
                {videos.map((video, i) => (
                  <li
                    key={i}
                    className={classnames({ active: activeId === video.id })}
                    onClick={() => onChangeVideo(video)}
                  >
                    <div className="video-player-list-name">
                      {`${1 + i}`.padStart(2, '0')}
                      {isPc ? ` ${video.name}` : ''}
                    </div>
                    {activeId === video.id ? (
                      <div className="equilizer">
                        <span />
                        <span />
                        <span />
                      </div>
                    ) : null}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          <span className="video-player-close hidden-in-mobile" onClick={onCancel}>
            &times;
          </span>
        </div>
      </div>
    );
  }

  return player;
}
