import { IconWrapper, ISvgIconProps } from ".";

export default IconWrapper(
  'line',
  true,
  (props: ISvgIconProps) => (
    <svg width={props.size} height={props.size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M 24,0 L24,48"
        stroke={props.colors[0]}
        strokeWidth={props.strokeWidth}
        strokeLinecap={props.strokeLinecap}
        strokeLinejoin={props.strokeLinejoin}
      />
    </svg>
  )
);