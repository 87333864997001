import { divideData } from 'components/About/news';
import Affix from 'components/Affix';
import AnchorList from 'components/Common/anchor';
import Divider from 'components/Common/divider';
import { oss } from 'scripts/utils';
import './product-list.scss';
import Context from 'container';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import VideoIcon from 'components/Video/video-icon';
import { createVideoModal } from 'components/Video/video';
import usePlatform from 'hooks/usePlatform';
import { ProductDetail } from './detail';

const PAGE_COLUMN = 3;

// export interface ProductDetail {
//   id: number;
//   desc: string;
//   name: string;
//   videoMaterial?: {
//     files: {
//       url: string;
//     }[];
//   };
// }

interface ProductData {
  id: number;
  name: string;
  productList: ProductDetail[];
}

interface IProductListProps {
  data: ProductData[];
}

const ProductList = (props: IProductListProps) => {
  const { data } = props;
  const { lang, updateObserve } = useContext(Context);
  useEffect(() => {
    if (data) updateObserve();
  }, [data, updateObserve]);
  if (!data) return null;

  const anchorItems = data.map((d) => ({ id: `_${d.id}`, title: d.name }));

  return (
    <div className="product-list-wrapper">
      <div className="affix-list">
        <Affix offsetTop={50}>
          <AnchorList items={anchorItems} />
        </Affix>
      </div>

      <div className="product-list">
        {data.map((group) => {
          const { id, name, productList } = group;
          return (
            <div className="product-list-group" key={id}>
              <div id={`_${id}`} className="product-list-group-title">
                {name}
              </div>
              <div className="product-list-group-content">
                {divideData<ProductDetail>(productList, PAGE_COLUMN).map((product, productIndex) => {
                  return (
                    <div key={productIndex}>
                      <div className="product-list-group-content-row">
                        {product.map((c, i) => {
                          // const video = c.videoMaterial?.files[0]?.url;
                          return (
                            <Link
                              // onMouseLeave={() => video && setActiveId(null)}
                              // onMouseEnter={() => video && setActiveId(`${id}_${c.id}`)}
                              to={`/product/${c.id}`}
                              className="product-list-group-content-item"
                              key={i}
                            >
                              <div className="item-img-wrapper">
                                {/* {video ? (
                                  <VideoIcon
                                    active={`${id}_${c.id}` === activeId}
                                    className="item-play"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      ev.stopPropagation();
                                      createVideoModal(video);
                                    }}
                                  >
                                    {lang === 'cn' ? '立即播放' : 'Play'}
                                  </VideoIcon>
                                ) : null} */}
                                <img
                                  className="item-img"
                                  data-src={oss(c.pcResource?.files?.[0]?.url, 560, 316)}
                                  alt={c.name}
                                />
                                <div className="footer-banner flex-h-center">
                                  <div className="item-title line-clamp-2">{c.name}</div>
                                </div>
                              </div>

                              {/* <img className="item-logo" data-src={oss(c.proLogo, 100, 24, 'lfit')} alt={c.name} /> */}
                              <div className="item-desc line-clamp-2">{c.desc}</div>
                              <div className="item-detail line-clamp-2">{lang === 'cn' ? '查看详情' : 'Detail'}</div>
                            </Link>
                          );
                        })}
                      </div>
                      <Divider className="row-divider" />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductList;
