import { createContactUsModal } from 'components/ContactUs';
import SetTop from 'components/IconWrapper/set-top';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './index.scss';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="comp-scroll-to-top">
      <div className="contact-button" onClick={() => createContactUsModal()}>
        <div className="consult-icon img-background"></div>
      </div>
      <div className="set-top" onClick={() => window.scrollTo(0, 0)}>
        <SetTop strokeWidth={1} />
      </div>
    </div>
  );
}
