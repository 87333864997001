import React, { useState } from 'react';
import RcSelect, { Option } from 'rc-select';
import './select.scss';

function Select({ config, onFocus, onChange }, ref) {
  const [value, setValue] = useState(config.defaultValue);

  function onChangeHandle(val) {
    setValue(val);

    if(config.name) {
      onChange(val);
    }
  }

  return (
    <RcSelect
      allowClear
      value={value}
      mode={config.customProps?.mode}
      placeholder={config.customProps?.placeholder || "请选择"}
      onChange={onChangeHandle}
    >
      {
        config.customProps.options.map((opt, i) => (
          <Option key={i} value={opt.value}>{opt.label}</Option>
        ))
      }
    </RcSelect>
  )
}

export default React.forwardRef(Select)
