import cn from 'classnames';
import { fieldTranslation } from 'components/constants';
import CloseSmall from 'components/IconWrapper/close-small';
import Right from 'components/IconWrapper/right';
import Overlay from 'components/Overlay';
import useLanguage from 'hooks/useLanguage';
import { map } from 'lodash';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { isValid } from 'scripts/utils';
import './index.scss';

export interface IMobileMenuModalProps {
  open?: boolean;
  onClose?: () => void;
  title?: React.ReactNode;
  content?: React.ReactNode;
  destroyOnClose?: boolean;
  data?: MenuType[];
  sites?: SiteType[];
  onLanguageChange?: (mark?: string) => void;
}
type SiteType = {
  mark: string;
  title: string;
  logo: string;
};
type MenuType = {
  name: string;
  group?: boolean;
  link?: string;
  children?: MenuType[];
  onClick?: () => void;
};

type ModalData = {
  groupName?: string;
  menu?: MenuType[];
};

const MobileMenuModal = (props: IMobileMenuModalProps) => {
  const { open = false, onClose, data = [], sites, onLanguageChange, destroyOnClose } = props;
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { i18n } = useLanguage();
  const initTitle = i18n(fieldTranslation, '菜单');
  const [modalTitle, setModalTitle] = useState<string[]>([]);
  const [modalLevel, setModalLevel] = useState(-1);
  const [modalData, setModalData] = useState<ModalData[][]>([]);
  const isRootLevel = modalLevel === -1;
  const currentData = isRootLevel ? null : modalData[modalLevel];
  const currentTitle = isRootLevel ? initTitle : modalTitle[modalLevel];

  const initModal = () => {
    setModalLevel(-1);
    setModalData([]);
    setModalTitle([]);
  };

  const setSitesStep = () => {
    setModalTitle([i18n(fieldTranslation, '语言切换')]);
    setModalLevel((l) => l + 1);
    setModalData([
      [
        {
          menu: map(sites, (site) => ({
            name: site.title,
            onClick: () => {
              onLanguageChange?.(site.mark);
              handleClose();
            }
          }))
        }
      ]
    ]);
  };

  const handleClose = () => {
    initModal();
    onClose?.();
  };

  const handleBack = () => {
    if (!isRootLevel) {
      setModalLevel((l) => l - 1);
      setModalData((d) => d.slice(0, -1));
      setModalTitle((d) => d.slice(0, -1));
    }
  };

  const getContentScroll = () => {
    if (isValid(contentRef.current?.scrollHeight) && isValid(contentRef.current?.clientHeight)) {
      return contentRef.current!.scrollHeight > contentRef.current!.clientHeight;
    }
    return false;
  };

  const renderGroup = (groupData?: ModalData[]) => {
    if (!groupData) return null;
    return map(groupData, (group, groupIndex) => {
      return (
        <div key={groupIndex}>
          {group.groupName ? <div className="mobile-menu-group-name">{group.groupName}</div> : null}
          <div className="mobile-menu-group">
            {map(group.menu, (item, index) => {
              // 点击跳转
              if (!item.children) {
                if (item.link) {
                  if (/^https?:\/\//.test(item.link)) {
                    return (
                      <a key={index} className="mobile-menu-group-item" href={item.link} target="_blank" >
                        <span>{item.name}</span>
                        <Right size={16} />
                      </a>
                    );
                  }
                  return (
                    <Link onClick={handleClose} key={index} className="mobile-menu-group-item" to={item.link}>
                      <span>{item.name}</span>
                      <Right size={16} />
                    </Link>
                  );
                } else {
                  return (
                    <a onClick={item.onClick} key={index} target="_blank" className="mobile-menu-group-item">
                      <span>{item.name}</span>
                      <Right size={16} />
                    </a>
                  );
                }
              } else {
                let handleNextStep = () => {
                  setModalTitle((title) => [...title, item.name]);
                  setModalLevel((l) => l + 1);
                  setModalData((modalData) => [
                    ...modalData,
                    [
                      {
                        menu: map(item.children, (child) => child)
                      }
                    ]
                  ]);
                };
                if (item.group) {
                  handleNextStep = () => {
                    setModalTitle((title) => [...title, item.name]);
                    setModalLevel((l) => l + 1);
                    setModalData((modalData) => [
                      ...modalData,
                      map(item.children, (child) => ({
                        groupName: child.name,
                        menu: child.children
                      }))
                    ]);
                  };
                }
                return (
                  <a onClick={handleNextStep} key={index} target="_blank" className="mobile-menu-group-item">
                    <span>{item.name}</span>
                    <Right size={16} />
                  </a>
                );
              }
            })}
          </div>
        </div>
      );
    });
  };

  const renderCurrentContent = () => {
    if (currentData) {
      return renderGroup(currentData);
    } else {
      return rootContent;
    }
  };

  const rootContent = (
    <>
      <div className="mobile-menu-group">
        <Link onClick={handleClose} className="mobile-menu-group-item" to={'/'}>
          <span>{i18n(fieldTranslation, '首页')}</span>
          <Right size={16} />
        </Link>
      </div>
      {renderGroup([{ menu: data }])}
      {Array.isArray(sites) && sites.length > 1 ? (
        <div className="mobile-menu-group">
          <a onClick={() => setSitesStep()} className="mobile-menu-group-item" target="_blank">
            <span>{i18n(fieldTranslation, '语言切换')}</span>
            <Right size={16} />
          </a>
        </div>
      ) : null}
    </>
  );

  const isContentScroll = getContentScroll();
  const modal = (
    <div className="mobile-modal-container">
      <div className={cn('comp-modal-header', { 'comp-modal-header-shadow': isContentScroll })}>
        <div className="comp-modal-operation">
          {isRootLevel ? (
            <CloseSmall size={20} strokeWidth={4} onClick={handleClose} />
          ) : (
            <Right style={{ transform: 'rotate(180deg)' }} size={20} onClick={handleBack} />
          )}
        </div>
        {currentTitle}
      </div>
      <div ref={contentRef} className="comp-modal-content">
        {renderCurrentContent()}
      </div>
    </div>
  );
  return <Overlay destroy={destroyOnClose} open={open} content={modal} />;
};

export default MobileMenuModal;
