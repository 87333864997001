import React, { useState, useRef, useEffect } from "react";
import { map } from 'lodash'
import cn from 'classnames';
import usePlatform from 'hooks/usePlatform';
import { fieldTranslation } from 'components/constants';
import CircleButton from 'components/Common/circle-button';
import { CursorPagination } from 'components/Common/cursor-pagination';
import Slider, { Settings } from 'react-slick';
import { Resource } from 'components/types';
import { oss } from 'scripts/utils';
import useLanguage from 'hooks/useLanguage';

export interface ISingle {
  id: number;
  name: string;
  desc: string;
  resource: Resource;
  config: {
    id: number;
    title: string;
    desc: string;
    resource: Resource;
  }[];
}
interface IProps {
  list: ISingle[]
  activeKey?: string
}

const defaultSliderContentSetting = {
  infinite: true,
  vertical: true,
  verticalSwiping: true,
  centerMode: false,
  swipeToSlide: true,
  slidesToScroll: 1,
  arrows: false,
  slidesToShow: 1,
  initialSlide: 0,
}
const defaultSliderPicSetting = {
  vertical: true,
  touchMove: false,
  slidesToScroll: 1,
  slidesToShow: 1,
  initialSlide: 0,
  arrows: false
}

export default ({ list, activeKey }: IProps) => {
  const { isPc } = usePlatform() || {};
  const { i18n } = useLanguage();
  const mainSliderRef = useRef<Slider | null>(null);
  const picSliderRef = useRef<Slider | null>(null);
  const [activeValueIndex, setActiveValueIndex] = useState(0);
  const contentToShow = isPc ? 2 : 1;
  const contentSlidesToShow = list === undefined ? 0 : list.length > 1 ? contentToShow : 1;

  useEffect(() => {
    setTimeout(() => {
      mainSliderRef.current?.slickGoTo(0)
      setActiveValueIndex(0);
    }, 200);
  }, [activeKey])

  const sliderContentSetting = useRef<Settings>({
    ...defaultSliderContentSetting,
    beforeChange: (_: number, index: number) => {
      setActiveValueIndex(index);
    }
  });

  const sliderPicSetting: Settings = defaultSliderPicSetting;

  useEffect(() => {
    if (isPc) {
      sliderContentSetting.current.vertical = true;
      sliderContentSetting.current.verticalSwiping = true;
    } else {
      sliderContentSetting.current.vertical = false;
      sliderContentSetting.current.verticalSwiping = false;
    }
    sliderContentSetting.current.slidesToShow = contentSlidesToShow;
  }, [contentSlidesToShow, isPc]);

  return (
    <div className="plate-values-wrapper">
    {list?.length > 1 ? (
      <div className="carousel-side hidden-in-mobile">
        <div className="side-circles">
          <CursorPagination
            onItemClick={(i) => mainSliderRef.current?.slickGoTo(i)}
            vertical={true}
            activeIndex={activeValueIndex}
            count={list.length}
          />
        </div>
        <div className="side-buttons">
          <CircleButton onClick={() => mainSliderRef.current?.slickPrev()} type="top" />
          <CircleButton onClick={() => mainSliderRef.current?.slickNext()} type="bottom" />
        </div>
      </div>
    ) : null}

    <div className="carousel-content carousel-content-detail">
      <Slider
        {...sliderContentSetting.current}
        asNavFor={picSliderRef.current ?? undefined}
        ref={mainSliderRef}
        className="main-content-wrapper"
      >
        {map(list, (value, index) => {
          const logo = value.resource?.files?.[0]?.url;
          return (
            <div
              className={cn('main-content', { 'main-content-active': activeValueIndex === index })}
              onClick={() => mainSliderRef.current?.slickGoTo(index)}
              key={index}
            >
              <main>
                <img className="main-content-img hidden-in-pc" src={oss(logo, 770)} alt="" />
                <div className="main-content-wrap">
                  <div className="main-content-title">{value.name}</div>
                  <div className="main-content-desc">{value.desc}</div>
                  {value.config && value.config?.length > 0 ? (
                    <div className="main-content-detail">{i18n(fieldTranslation, '细节亮点')}:</div>
                  ) : null}
                  <div>
                    {map(value.config, (d, i) => (
                      <div className="main-content-detail-item" key={i}>
                        <img src="/static/images/bright-spot.png" width={14} height={14} alt="" />
                        <span>{d.title}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </main>
            </div>
          );
        })}
      </Slider>
      <CursorPagination
        className="mobile-pagination hidden-in-pc"
        onItemClick={(i) => mainSliderRef.current?.slickGoTo(i)}
        activeIndex={activeValueIndex}
        count={list?.length ?? 0}
      />
    </div>
    <div className="carousel-content hidden-in-mobile pic-wrap">
      <Slider
        {...sliderPicSetting}
        asNavFor={mainSliderRef.current ?? undefined}
        ref={picSliderRef}
        className="main-pic-wrapper"
      >
        {map(list, (pic, index) => {
          const logo = pic.resource?.files?.[0]?.url;
          return (
            <div className={cn('pic-content')} key={index}>
              <img src={oss(logo, undefined, 592)} alt={pic.name} />
              {/* <img height={680} data-src={logo} alt="" /> */}
            </div>
          );
        })}
      </Slider>
    </div>
    <div className="values-mask hidden-in-mobile"></div>
  </div>
  )
}