import { useState, useEffect, useContext } from 'react';
import usePlatform from 'hooks/usePlatform';
import { oss } from 'scripts/utils';
import {
  renderDefault,
  RenderProduction,
  renderDetailHeader,
  renderCaseDetail,
  renderPlateDetail,
  RenderSolutionDetail
} from './render';
import { BANNER_TYPE } from 'components/constants';
import { isDark } from 'scripts/color-extractor/utils';
import { getColorByUrl } from 'scripts/color-extractor';
import Context from 'container';
import useLanguage from 'hooks/useLanguage';

export default function Single(props) {
  const {
    covered,
    height,
    paddingTop,
    src,
    mobileSrc,
    title,
    legend,
    operation,
    position = '',
    setThemeCallback,
    subheading,
    type
  } = props;
  const [theme, setTheme] = useState();
  const [primaryColor, setPrimaryColor] = useState();
  const [styles, setStyles] = useState({});
  const { isPc } = usePlatform() || {};
  const realSrc = (isPc ? src : mobileSrc) || src;
  const [media, setMedia] = useState('');
  const { lang } = useContext(Context);
  const { i18n } = useLanguage();

  useEffect(() => {
    if (isPc) {
      setMedia(realSrc);
    } else {
      setMedia(oss(realSrc, 1200));
    }
  }, [isPc, realSrc]);

  useEffect(() => {
    const _styles = { height };

    if (paddingTop) {
      _styles.paddingTop = paddingTop;
    }

    setStyles(_styles);
  }, [height, paddingTop, isPc]);

  useEffect(() => {
    (async () => {
      if (media && covered) {
        getColorByUrl(media).then((res) => {
          const result = res;
          const color = { r: result[0], g: result[1], b: result[2] };
          const _theme = isDark(color) ? 'dark' : 'light';
          setTheme(_theme);
          setPrimaryColor(`rgb(${result.join(',')})`);
          setThemeCallback instanceof Function && setThemeCallback(_theme);
        });
      }
    })();
  }, [media, covered]);

  const renderProps = {
    ...props,
    styles,
    primaryColor,
    media,
    position,
    theme,
    title,
    legend,
    operation,
    subheading,
    lang,
    i18n,
    isPc
  };

  switch (type) {
    case BANNER_TYPE.PRODUCT:
      return <RenderProduction {...renderProps} />;
    case BANNER_TYPE.DETAIL_HEADER:
      return renderDetailHeader(renderProps);
    case BANNER_TYPE.CASE_DETAIL:
      return renderCaseDetail(renderProps);
    case BANNER_TYPE.PLATE_DETAIL:
      return renderPlateDetail(renderProps);
    case BANNER_TYPE.SOLUTION:
      return <RenderSolutionDetail {...renderProps} />;
    default:
      return renderDefault(renderProps);
  }
}
