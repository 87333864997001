import React, { useState } from 'react';

function Checkbox({ config, onFocus, onChange }, ref) {
  const [value, setValue] = useState(config.defaultValue || []);

  function onChangeHandle(event) {
    const { value: val, checked } = event.target;
    if(checked) {
      value.push(val);
    } else {
      value.splice(value.indexOf(val), 1);
    }

    setValue(value);

    if(config.name) {
      onChange(value);
    }
  }
  return (
    <div className="form-creator-checkbox-group">
      {config.customProps.options.map((option, index) => (
        <div className="form-creator-checkbox-group-row" key={index}>
          <label>
            <input
              name={config.name}
              type="checkbox"
              value={option.value}
              onChange={onChangeHandle}
              onFocus={onFocus}
            />
            <span>{option.label}</span>
          </label>
        </div>
      ))}
    </div>
  )
}

export default React.forwardRef(Checkbox)
